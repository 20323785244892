/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import classnames from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// import logo from 'logo-white.svg';

let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar" data-color={this.props.backgroundColor}>
        <div className="logo">
          <a href="/admin" className="simple-text logo-normal">
            CUROFY
          </a>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;

              if ((this.props.isNewRowSequency && prop.path !== '/custom-ranking/') || !this.props.isNewRowSequency) {
                return (
                  <li
                    className={classnames(
                      this.activeRoute(prop.layout + prop.path) + (prop.pro ? ' active active-pro' : ''),
                      { hidden: prop.isHidden }
                    )}
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path + (prop.addPath || '')}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop['fa-icons'] ? prop.icon : 'now-ui-icons ' + prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
