import React, { useState, useEffect } from 'react';

// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { v4 as uuid } from 'uuid';

export default ({ setEditaCategorySetData, editaCategorySetData, editIndex }) => {
  const [slots, setSlots] = useState('');
  const [catSetName, setCatSetName] = useState('');
  const [uniqueCategoryId, setUniqueCategoryId] = useState('');
  const [mainCategory, setMainCategory] = useState('');
  const handleMainCategory = category => {
    setMainCategory(category);
    setEditaCategorySetData({ ...editaCategorySetData, mainCategory: category });
  };

  const handleName = name => {
    setCatSetName(name);
    setEditaCategorySetData({ ...editaCategorySetData, category_set: name });
  };

  const handleSlots = slot => {
    setSlots(slot);
    setEditaCategorySetData({ ...editaCategorySetData, slots: slot });
  };

  const handleUniqueCategoryId = uniqueCategoryIds => {
    setUniqueCategoryId(uniqueCategoryIds);
    setEditaCategorySetData({
      ...editaCategorySetData,
      unique_category_id: uniqueCategoryIds.replace(/\s/g, '').split(',')
    });
  };

  useEffect(() => {
    console.log(editIndex, 'editIndex');
    if (editIndex === -1) {
      setEditaCategorySetData({ ...editaCategorySetData, uuid: uuid() });
    }
  }, []);

  useEffect(() => {
    setSlots(editaCategorySetData?.slots || '');
    setCatSetName(editaCategorySetData?.category_set || '');
    setMainCategory(editaCategorySetData?.mainCategory || '');
    setUniqueCategoryId(editaCategorySetData?.unique_category_id || '');
  }, [editaCategorySetData]);
  return (
    <>
      <div className="">
        <Form>
          <Row>
            <Col className="px-3" md="12">
              <FormGroup>
                <Label for="exampleSelect">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={catSetName}
                  onChange={event => {
                    handleName(event.target.value);
                  }}
                ></Input>
                <Label for="exampleSelect">Slots</Label>
                <Input
                  type="number"
                  name="slots"
                  id="slots"
                  value={slots}
                  onChange={event => {
                    handleSlots(event.target.value);
                  }}
                ></Input>
                <Label for="exampleSelect">Unique Category Id</Label>
                <Input
                  type="text"
                  name="slots"
                  id="slots"
                  placeholder="Add unique ids by comma (,) separated"
                  value={uniqueCategoryId}
                  onChange={event => {
                    handleUniqueCategoryId(event.target.value);
                  }}
                ></Input>
                <Label for="exampleSelect">
                  <i style={{ color: 'red', fontSize: '10px' }}>Note: Make sure the unique category ids are correct</i>
                </Label>
                <br />
                <Label for="exampleSelect">Select Category</Label>
                <select
                  className="form-control"
                  // style={{ height: 40, width: 30 }}
                  placeholder="Name"
                  type="text"
                  value={mainCategory}
                  onChange={e => {
                    handleMainCategory(e.target.value);
                  }}
                >
                  <option value="">--Select--</option>
                  <option value="Women">Women</option>
                  <option value="Men">Men</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Kids">Kids</option>
                  <option value="Home">Home</option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
