import React, { useEffect, useState } from 'react';

import Creatable from 'react-select/creatable';
import Button from 'reactstrap/lib/Button';
import { useStateValue } from 'context/RuleContext';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';

export default ({
  handleMetricDaysInRange,
  daysRangeDropDownOpen,
  toggle,
  daysRange,
  setFilter
}) => {
  const defaultOptions = [
    { value: 'name', label: 'Name' },
    { value: 'enabled_at', label: 'Enabled At' },
    { value: 'discount_percentage', label: 'Discount' },
    { value: 'enrich.last30revros', label: 'Last 30d Rev ROS' },
    { value: 'enrich.last30qtyros', label: 'Last 30d Qty ROS' },
    { value: 'enrich.last30cr', label: 'Last 30d Conv. Rate' }
  ];
  const [dropdown, setShowDropdown] = useState(false);
  const selectedAttributes = JSON.parse(
    window.localStorage.selectedAttributes || '[]'
  );

  const itemsPerRowStorage = Number(window.localStorage.itemsPerRow || 6);
  const [itemsPerRow, setItemsPerRow] = useState(itemsPerRowStorage);

  const [options, setOptions] = useState([
    ...(defaultOptions || selectedAttributes || [])
  ]);
  const [selectedOptions, setSelectedOptions] = useState([
    ...(selectedAttributes || [])
  ]);
  const handleOnCreateNewOption = option => {
    const newOption = { label: option, value: option };
    const allOptions = [...options, newOption];
    setOptions(allOptions);
    // window.localStorage.productView = JSON.stringify(allOptions);
    handleSelectOption([newOption], { action: 'create-new' });
  };
  const handleSelectOption = (opt, info = {}) => {
    let selected = [...selectedOptions];
    if (info.action === 'remove-value') {
      selected = opt || [];
    }
    if (info.action === 'create-new') {
      selected = [...selected, ...opt] || [];
    } else {
      selected = [...(opt || [])];
    }
    setSelectedOptions(selected);
    window.localStorage.selectedAttributes = JSON.stringify(selected);
    ruleDispatch({ type: 'SET_VISIBLE_ATTRIBUTES' });
  };
  const handleToggle = () => {
    setShowDropdown(!dropdown);
  };
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  return (
    <>
      <Button
        style={{ position: 'absolute', top: '-50px', right: -35 }}
        size="sm"
        className="bg-pastel-red border-right-0 btn btn-outline-info btn-sm c-white no-border p-lg-2"
        outline
        color="info"
        onClick={handleToggle}
      >
        {!dropdown ? (
          <i className={'fa fa-angle-left'} />
        ) : (
          <i className={'fa fa-angle-right'} />
        )}
      </Button>
      {dropdown ? (
        <>
          <Row >
            <Col size={3}>
              <FormGroup>
                <label>Items per row</label>
                <select
                  className="form-control"
                  name="itemsPerRow"
                  value={itemsPerRow}
                  onChange={event => {
                    window.localStorage.itemsPerRow = event.target.value;
                    setItemsPerRow(Number(event.target.value));
                    ruleDispatch({
                      type: 'SET_VISIBLE_ATTRIBUTES',
                      data: { itemsPerRow: event.target.value }
                    });
                  }}
                  defaultValue="6"
                  placeholder="Value"
                >
                  <option value="4">3</option>
                  <option value="3">4</option>
                  <option value="2">6</option>
                </select>
              </FormGroup>
            </Col>
            <Col size={5}>
              <FormGroup>
                <label>Visible Attributes</label>
                <Creatable
                  onCreateOption={handleOnCreateNewOption}
                  isMulti={true}
                  onChange={handleSelectOption}
                  options={options}
                  value={selectedAttributes}
                />{' '}
              </FormGroup>
            </Col>
            <Col size={4}>
              <FormGroup>
                <label>Filter</label>
                <select
                  className="form-control"
                  name="filter"
                  onChange={event => {
                    setFilter(event.target.value)
                  }}
                  defaultValue=""
                >
                  <option value="">Select Filter</option>
                  <option value="Spotlight">Spotlight</option>
                </select>
              </FormGroup>
            </Col>
            <Col size={1}>
              <FormGroup>
                <label>Metrics</label>
                <Dropdown
                  isOpen={daysRangeDropDownOpen}
                  toggle={toggle}
                  style={{ textAlign: 'right' }}
                >
                  <DropdownToggle
                    caret
                    className="btn btn-outline-info btn-sm dropdown-toggle"
                  >
                    Last {daysRange} Days
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      right: 0,
                      marginLeft: 47
                    }}
                  >
                    <DropdownItem onClick={() => handleMetricDaysInRange(7)}>
                      Last 7 Days
                    </DropdownItem>
                    <DropdownItem onClick={() => handleMetricDaysInRange(15)}>
                      Last 15 Days
                    </DropdownItem>
                    <DropdownItem onClick={() => handleMetricDaysInRange(30)}>
                      Last 30 Days
                    </DropdownItem>
                    <DropdownItem onClick={() => handleMetricDaysInRange(90)}>
                      Last 90 Days
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
          </Row>


        </>
      ) : null}
    </>
  );
};
