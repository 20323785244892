import axios from 'variables/axios';
import { FEED_SERVER_ENDPOINT } from 'constants/index';

export async function getCategorySlottingList(dispatch) {
  const result = await axios.get(`${FEED_SERVER_ENDPOINT}/api/get-category-slotting`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  dispatch({
    type: 'GET_CATEGORY_SLOTTING',
    data: {
      getCategorySlotting: result.data
    }
  });
}
export async function setCategorySlottingList({ catSlotData, updateBy }, dispatch) {
  const result = await axios.post(
    `${FEED_SERVER_ENDPOINT}/api/set-category-slotting`,
    { catSlotData, updateBy },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getCategorySlottingList(dispatch);
}

export async function setCategorySetList({ catSetData, edit = true, editaCategorySetData = {}, updateBy }, dispatch) {
  const result = await axios.post(
    `${FEED_SERVER_ENDPOINT}/api/set-category-set`,
    { catSetData, edit, editaCategorySetData, updateBy },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getCategorySetList(dispatch);
}

export async function getCategorySetList(dispatch) {
  const result = await axios.get(`${FEED_SERVER_ENDPOINT}/api/get-category-set`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  dispatch({
    type: 'GET_CATEGORY_SET',
    data: {
      getCategorySet: result.data.Value
    }
  });
}
