import React, { useState } from 'react';

import Rules from './Rules';

export default ({ data, handleRuleGroupChange, ruleGroupIndex }) => {
  const [rules, setNewRule] = useState(data);
  const handleNewRule = () => {
    const lastRule = rules?.length
      ? rules[rules.length - 1]
      : { name: 'categoryIds' };
    setNewRule([
      ...rules,
      {
        ...lastRule,
        type: rules[0] && rules[0].type === 'must_not' ? 'must_not' : 'must',
        value: ''
      }
    ]);
  };
  const handleRuleChange = ({ name, value, index, operation }) => {
    const newRules = [...rules];
    let flag = false;
    if (operation === 'remove') {
      flag = true;
      newRules.splice(index, 1);
    } else {
      if (value) {
        flag = true;
      }
      const currentRule = newRules[index];
      newRules[index] = { ...currentRule, [name]: value }; //{ name, logical, value };
    }
    setNewRule([...newRules]);
    if (flag) handleRuleGroupChange({ ruleGroup: newRules, ruleGroupIndex });
  };
  return (
    <fieldset
      className="  pt-3 pb-3 mb-4 pr-2 pl-2"
      style={{ border: '1px solid #dee2e6' }}
    >
      <legend className="text-lg-left fa-1x">
        {rules[0] && rules[0].type === 'must_not' ? (
          <span className="c-pastel-red">MUST NOT</span>
        ) : (
          <span className="c-sap-green">MUST</span>
        )}
      </legend>
      {rules.map((rule, index) => (
        <>
          <Rules
            ruleData={rule}
            handleRuleChange={handleRuleChange}
            index={index}
          />
        </>
      ))}
      <a onClick={handleNewRule} role="link" href="javascript:;">
        -- or --
      </a>
      {/* <Button
        onClick={handleNewRule}
        role="link"
        className="btn-outline-default text-center btn-sm"
      >
        OR
      </Button> */}
    </fieldset>
  );
};
