import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroup,
  InputGroupText
} from 'reactstrap';

import { useStateValue } from '../../context/RuleContext';
import { getFilterAttributes } from '../../views/FilterListing/actions';

export default ({ ruleData = {}, handleRuleChange, index }) => {
  const {
    filterattributes: [filterattributeState, filterAttributeDispatch]
  } = useStateValue();
  useEffect(() => {
    getFilterAttributes({}, filterAttributeDispatch);
  }, []);
  const handleNameChange = event => {
    handleRuleChange({ name: 'name', value: event.target.value, index });
  };
  const handleLogicalChange = event => {
    handleRuleChange({ name: 'logical', value: event.target.value, index });
  };
  const handleValueChange = event => {
    handleRuleChange({ name: 'value', value: event.target.value, index });
  };
  const handleRemoveRule = () => {
    handleRuleChange({ operation: 'remove', index });
  };
  const { filterAttributesList = [] } = filterattributeState;
  return (
    <Row>
      <Col className="px-3">
        <FormGroup>
          <InputGroup>
            <select
              className="form-control"
              style={{ height: 40, width: 30 }}
              value={ruleData.name}
              onChange={handleNameChange}
              defaultValue=""
              placeholder="Name"
              type="text"
              id={`ruleAttribute${index}`}
            >
              <option>Choose</option>
              {filterAttributesList.map(filterfield => {
                const { fieldLabel, fieldValue, fieldHint } = filterfield;
                return (
                  <option key={fieldValue} value={fieldValue} title={fieldHint}>
                    {fieldLabel}
                  </option>
                );
              })}
            </select>

            <select
              value={ruleData.logical}
              onChange={handleLogicalChange}
              className="form-control"
              style={{ height: 40, width: 30, textAlign: 'center' }}
            >
              <option value="contains">contains</option>
              <option value="is">is</option>
              <option value="lte">lesser or equal to(&le;)</option>
              <option value="gte">greater or equal to(&ge;)</option>
              <option value="between">between</option>
              <option value="exists">exists</option>
            </select>
            <Input
              value={ruleData.value}
              onChange={handleValueChange}
              defaultValue=""
              placeholder={
                ruleData.name === 'created_at' ? '2020-01-01' : 'value'
              }
              type="text"
            />

            <InputGroupAddon addonType="append">
              <InputGroupText onClick={handleRemoveRule}>
                <a href="javascript:;" className="c-red">
                  X
                </a>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};
