/** @format */

import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import { emailRegex, nameRegex } from 'utils/regex';

export const emailValidation = input => {
  return emailRegex.test(input);
};
export const alphaValidation = input => {
  return nameRegex.test(input);
};
export const emptyValidation = input => {
  return input === undefined || input === null;
};
export const validator = validationObject => {
  const err = {};
  map(validationObject, (item, key) => {
    const { value, name, validate } = item;
    if (!isEmpty(validate)) {
      // for (let i = 0; i <= validate.length; i+=1) {
      validate.find(element => {
        if (element === 'empty') {
          if (emptyValidation(value)) {
            err[key] = `${name} can't be null`;
            return false;
          }
        }
        if (element === 'alpha') {
          if (!alphaValidation(value)) {
            err[key] = `Please enter ${name} in correct format`;
            return false;
          }
        }
        if (element === 'email') {
          if (!emailValidation(value)) {
            err[key] = `Please enter the ${name} in correct format`;
            return false;
          }
        }
        // return false;
      });

      // }
    }
    return {};
  });
  return err;
};
