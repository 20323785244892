import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import 'react-datetime/css/react-datetime.css';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  CardFooter,
  ModalFooter,
  FormGroup
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import FeatureRankingManage from 'views/FeatureRanking/Manage';

import { useStateValue } from 'context/RuleContext';
import { listFeatureRanking, deleteFeatureRanking } from './actions';
import Modal from 'reactstrap/lib/Modal';
import classNames from 'classnames';
import Input from 'reactstrap/lib/Input';

export default ({ handleClose }) => {
  const history = useHistory();

  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();
  const [selectedRankingId, setSelectedRankingId] = useState();

  const [mainCategory, setMainCategory] = useState(
    window.localStorage.featureRankingFilterCategory || ''
  );
  const [searchText, setSearchText] = useState(
    window.localStorage.searchText || ''
  );

  const handleMainCategoryChange = event => {
    window.localStorage.featureRankingFilterCategory = event.target.value;
    setMainCategory(event.target.value);
  };
  const handleSearchText = event => {
    window.localStorage.featureRankingSearchText = event.target.value;
    setSearchText(event.target.value);
  };

  const [featureRankingItemModalOpen, setFeatureRankingItemModalOpen] =
    useState(false);

  const handleFeatureRankingDelete = id => {
    if (
      window.confirm(`This will delete from all collections/curations it assinged.
Are you SURE you want to delete?`)
    )
      deleteFeatureRanking(
        { featureRanking: { id }, isDelete: true },
        ruleDispatch
      );
  };
  const handleFeatureRankingEdit = id => {
    // ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined } });
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    setSelectedRankingId(id);
    setFeatureRankingItemModalOpen(!featureRankingItemModalOpen);
    // history.push(`/admin/feature/ranking/edit/${id}`);
    // window.location.reload();
  };
  const handleRuleAdd = () => {
    // ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined } });
    // window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    setSelectedRankingId(false);
    setFeatureRankingItemModalOpen(!featureRankingItemModalOpen);
    // history.push(`/admin/feature/ranking/add`);
  };
  useEffect(() => {
    listFeatureRanking({}, ruleDispatch);
  }, []);

  const { featureRankingList = [] } = ruleState;
  const modalClasses = classNames({
    'modal-content': {
      height: '100%'
    },
    modal: {
      WebkitTransform: 'translate3d(0%, 0, 0)',
      msTransform: 'translate3d(0%, 0, 0)',
      oTransform: 'translate3d(0%, 0, 0)',
      transform: 'translate3d(0%, 0, 0)'
    },
    'modal-dialog': {
      transition: '1s',
      right: 0,
      left: 'unset'
    }
  });
  return (
    <>
      {!handleClose ? <PanelHeader size="sm"></PanelHeader> : null}
      <Modal
        size={'lg'}
        isOpen={featureRankingItemModalOpen}
        toggle={() =>
          setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
        }
        style={{
          maxWidth: '60%',
          height: '100vh',
          right: 0,
          margin: '0'
        }}
        className={`${modalClasses} no-border`}
        contentClassName={'no-border'}
      >
        {featureRankingItemModalOpen ? (
          <FeatureRankingManage
            handleClose={() =>
              setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
            }
            idFromProps={selectedRankingId}
            onSaveComplete={() =>
              setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
            }
          />
        ) : null}
      </Modal>
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col md={4}>
                    <CardTitle tag="h4">Feature Ranking&nbsp;</CardTitle>
                    {!handleClose ? (
                      <Button
                        color="info"
                        size="sm"
                        className="btn-round"
                        onClick={handleRuleAdd}
                      >
                        Create New Feature ranking
                      </Button>
                    ) : null}
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <label>&nbsp;</label>
                      <Input
                        type="search"
                        placeholder="Search Feature Ranking"
                        className="form-control"
                        name="searchText"
                        value={searchText}
                        onChange={handleSearchText}
                        defaultValue=""
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label>Filter By</label>
                      <select
                        className="form-control"
                        name="mainCategory"
                        value={mainCategory}
                        onChange={handleMainCategoryChange}
                        defaultValue=""
                      >
                        <option value="">--Select--</option>
                        <option value="Women">Women</option>
                        <option value="Men">Men</option>
                        <option value="Beauty">Beauty</option>
                        <option value="Kids">Kids</option>
                        <option value="Home">Home</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: 10 }}>#</th>
                      <th>Ranking Name</th>

                      <th style={{ width: 30 }} className="text-right">
                        Edit
                      </th>
                      <th style={{ width: 30 }} className="text-right">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {featureRankingList
                      .filter(
                        ranking =>
                          (ranking.mainCategory || '') === mainCategory &&
                          (ranking.rankingName
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) > -1 ||
                            !searchText)
                      )
                      .map((ranking, key) => {
                        const { rankingName, mainCategory, id } = ranking;

                        return (
                          <tr>
                            <td style={{ width: 10 }}>{key + 1}.</td>

                            <td
                              className="text-left"
                              onClick={() => handleFeatureRankingEdit(id)}
                            >
                              <b>{mainCategory}</b> {rankingName}
                            </td>

                            <td
                              style={{ color: '#4570b9', width: 20 }}
                              className="text-right pointer"
                              onClick={() => handleFeatureRankingEdit(id)}
                            >
                              Edit
                            </td>
                            <td
                              style={{ color: 'red', width: 20 }}
                              className="text-right pointer"
                              onClick={() => handleFeatureRankingDelete(id)}
                            >
                              Delete
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {handleClose ? (
        <ModalFooter
          style={{
            position: 'fixed',
            bottom: 0,
            background: 'inherit',
            borderTop: '1px solid #eee',
            width: '50%',
            height: '60px'
          }}
        >
          <Button
            color="default"
            size="sm"
            outline
            className="btn-round"
            onClick={handleClose}
          >
            Close
          </Button>

          <Button
            color="info"
            size="sm"
            className="btn-round"
            onClick={handleRuleAdd}
          >
            Create New Feature ranking
          </Button>
        </ModalFooter>
      ) : null}
    </>
  );
};
