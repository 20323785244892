
export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "LIST_FILTERATTRIBUTES": {
            const { filterattributes } = action.data;
            return {
                ...state,
                filterAttributesList: filterattributes
            };
        }
        case "GET_FILTERATTRIBUTE": {
            const { filterAttributesDetail } = action.data;
            return {
                ...state,
                filterAttributesDetail,
            };
        }
        default: {
            return state;
        }
    }
};
