import React from 'react';
import Switch from 'react-switch';

// reactstrap components
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Form, Input, InputGroup } from 'reactstrap';
import { useStateValue } from 'context/RuleContext';

export default ({ sizeBrokenness, handleSizeBrokenness, index = 1, maxFragmentation = 5, showPinned = true }) => {
  const numberOfSizeFragmentation = new Array(maxFragmentation);
  numberOfSizeFragmentation.fill(1);
  const {
    configData: [configState, configDispatch]
  } = useStateValue();
  return (
    <>
      <div className="">
        <Card className="">
          <CardTitle className="px-3 pt-3" tag="h6">
            ACTIVE{' '}
            <label style={{ verticalAlign: 'middle' }}>
              <Switch
                onChange={checked =>
                  handleSizeBrokenness({
                    name: 'enabled',
                    value: checked
                  })
                }
                checked={sizeBrokenness.enabled}
              />
            </label>
          </CardTitle>
          <CardTitle className="px-3 pt-3" tag="h6">
            <label style={{ verticalAlign: 'middle' }}>Core Sizes</label>
          </CardTitle>
          <CardBody>
            <Form>
              {numberOfSizeFragmentation.map((fragmentation, i) => {
                const key = i === 0 ? '' : i + 1;
                return (
                  <Row>
                    <Col className="px-3" md="12">
                      <FormGroup>
                        <label>Select Field {i + 1}</label>
                        <InputGroup>
                          <select
                            className="form-control"
                            name={`sizeBrokenessField${key}`}
                            value={sizeBrokenness[`sizeBrokenessField${key}`]}
                            onChange={event => {
                              handleSizeBrokenness({
                                name: `sizeBrokenessField${key}`,
                                value: event.target.value,
                                index
                              });
                            }}
                            defaultValue=""
                            placeholder="Attribute"
                            type="text"
                          >
                            <option value="size">Size</option>
                            <option value="size_lingerie">Size Lingerie</option>
                            <option value="size_phone_cases">Size Phone Cases</option>
                            <option value="size_beauty">Size Beauty</option>
                            <option value="size_kids">Size Kids</option>
                          </select>

                          <Input
                            value={sizeBrokenness[`sizeBrokenessValue${key}`]}
                            onChange={event => {
                              handleSizeBrokenness({
                                name: `sizeBrokenessValue${key}`,
                                value: event.target.value
                              });
                            }}
                            defaultValue=""
                            placeholder="Value"
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                );
              })}

              {/* <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <label>Select Field 2</label>
                    <InputGroup>
                      <select
                        className="form-control"
                        name="sizeBrokenessField2"
                        value={sizeBrokenness.sizeBrokenessField2}
                        onChange={event => {
                          handleSizeBrokenness({
                            name: 'sizeBrokenessField2',
                            value: event.target.value,
                            index
                          });
                        }}
                        defaultValue=""
                        placeholder="Attribute"
                        type="text"
                      >
                        <option value="">--select--</option>
                        <option value="size_lingerie">Size Lingerie</option>
                        <option value="size">Size</option>
                        <option value="size_phone_cases">
                          Size Phone Cases
                        </option>
                        <option value="size_beauty">Size Beauty</option>
                      </select>

                      <Input
                        value={sizeBrokenness.sizeBrokenessValue2}
                        onChange={event => {
                          handleSizeBrokenness({
                            name: 'sizeBrokenessValue2',
                            value: event.target.value
                          });
                        }}
                        defaultValue=""
                        placeholder="Value"
                        type="text"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <label>Select Field 3</label>
                    <InputGroup>
                      <select
                        className="form-control"
                        name="sizeBrokenessField3"
                        value={sizeBrokenness.sizeBrokenessField3}
                        onChange={event => {
                          handleSizeBrokenness({
                            name: 'sizeBrokenessField3',
                            value: event.target.value,
                            index
                          });
                        }}
                        defaultValue=""
                        placeholder="Attribute"
                        type="text"
                      >
                        <option value="">--select--</option>
                        <option value="size_lingerie">Size Lingerie</option>
                        <option value="size">Size</option>
                        <option value="size_phone_cases">
                          Size Phone Cases
                        </option>
                        <option value="size_beauty">Size Beauty</option>
                      </select>

                      <Input
                        value={sizeBrokenness.sizeBrokenessValue3}
                        onChange={event => {
                          handleSizeBrokenness({
                            name: 'sizeBrokenessValue3',
                            value: event.target.value
                          });
                        }}
                        defaultValue=""
                        placeholder="Value"
                        type="text"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row>
                <Col className="px-3" md="6">
                  <FormGroup>
                    <label>Enter Number Of Other Sizes</label>
                    <Input
                      value={sizeBrokenness?.noOfOtherSizes}
                      onChange={event => {
                        handleSizeBrokenness({
                          name: 'noOfOtherSizes',
                          value: event.target.value
                        });
                      }}
                      defaultValue=""
                      placeholder="Value"
                      type="number"
                      min="0"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {showPinned ? (
                  <Col style={{ fontSize: '0.8em' }}>
                    Apply On Pinned Products
                    <label
                      style={{
                        zoom: 0.7,
                        verticalAlign: 'text-top',
                        marginLeft: 10
                      }}
                    >
                      <Switch
                        onChange={checked =>
                          handleSizeBrokenness({
                            name: 'pinnedProducts',
                            value: checked
                          })
                        }
                        checked={sizeBrokenness.pinnedProducts}
                      />
                    </label>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <span className="fs-10 c-brown-gray" style={{ float: 'right' }}>
                Note: Experimental Feature
              </span>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
