import axios from './axios';
import get from 'lodash.get';
import { SERVER_ENDPOINT } from 'constants/index';

import firebaseConfig from '../firebaseConfig';
const db = firebaseConfig.firestore();
firebaseConfig.firestore().settings({ experimentalForceLongPolling: true });

export const endpoint = `${SERVER_ENDPOINT}/save-rules`;
export const endpoint_remove = `${SERVER_ENDPOINT}/remove-spotlight`;

export async function saveRules({
  ruleGroups,
  ruleGroupsModal,
  boostRules,
  deBoostRules,
  id,
  ruleInfo,
  pinRules,
  rankingRules,
  newRankingRules,
  rowSeqFilters,
  sizeBrokenness,
  collectionInfo,
  assignedFeatureRankingList,
  featureRankingProperties,
  storeFront,
  updatedBy,
  type = 'curated',
  sortState
}) {
  const data = {
    ruleGroups,
    boostRules,
    deBoostRules,
    ruleGroupsModal,
    ruleInfo,
    rowSeqFilters,
    pinRules,
    id,
    rankingRules,
    collectionInfo,
    assignedFeatureRankingList,
    featureRankingProperties,
    type,
    sizeBrokenness,
    storeFront,
    updatedBy,
    newRankingRules
  };
  const result = await axios.post(endpoint, data, {
    timeout: 36000000,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  try {
    db.collection('curofy_rules')
      .doc(ruleInfo.contextRuleId)
      .set({ json: JSON.stringify(data) })
      .then(() => {})
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  } catch (e) {
    console.log(e, ruleInfo.contextRuleId);
    alert('Saving existing curated category failed', ruleInfo.contextRuleId);
  }

  return result;
}

export async function saveAllRules({
  ruleGroups,
  boostRules,
  deBoostRules,
  id,
  ruleInfo,
  pinRules,
  rankingRules,
  sizeBrokenness,
  storeFront,
  updatedBy
}) {
  const data = {
    ruleGroups,
    boostRules,
    deBoostRules,
    ruleInfo,
    pinRules,
    id,
    rankingRules,
    sizeBrokenness,
    storeFront,
    updatedBy
  };

  try {
    db.collection('curofy_rules')
      .doc(ruleInfo.contextRuleId)
      .set({ json: JSON.stringify(data) })
      .then(() => {})
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  } catch (e) {
    console.log(e, ruleInfo.contextRuleId);
    alert('Saving existing curated category failed', ruleInfo.contextRuleId);
  }
}

export async function restoreAllRulesFromFireStore() {
  try {
    db.collection('curofy_rules')
      .get()
      .then(data => {
        console.log('data.docChanges', data.docChanges);
        data.docChanges().map((res, i) => {
          setTimeout(() => {
            saveRules(JSON.parse(res.doc.data().json));
          }, i * 500);
          console.log('doc', JSON.parse(res.doc.data().json));
        });

        //saveRules()
      })
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  } catch (e) {
    console.error(e);
  }
}

export async function getRules({ id = '', sortInfo = {}, filterInfo = {}, type, dynamicSearch }, dispatch) {
  if (!id || id <= 0) {
    dispatch({
      type: 'FETCH_RULES_LIST_LOADING'
    });
  }
  const result = await axios.post(
    `${SERVER_ENDPOINT}/get-rules`,
    { id, sortInfo, filterInfo, type, dynamicSearch },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  if (id > 0 && result.data && result.data.body) {
    const {
      filterableAttributesList,
      maxFragmentation = 5,
      fixedFilterableAttributesList,
      sortByVMMetric
    } = result.data;
    // mergedFixcoreAttr = [...filterableAttributesList, ...fixedFilterableAttributesList];
    const mergedFixcoreAttr = Object.assign({}, fixedFilterableAttributesList, filterableAttributesList);
    const mergedfilterAttr = Object.assign({}, filterableAttributesList, fixedFilterableAttributesList);
    dispatch({
      type: 'RULE_DETAIL',
      data: {
        ruleDetail: {
          ...result.data.body._source,
          mergedfilterAttr,
          mergedFixcoreAttr,
          maxFragmentation
        },
        sortByVMMetric: sortByVMMetric
      }
    });
  } else {
    dispatch({
      type: 'FETCH_RULES',
      data: {
        rules: get(result, ['data', 'body', 'hits'], []),
        config: get(result, ['data', 'config'], {}),
        sortByVMMetric: result.data.sortByVMMetric
      }
    });
  }
  return get(result, ['data', 'body', 'hits'], []);
}
export async function getActivityLogs({ id = '', sortInfo = {}, filterInfo = {}, type }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/get-activities`,
    { id, sortInfo, filterInfo, type },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return get(result, ['data', 'data'], []);
}

export async function saveFlashSaleInfo({ flashSaleInfo = {}, type, subType }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/flash-sale-v2`,
    { flashSaleInfo },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  getRules({ type }, dispatch);
}

export async function deleteRule({ id = '', contextRuleId, type, flashSaleInfo, filterInfo, dynamicSearch }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/delete-rule`,
    { id, contextRuleId, flashSaleInfo, type },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getRules({ filterInfo, type, dynamicSearch }, dispatch);
  // console.log('result.data.body.hits.hits', result.data.body.hits);
  // dispatch({ type: 'FETCH_RULES', data: { rules: result.data.body.hits } });
  // return result.data.body.hits;
}

export async function deleteBulkRule({ id, contextRuleIds, flashSaleInfo, type, filterInfo, dynamicSearch }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/delete-bulk-rule`,
    { id, contextRuleIds, flashSaleInfo, type },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getRules({ filterInfo, type, dynamicSearch }, dispatch);
}

export const getStrapiImages = async dispatch => {
  const result = await axios.get(`https://stylishop.store/getstrapi`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  let contexts = {};
  const widgets = {};

  result.data.map(b => {
    return b.rows.map(row => {
      row.widgets.map(widget => {
        widgets[widget.contextName] = {
          promoName: row.analytics.promoName,
          rowName: row.name,
          rowType: row.rowType,
          widgetName: widget.name,
          widgetType: widget.widgetType,
          contextName: widget.contextName
        };
        if (widget.imageMedia?.[0]?.url) {
          contexts[widget.contextName] = widget.imageMedia && widget.imageMedia?.[0]?.url;
        }
      });
    });
  });

  const resultCat = await axios.get(`https://stylishop.store/getstrapi-categories`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  resultCat.data.map(b => {
    b.tabs.map(tab => {
      return tab.rows.map(row => {
        row.widgets.map(widget => {
          widgets[widget.contextName] = {
            promoName: row.analytics.promoName,
            rowName: row.name,
            rowType: row.rowType,
            widgetName: widget.name,
            widgetType: widget.widgetType,
            contextName: widget.contextName
          };
          contexts[widget.contextName] = widget.imageMedia && widget.imageMedia?.[0]?.url;
        });
      });
    });
  });
  // console.log('widgets', JSON.stringify(widgets));
  dispatch({
    type: 'STRAPI_IMAGES',
    data: { strapiImages: contexts, widgets }
  });
};
