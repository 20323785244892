import React, { useState } from 'react';
import { Card, Row, Col, FormGroup, CardBody, Form, Input } from 'reactstrap';
import { getPositionOfLineAndCharacter } from 'typescript';

const Metadata = ({ storeFront, handleStorefront }) => {
  const region = localStorage.getItem('LOCATION');
  const [seoMetadata, setSeoMetadata] = useState({ ...storeFront.seoMetadata });
  const [storeId, setStoreId] = useState(1);

  const handleChange = (event, key) => {
    let initialObj = { ...seoMetadata?.[event.target.name] };
    initialObj[storeId] = event.target.value;
    const updated = {
      ...seoMetadata,
      [key]: initialObj
    };
    setSeoMetadata(updated);
    handleStorefront({
      name: 'seoMetadata',
      value: updated
    });
  };

  const handleCanonicalChange = event => {
    const updated = {
      ...seoMetadata,
      canonical: event.target.value
    };
    setSeoMetadata(updated);
    handleStorefront({
      name: 'seoMetadata',
      value: updated
    });
  };

  const handleFilter = event => {
    setStoreId(event.target.value);
  };
  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <Row>
                <Col className="px-3" md="12">
                  Filter By{' '}
                  <FormGroup>
                    <select className="form-control" name="mainCategory" onChange={handleFilter}>
                      {region === 'IN' && <option value="51">IN-EN</option>}
                      {region !== 'IN' && (
                        <>
                          <option value="1">SA-EN</option>
                          <option value="3">SA-AR</option>
                          <option value="7">AE-EN</option>
                          <option value="11">AE-AR</option>
                          <option value="12">KW-EN</option>
                          <option value="13">KW-AR</option>
                          <option value="15">BH-EN</option>
                          <option value="17">BH-AR</option>
                        </>
                      )}
                    </select>

                    <label>Recommendation Title</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 10
                      }}
                      className="form-control"
                      name="title"
                      multiple={true}
                      value={seoMetadata?.title?.[storeId] || ''}
                      onChange={event => handleChange(event, 'title')}
                      defaultValue=""
                    ></textarea>

                    <label>Recommendation Description</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 20
                      }}
                      className="form-control"
                      name="description"
                      multiple={true}
                      value={seoMetadata?.description?.[storeId] || ''}
                      onChange={event => handleChange(event, 'description')}
                      defaultValue=""
                    ></textarea>

                    <label>Recommendation H1</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 10
                      }}
                      className="form-control"
                      name="h1"
                      multiple={true}
                      value={seoMetadata?.h1?.[storeId] || ''}
                      onChange={event => handleChange(event, 'h1')}
                      defaultValue=""
                    ></textarea>

                    <label>Recommendation H2</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 10
                      }}
                      className="form-control"
                      name="h2"
                      multiple={true}
                      value={seoMetadata?.h2?.[storeId] || ''}
                      onChange={event => handleChange(event, 'h2')}
                      defaultValue=""
                    ></textarea>

                    <label>HTML Content</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 100
                      }}
                      className="form-control"
                      name="seoHtmlContent"
                      multiple={true}
                      value={seoMetadata?.seoHtmlContent?.[storeId] || ''}
                      onChange={event => handleChange(event, 'seoHtmlContent')}
                      defaultValue=""
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <label>Canonical Name</label>
                  <Input
                    name="canonical"
                    value={seoMetadata?.canonical || ''}
                    onChange={event => handleCanonicalChange(event)}
                    placeholder={``}
                    type="text"
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Metadata;
