import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getSearchableAttribute,
  addSearchableAttribute,
} from "views/SearchableAttributes/actions";
import isEmpty from "lodash.isempty";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Input,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import { validator } from "utils/validator";
import { useStateValue } from "context/RuleContext";

export default ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const [searchAttributeForm, setSearchAttributeForm] = useState({});
  const [searchAttributeFormErrors, setSearchAttributeFormErrors] = useState(
    {}
  );
  const {
    searchattributes: [searchAttributesState, searchAttributesDispatch],
  } = useStateValue();
  useEffect(() => {
    if (id) {
      getSearchableAttribute({ id }, searchAttributesDispatch);
    } else {
      setSearchAttributeForm({});
    }
  }, []);
  useEffect(() => {
    if (id && searchAttributesState.searchableAttributesDetail) {
      console.log("searcattr",searchAttributesState.searchableAttributesDetail);
      setSearchAttributeForm(searchAttributesState.searchableAttributesDetail);
    }
  }, [searchAttributesState.searchableAttributesDetail]);

  const handleAddSearchAttributefield = () => {
    const {
      attributeName,
      attributeLabel,
      attributeType,
    } = searchAttributeForm;
    const validationErrors = validator({
      attributeName: {
        value: attributeName,
        name: "Attribute Name",
        validate: ["empty", "alpha"],
      },
      attributeLabel: {
        value: attributeLabel,
        name: "Attribute Label",
        validate: ["empty", "alpha"],
      },
      attributeType: {
        value: attributeType,
        name: "Attribute Type",
        validate: ["empty", "alpha"],
      },
    });

    setSearchAttributeFormErrors(validationErrors);

  
      if (isEmpty(validationErrors)) {
        addSearchableAttribute(
          { ...searchAttributeForm, id },
          searchAttributesDispatch,
          history
        );
        setSearchAttributeFormErrors({});
      }
      
    
  };

  const handleSearchAttributeFormChange = event => {
    const inputVal = event.target.value;
    const inputName = event.target.name;
    setSearchAttributeForm({
      ...searchAttributeForm,
      [inputName]: inputVal,
    });
  };
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add new searchable attribute </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive className="formTable">
                  <tbody>
                    <tr>
                      <td style={{ width: 250 }} className="text-left">
                        <Input
                          type="text"
                          name="attributeName"
                          value={searchAttributeForm.attributeName}
                          placeholder="Attribute Name"
                          onChange={(e) => handleSearchAttributeFormChange(e)}
                        />
                        {searchAttributeFormErrors.attributeName ? (
                          <span className="validationError">
                            {searchAttributeFormErrors.attributeName}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 250 }} className="text-left">
                        <Input
                          type="text"
                          name="attributeLabel"
                          value={searchAttributeForm.attributeLabel}
                          placeholder="Attribute Label"
                          onChange={(e) => handleSearchAttributeFormChange(e)}
                        />
                        {searchAttributeFormErrors.attributeLabel ? (
                          <span className="validationError">
                            {searchAttributeFormErrors.attributeLabel}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 250 }} className="text-left">
                        <Input
                          type="text"
                          name="attributeType"
                          value={searchAttributeForm.attributeType}
                          placeholder="Attribute Type"
                          onChange={(e) => handleSearchAttributeFormChange(e)}
                        />
                        {searchAttributeFormErrors.attributeType ? (
                          <span className="validationError">
                            {searchAttributeFormErrors.attributeType}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          color="info"
                          size="sm"
                          className="btn-round"
                          onClick={handleAddSearchAttributefield}
                        >
                          Add
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
