import React, { useState } from 'react';
import 'firebase/auth';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { useStateValue } from 'context/RuleContext';
import { productSTORES } from 'utils';

const envs = ['prod', 'qa', 'uat', 'dev'];
const localStores = productSTORES();

function Header() {
  //   const [isOpen, setOpen] = useState(false);
  //   const [dropdownOpen, setDropdownOpen] = useState(false);
  const [storeToggleOpen, seStoreToggleOpen] = useState(false);
  const [storeId, setStoreId] = useState(window.localStorage.storeId || 1);
  const [env, setEnv] = useState(
    window.localStorage.env ||
    (process.env.CUROFY_APP_NODE_ENV === 'production' ? 'prod' : 'uat')
  );

  const {
    rules: [, ruleDispatch]
  } = useStateValue();

  const storeToggle = e => {
    seStoreToggleOpen(!storeToggleOpen);
  };

  const handleSwitchEnv = env => {
    ruleDispatch({ type: 'SET_ENV', data: { env } });
    setEnv(env);
    window.localStorage.env = env;
  };
  const handleSwitchStores = storeId => {
    ruleDispatch({ type: 'SET_STORE', data: { storeId } });
    setStoreId(storeId);
    window.localStorage.storeId = storeId;
  };

  return (
    <Dropdown nav isOpen={storeToggleOpen} toggle={e => storeToggle(e)}>
      <DropdownToggle caret nav>
        {localStores[storeId]?.name}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(localStores).map(sId => {
          return (
            <DropdownItem key={sId} tag="a" onClick={() => handleSwitchStores(sId)}>
              {localStores[sId]?.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default Header;
