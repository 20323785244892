import React from 'react';
import { useDrop } from 'react-dnd';

import { ReactSortable } from 'react-sortablejs';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from 'reactstrap';
import Product from 'components/Product/Item.jsx';

const style = {
  // height: '12rem',
  width: '100%',
  marginRight: '1.5rem',
  marginBottom: '0px',
  // color: 'white',
  padding: '1rem 1rem 15px 1rem'
  // textAlign: 'center',
  // fontSize: '1rem',
  // lineHeight: 'normal',
  // float: 'left'
};
const PinnedItems = ({
  accept,
  lastDroppedItem,
  onDrop,
  sortPinnedItems,
  onDelete = () => {},
  sizeBrokenness
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = isOver && canDrop;
  let backgroundColor = '#eee';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }
  return (
    <div ref={drop} style={{ ...style, backgroundColor, textAlign: 'center' }}>
      <span
        className="text-center"
        style={{
          paddingBottom: 3,
          opacity: 0.8,
          color: isActive ? 'white' : 'inherit'
        }}
      >
        {isActive
          ? 'Release to drop'
          : `Please drop products and then drag to sort(MAX 80)`}
      </span>

      {/* {lastDroppedItem && (
        <p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>
      )} */}
      <ReactSortable
        list={lastDroppedItem}
        setList={sortPinnedItems}
        className="row"
        style={{ opacity: isActive ? 0.1 : 1 }}
      >
        {lastDroppedItem.map((item, key) => {
          return (
            <Col
              sm={4}
              md={3}
              xs={4}
              lg={1}
              key={key}
              className="showOnHoverWrap"
            >
              <a
                className="showOnHover"
                onClick={() => onDelete(item)}
                href="javascript:;"
                style={{
                  top: 0,
                  right: -4,
                  position: 'absolute',
                  color: 'red'
                }}
              >
                X
              </a>
              <Product
                product={item.product}
                renderedFrom="pinnedItem"
                sizeBrokenness={sizeBrokenness}
              />
            </Col>
          );
        })}
      </ReactSortable>
    </div>
  );
};
export default PinnedItems;
