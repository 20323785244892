import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';

// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Table, Row, Col, Button, FormGroup, Input, Alert } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import NotificatioModal from '../notificationModal';

import { tbody } from 'variables/general';
import { useStateValue } from 'context/RuleContext';
import {
  getRules,
  deleteRule,
  deleteBulkRule,
  getStrapiImages,
  saveAllRules,
  restoreAllRulesFromFireStore,
  saveFlashSaleInfo
} from 'variables/rules';
import isEmpty from 'lodash.isempty';

export default () => {
  let type = window.location.pathname.indexOf('collections') > -1 ? 'collection' : '';
  let subType;
  if (window.location.pathname.indexOf('flashsale') > -1) {
    subType = 'flashsale' || '';
  } else if (window.location.pathname.indexOf('curated_search') > -1) {
    subType = 'curated_search' || '';
    type = 'curated_search';
  }
  const history = useHistory();
  const [copied, setCopy] = useState(false);
  const sortInfoStorage = (window.localStorage &&
    window.localStorage.sortInfo &&
    JSON.parse(window.localStorage.sortInfo)) || {
    field: 'updatedBy.timestamp',
    order: 'desc'
  };

  const filterInfoStorage =
    (window.localStorage && window.localStorage.filterInfo && JSON.parse(window.localStorage.filterInfo)) || {};
  const dynamicSearchStorage =
    (window.localStorage && window.localStorage.dynamicSearch && JSON.parse(window.localStorage.dynamicSearch)) || {};

  const [sortInfo, setSortInfo] = useState(sortInfoStorage);
  const [filterInfo, setFilter] = useState(filterInfoStorage);
  const [dynamicSearch, setDynamicSearch] = useState(dynamicSearchStorage);
  const [configureShow, setConfigureShow] = useState(false);
  const {
    rules: [ruleState, ruleDispatch],
    configData: [configState, configDispatch]
  } = useStateValue();

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const [ruleid, setRuleid] = useState([]);
  const [contextid, setCcontextid] = useState([]);
  const [flashsaledata, setFlashsaledata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setModalContent] = useState();
  const { ruleList = [], ruleListLoading = false, widgets = {}, config: { flashSale = {} } = {} } = ruleState;

  useEffect(() => {
    setList(ruleList);
    if (type === 'collection' && subType !== 'flashsale') {
      const collectionData = ruleList.filter(({ _source: rule }) => {
        if (!rule.ruleInfo.flashSaleInfo) return true;
      });
      if (
        !sessionStorage.getItem('collection') &&
        collectionData &&
        collectionData?.length &&
        configState?.data?.collectionPurging > collectionData?.length &&
        configState?.data?.collectionNearestPurging <= collectionData?.length
      ) {
        setModalTitle('Warning Alert!');
        setModalContent(
          `You will reach maximum limit for number of flash sale (${configState?.data?.collectionPurging}). Delete unused entries to avoid blockage.`
        );
        setOpenModal(true);
        sessionStorage.setItem('collection', '1');
      } else if (
        !sessionStorage.getItem('collection_purging') &&
        collectionData &&
        collectionData?.length &&
        configState?.data?.collectionPurging <= collectionData?.length
      ) {
        setModalTitle('Limit Exceeded!');
        setModalContent(
          `You have reached maximum limit for number of collections (${configState?.data?.collectionPurging}). Delete unused entries to proceed further `
        );
        setOpenModal(true);
        sessionStorage.setItem('collection_purging', '1');
      }
    } else if (subType === 'flashsale') {
      const flashData = ruleList.filter(({ _source: rule }) => {
        if (rule.ruleInfo.flashSaleInfo) return true;
      });
      if (
        !sessionStorage.getItem('flashSale') &&
        flashData &&
        flashData?.length &&
        configState?.data?.flashSalePurging > flashData?.length &&
        configState?.data?.flashSaleNearestPurging <= flashData?.length &&
        subType === 'flashsale'
      ) {
        setModalTitle('Warning Alert!');
        setModalContent(
          `You will reach maximum limit for number of flash sale (${configState?.data?.flashSalePurging}). Delete unused entries to avoid blockage.`
        );
        setOpenModal(true);
        sessionStorage.setItem('flashSale', '1');
      } else if (
        !sessionStorage.getItem('flashSale_purging') &&
        flashData &&
        flashData?.length &&
        configState?.data?.flashSalePurging <= flashData?.length &&
        subType === 'flashsale'
      ) {
        setModalTitle('Limit Exceeded!');
        setModalContent(
          `You have reached maximum limit for number of flash sale (${configState?.data?.flashSalePurging}). Delete unused entries to proceed further`
        );
        setOpenModal(true);
        sessionStorage.setItem('flashSale_purging', '1');
      }
    } else {
      if (
        !sessionStorage.getItem('curated') &&
        ruleList?.length &&
        configState?.data?.curatedPurging > ruleList?.length &&
        configState?.data?.curatedNearestPurging <= ruleList?.length
      ) {
        setModalTitle('Warning Alert!');
        setModalContent(
          `You will reach maximum limit for number of curations (${configState?.data?.curatedPurging}). Delete unused entries to avoid blockage.`
        );
        setOpenModal(true);
        sessionStorage.setItem('curated', '1');
      } else if (
        !sessionStorage.getItem('curated_purging') &&
        ruleList?.length &&
        configState?.data?.curatedPurging <= ruleList?.length
      ) {
        setModalTitle('Limit Exceeded!');
        setModalContent(
          `You have reached maximum limit for number of curations (${configState?.data?.curatedPurging}). Delete unused entries to proceed further`
        );
        setOpenModal(true);
        sessionStorage.setItem('curated_purging', '1');
      }
    }
  }, [ruleList]);

  const handleClick = ({ cEvent, flashSaleInfo }) => {
    const { id, checked, name } = cEvent.target;
    setIsCheck([...isCheck, id]);
    if (isCheck) {
      setRuleid([...ruleid, id]);
      setCcontextid([...contextid, name]);
      setFlashsaledata([...flashsaledata, flashSaleInfo]);
    }
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    getRules({ sortInfo, filterInfo, type, dynamicSearch }, ruleDispatch);
  }, [sortInfo, filterInfo, dynamicSearch]);

  useEffect(() => {
    getStrapiImages(ruleDispatch);
  }, []);

  const handleBackupAllRules = () => {
    ruleState.ruleList.map(({ _source: rule }) => {
      saveAllRules(rule);
    });
  };

  const handleRestoreAllRules = () => {
    restoreAllRulesFromFireStore();
  };

  const { strapiImages = {} } = ruleState;
  const handleRuleDelete = (id, contextRuleId, flashSaleInfo, type) => {
    if (
      window.confirm(`Please make sure this context name has removed from Strapi.
Are you SURE you want to delete?`)
    )
      deleteRule({ id, contextRuleId, flashSaleInfo, type, filterInfo, dynamicSearch }, ruleDispatch);
  };

  const handleRuleDeleteAll = (ruleid, contextid, flashsaledata, type) => {
    if (
      window.confirm(`Please make sure these context names removed from Strapi.
  Are you SURE you want to delete these all ?`)
    )
      try {
        const id = ruleid;
        const contextRuleIds = contextid;
        const flashSaleInfo = flashsaledata ? flashsaledata : [];
        deleteBulkRule({ id, contextRuleIds, flashSaleInfo, type, filterInfo, dynamicSearch }, ruleDispatch);
        console.log('@DeleteBulkcollection - From Client (Curofy)');
      } catch (e) {
        console.error(e);
      }
  };

  const handleRuleEdit = id => {
    ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined }, isAddNew: true });
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    if (type === 'curated_search' || type === 'collection') {
      return history.push(`/admin/categories/collection/${id}?subType=${subType}`);
    }
    history.push(`/admin/categories/curated/${id}`);
  };
  const handleRuleAdd = id => {
    if (ruleList?.length) {
      if (subType === 'flashsale') {
        const flashData = ruleList.filter(({ _source: rule }) => {
          if (rule.ruleInfo.flashSaleInfo) return true;
        });
        if (flashData && flashData?.length && configState.data.flashSalePurging <= flashData?.length) {
          setModalTitle('Limit Exceeded!');
          setModalContent(
            `You have reached maximum limit for number of flash sale (${configState.data.flashSalePurging}). Delete unused entries to proceed further `
          );
          setOpenModal(true);
        } else {
          // setConfigureShow(!configureShow);
          ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined }, isAddNew: true });
          window.__isReactDndBackendSetUp = false;
          history.push(`/admin/categories/collection?subType=${subType}`);
        }
      } else {
        if (type === 'collection') {
          const collectionData = ruleList.filter(({ _source: rule }) => {
            if (!rule.ruleInfo.flashSaleInfo) return true;
          });
          if (
            collectionData &&
            collectionData?.length &&
            configState?.data?.collectionPurging <= collectionData?.length
          ) {
            setModalTitle('Limit Exceeded!');
            setModalContent(
              `You have reached maximum limit for number of collections (${configState?.data?.collectionPurging}). Delete unused entries to proceed further `
            );
            // setOpenModal(true);
          } else {
            ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined }, isAddNew: true });
            window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
            history.push(`/admin/categories/collection?subType=${subType}`);
          }
        } else {
          if (ruleList?.length && configState.data.curatedPurging <= ruleList?.length) {
            setModalTitle('Limit Exceeded!');
            setModalContent(
              `You have reached maximum limit for number of curations (${configState.data.curatedPurging}). Delete unused entries to proceed further `
            );
            setOpenModal(true);
          } else {
            ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined }, isAddNew: true });
            window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
            history.push(`/admin/categories?subType=${subType}`);
          }
        }
      }
    }
  };

  const handleSort = ({ field, order }) => {
    setSortInfo({ field, order: sortInfo.order === 'desc' ? 'asc' : 'desc' });
    window.localStorage.sortInfo = JSON.stringify({ field, order });
    // getRules({}, ruleDispatch);
  };
  const handleFilter = event => {
    const elementName = event.target.name;
    if (elementName === 'context_name') {
      setDynamicSearch({ txtSearch: event.target.value });
      window.localStorage.dynamicSearch = JSON.stringify({
        txtSearch: event.target.value
      });
    } else {
      setFilter({ field: 'ruleInfo.mainCategory', value: event.target.value });
      window.localStorage.filterInfo = JSON.stringify({
        field: 'ruleInfo.mainCategory',
        value: event.target.value
      });
    }
  };
  const onTextChange = event => {
    setDynamicSearch({ txtSearch: event.target.value });
  };
  //const { ruleList = [], widgets = {}, config: { flashSale: { ksa = {} } = {} } = {} } = ruleState;

  const [flashSaleInfo, setFlashSaleInfo] = useState({});
  const savedFlashSale = {};

  const handleExport = event => {
    const rows = [
      [
        'Title',
        'Arabic Title',
        'Context Name',
        'Main Category',
        'KSA English Deeplink',
        'KSA Arabic Deeplink',
        'UAE English Deeplink',
        'UAE Arabic Deeplink',
        'Promo Name',
        'Row Name',
        'Row Type',
        'Widget Name',
        'Widget Type'
      ]
    ];
    ruleList.map(({ _source: rule }) => {
      const mainCategory = (rule.ruleInfo.mainCategory || 'women').toLowerCase();
      const { promoName, rowName, rowType, widgetName, widgetType } = widgets[rule.ruleInfo.contextRuleId] || {};

      rows.push([
        rule.ruleInfo.ruleName,
        rule.ruleInfo.ruleNameArabic,
        rule.ruleInfo.contextRuleId,
        mainCategory,
        `https://stylishop.com/sa/en/list/${mainCategory}/context/${rule.ruleInfo.contextRuleId}`,
        `https://stylishop.com/sa/ar/list/${mainCategory}/context/${rule.ruleInfo.contextRuleId}`,
        `https://stylishop.com/ae/en/list/${mainCategory}/context/${rule.ruleInfo.contextRuleId}`,
        `https://stylishop.com/ae/ar/list/${mainCategory}/context/${rule.ruleInfo.contextRuleId}`,
        promoName,
        rowName,
        rowType,
        widgetName,
        widgetType
      ]);
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri, 'contexts.csv');
  };

  const rankingNames = {
    ranking: 'Custom-Ranking',
    'enrich.last30Rev': 'Last 30 Days'
  };
  let title =
    type === 'collection' ? (subType === 'flashsale' && '⚡️Flash Sale ⚡️') || 'Collections' : 'Curated Categories';
  if (subType === 'curated_search') {
    title = 'Curated Search';
  }
  const handleFlashSaleDateChange = (momentObj, key) => {
    setFlashSaleInfo({ ...flashSaleInfo, [key]: momentObj.toISOString() });
    //  onRuleInfoChange({ flashSaleInfo: { [key]: momentObj.toISOString() } });
  };

  const handleFlashSaleColorChange = event => {
    setFlashSaleInfo({ ...flashSaleInfo, color: event.target.value });
    //  onRuleInfoChange({ flashSaleInfo: { [key]: momentObj.toISOString() } });
  };

  const handleFlashSaleSave = async (momentObj, key) => {
    const result = await saveFlashSaleInfo({ flashSaleInfo, type }, ruleDispatch);
    window.alert('Flash Sale Configuration Saved SuccessFully');
    setConfigureShow(false);

    //  onRuleInfoChange({ flashSaleInfo: { [key]: momentObj.toISOString() } });
  };
  let isFlashSaleActive = false;
  let nextFlashSale = false;
  if (savedFlashSale.active && savedFlashSale.start) {
    if (new Date() >= new Date(savedFlashSale.start) && new Date() <= new Date(savedFlashSale.end)) {
      isFlashSaleActive = true;
    }
  }
  if (!isFlashSaleActive && savedFlashSale.active && new Date() < new Date(savedFlashSale.start)) {
    nextFlashSale = true;
  }
  return (
    <>
      <PanelHeader size="sm"></PanelHeader>
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col md="4">
                    <CardTitle tag="h4">
                      {title}&nbsp;
                      <Button
                        color="info"
                        size="sm"
                        className="btn-round"
                        onClick={handleRuleAdd}
                        disabled={ruleListLoading}
                      >
                        {/* {subType === 'flashsale' ? 'Configure Flash Sale' : 'Add New'} */}
                        Add New
                      </Button>
                      {subType === 'flashsale' && (isFlashSaleActive || nextFlashSale) ? (
                        <div>
                          <small className="c-avocado-green p-4 fs-14">
                            {nextFlashSale ? 'Next Flashsale starts at' : 'FlashSale is active'}:{' '}
                            <b>
                              {moment(savedFlashSale.start).format('ddd, Do MMM hh:mm a')} -{' '}
                              {moment(savedFlashSale.end).format('ddd, Do MMM hh:mm a')}
                            </b>
                          </small>
                        </div>
                      ) : null}
                    </CardTitle>

                    {configureShow ? (
                      <form className=" border-dark p-lg-4 " style={{ backgroundColor: '#fff5d8', borderRadius: 18 }}>
                        <fieldset className="w-100 bg">
                          <legend class="text-lg-left fa-1x">
                            <span class="c-sap-gray">Configure Flash Sale</span>
                          </legend>
                          <Row>
                            <Col className="text-left" md="6">
                              <FormGroup>
                                <small className="c-dark-gray">Start Time</small>
                                <Datetime
                                  inputProps={{
                                    readOnly: true,
                                    style: { color: 'var(--dark-gray)' }
                                  }}
                                  style={{ color: 'var(--dark-gray)' }}
                                  dateFormat="ddd, Do MMM"
                                  value={flashSaleInfo.start && new Date(flashSaleInfo.start)}
                                  onChange={event => {
                                    handleFlashSaleDateChange(event, 'start');
                                  }}
                                />
                                <label>
                                  KSA Time: {moment(flashSaleInfo.start).tz('Asia/Riyadh').format('hh:mm a')}
                                </label>
                              </FormGroup>
                            </Col>
                            <Col className="text-left" md="6">
                              <FormGroup>
                                <small className="c-dark-gray">End Time</small>
                                <Datetime
                                  inputProps={{
                                    readOnly: true,
                                    style: { color: 'var(--dark-gray)' }
                                  }}
                                  dateFormat="ddd, Do MMM "
                                  value={flashSaleInfo.end && new Date(flashSaleInfo.end)}
                                  onChange={event => {
                                    handleFlashSaleDateChange(event, 'end');
                                  }}
                                />
                                <label>KSA Time: {moment(flashSaleInfo.end).tz('Asia/Riyadh').format('hh:mm a')}</label>
                              </FormGroup>
                            </Col>
                            <Col className="text-left" md="6">
                              <FormGroup>
                                <small className="c-dark-gray">Background Color</small>
                                <Input
                                  type="color"
                                  value={flashSaleInfo.color || '#fcecbc'}
                                  style={{ height: 48, width: 100 }}
                                  onChange={handleFlashSaleColorChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button color="" size="sm" className="btn-round" onClick={() => setConfigureShow(false)}>
                            Cancel
                          </Button>
                          <Button color="info" size="sm" className="btn-round" onClick={handleFlashSaleSave}>
                            Save
                          </Button>
                        </fieldset>
                      </form>
                    ) : null}
                  </Col>

                  <Col md="8" style={{ alignContent: 'flex-end', textAlign: 'right', float: 'right' }}>
                    <Row
                      style={{
                        marginRight: 0
                      }}
                    >
                      <Col
                        md="12"
                        style={{
                          alignItems: 'flex-end',
                          textAlign: 'right'
                        }}
                      >
                        <FormGroup>
                          <Row>
                            <Col md="4" style={{ alignContent: 'flex-end', textAlign: 'right', float: 'right' }}>
                              Search <label>Context Name:</label>
                              <input
                                type="context_name"
                                name="context_name"
                                onBlur={handleFilter}
                                value={dynamicSearch.txtSearch}
                                onChange={onTextChange}
                              />
                            </Col>
                            <Col md="2" style={{ alignContent: 'flex-end', textAlign: 'right' }}>
                              <select
                                className="form-control"
                                name="mainCategory"
                                value={filterInfo.value}
                                onChange={handleFilter}
                              >
                                <option value="">All</option>
                                <option value="Women">Women</option>
                                <option value="Men">Men</option>
                                <option value="Beauty">Beauty</option>
                                <option value="Kids">Kids</option>
                                <option value="Home">Home</option>
                              </select>
                            </Col>
                            <Col md="6" style={{ alignContent: 'flex-end', textAlign: 'right' }}>
                              <Button onClick={handleExport} className="btn-round btn btn-info btn-sm">
                                Export CSV - {filterInfo.value || 'All'}
                              </Button>
                              {window.location.search.indexOf('superAdmin') > -1 ? (
                                <>
                                  <Button onClick={handleBackupAllRules} className="btn-round btn btn-info btn-sm">
                                    Backup
                                  </Button>
                                  <Button onClick={handleRestoreAllRules} className="btn-round btn btn-info btn-sm">
                                    Restore
                                  </Button>
                                </>
                              ) : null}
                              {isCheck.length >= 1 ? (
                                <Button
                                  onClick={() => handleRuleDeleteAll(ruleid, contextid, flashsaledata, type)}
                                  className="btn-round btn btn-info btn-sm btn btn-secondary"
                                >
                                  Delete All Selected
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 100 }}>
                        {/* <input type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          onChange={handleSelectAll}
                          checked={isCheckAll} />  */}
                        Select
                      </th>
                      <th style={{ width: 100 }}>Image</th>

                      {subType === 'curated_search' ? null : (
                        <th>
                          <a
                            href="javascript:;"
                            onClick={() => {
                              handleSort({ field: 'ruleInfo.ruleName.keyword' });
                            }}
                          >
                            Name {sortInfo.field === 'ruleInfo.ruleName.keyword' ? `(${sortInfo.order})` : ''}
                          </a>
                        </th>
                      )}
                      {subType === 'curated_search' ? (
                        <th>
                          Search Curation Name
                          <small style={{ fontSize: '0.5em' }}>&nbsp;(Click to copy)</small>
                        </th>
                      ) : (
                        <th>
                          Context Name
                          <small style={{ fontSize: '0.5em' }}>&nbsp;(Click to copy)</small>
                        </th>
                      )}

                      <th>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            handleSort({
                              field: 'updatedBy.timestamp',
                              order: 'desc'
                            });
                          }}
                        >
                          Last updated {sortInfo.field === 'updatedBy.timestamp' ? `(${sortInfo.order})` : ''}
                        </a>
                      </th>
                      {/* <th className="text-center">No. of Views</th>
                      <th className="text-center">Convertions</th> */}
                      <th className="text-left" style={{ width: 250 }}>
                        Features Used
                      </th>
                      <th className="text-left" style={{ width: 160 }}>
                        Ranking
                      </th>
                      <th className="text-right">Preview</th>
                      <th className="text-right">Edit</th>
                      <th className="text-right">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ruleList.map((prop, key) => {
                      const {
                        _id: id,
                        _source: {
                          ruleInfo: { ruleName, contextRuleId, flashSaleInfo = {} } = {},
                          boostRules = [],
                          pinRules = [],
                          sizeBrokenness = [],
                          rankingRules = [],
                          updatedBy: { email, timestamp } = {}
                        } = {}
                      } = prop;
                      if (
                        (subType === 'flashsale' && !flashSaleInfo.flashSaleId) ||
                        (subType !== 'flashsale' && flashSaleInfo.flashSaleId)
                      ) {
                        return null;
                      }
                      return (
                        <tr key={key}>
                          <td>{key + 1}.</td>
                          <td>
                            <input
                              type="checkbox"
                              name={contextRuleId}
                              id={id}
                              onChange={cEvent => handleClick({ cEvent, flashSaleInfo })}
                              checked={isCheck.includes(id)}
                            />
                          </td>
                          <td>
                            <a href="javascript:;" onClick={() => handleRuleEdit(id)}>
                              <img width="100" src={strapiImages[contextRuleId]} />
                            </a>
                          </td>
                          {subType === 'curated_search' ? null : (
                            <td style={{ width: 150 }} className="text-left" onClick={() => handleRuleEdit(id)}>
                              {ruleName}
                            </td>
                          )}
                          <td style={{ width: 200 }} className="text-left">
                            <a href="javascript:;">
                              <CopyToClipboard text={contextRuleId} onCopy={() => setCopy(contextRuleId)}>
                                <span>
                                  {contextRuleId}
                                  <span
                                    href="javascript:;"
                                    style={{
                                      color: contextRuleId === copied ? 'green' : ''
                                    }}
                                  >
                                    {contextRuleId === copied ? ' - Copied' : ''}
                                  </span>
                                </span>
                              </CopyToClipboard>
                              <b style={{ textAlign: 'right' }}>
                                &nbsp;&nbsp;
                                {timestamp + 60000 > new Date().getTime() ? (
                                  <a href="javascript:window.reload();">
                                    <small>updating storefront in 1 min...</small>
                                  </a>
                                ) : null}
                              </b>
                            </a>

                            {subType === 'flashsale' ? (
                              <>
                                <br />
                                <span>
                                  {flashSaleInfo.active &&
                                  flashSaleInfo.start &&
                                  new Date() >= new Date(flashSaleInfo.start) &&
                                  new Date() <= new Date(flashSaleInfo.end) ? (
                                    <span className="c-avocado-green">
                                      <b>
                                        {moment(flashSaleInfo.start).format('ddd, Do MMM hh:mm a')} - <br />
                                        {moment(flashSaleInfo.end).format('ddd, Do MMM hh:mm a')}
                                      </b>
                                    </span>
                                  ) : (
                                    <span>
                                      {moment(flashSaleInfo.start).format('ddd, Do MMM hh:mm a')} -{' '}
                                      {moment(flashSaleInfo.end).format('ddd, Do MMM hh:mm a')}
                                    </span>
                                  )}
                                </span>
                                <br />
                                <span>
                                  <b>Priority : </b>
                                  {flashSaleInfo.flashSalePriority}
                                </span>
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="text-left" onClick={() => handleRuleEdit(id)}>
                            {email ? email : ''}
                            <br />
                            {timestamp && new Date(timestamp).toDateString()} @{' '}
                            {timestamp && new Date(timestamp).toLocaleTimeString()}
                          </td>
                          <td className="text-left" onClick={() => handleRuleEdit(id)}>
                            Pinned Products:{' '}
                            <b className={[pinRules.length ? 'c-sap-green' : 'c-light-sage']}>{pinRules.length}</b>
                            <br />
                            Boosting Rules:{' '}
                            <b className={[boostRules.length ? 'c-sap-green' : 'c-light-sage']}>{boostRules.length}</b>
                            <br />
                            Size Brokenness:{' '}
                            <b className={[sizeBrokenness && sizeBrokenness.enabled ? 'c-sap-green' : 'c-light-sage']}>
                              {sizeBrokenness && sizeBrokenness.enabled ? 'Enabled' : 'Disabled'}
                            </b>
                          </td>
                          {process.env.CUROFY_APP_NODE_ENV !== 'production' ? (
                            <td style={{ color: '#4570b9', width: 150 }} className="text-right">
                              <a
                                href={`//uat.stylifashion.com/sa/en/list/women/context/${contextRuleId}`}
                                target="_blank"
                              >
                                UAT
                              </a>{' '}
                              |{' '}
                              <a
                                href={`//qa.stylifashion.com/sa/en/list/women/context/${contextRuleId}`}
                                target="_blank"
                              >
                                QA
                              </a>
                            </td>
                          ) : (
                            <td style={{ color: '#4570b9', width: 200 }} className="text-right">
                              <a href={`//stylishop.com/sa/en/list/women/context/${contextRuleId}`} target="_blank">
                                KSA
                              </a>{' '}
                              |{' '}
                              <a href={`//stylishop.com/ae/en/list/women/context/${contextRuleId}`} target="_blank">
                                UAE
                              </a>
                            </td>
                          )}

                          <td
                            style={{ color: '#4570b9', width: 50 }}
                            className="text-right"
                            onClick={() => handleRuleEdit(id)}
                          >
                            Edit
                          </td>
                          <td
                            style={{ color: 'red', width: 50 }}
                            className="text-right"
                            onClick={() => handleRuleDelete(id, contextRuleId, flashSaleInfo, type)}
                          >
                            Delete
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <NotificatioModal openModal={openModal} title={modalTitle} content={modalContent} setOpenModal={setOpenModal} />
    </>
  );
};
