import React from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { boostRuleList } from 'constants/index';

export default ({
  boostRules = [],
  handleAddNewRule = () => {},
  setBoostRules = () => {},
  handleBoostRuleTextChange = () => {},
  handleBoostRuleDelete = () => {},
  deboost = false
}) => {
  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <ReactSortable list={boostRules} setList={setBoostRules}>
                {boostRules.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <Row>
                        <Col className="px-3" md="12">
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>{index + 1}</InputGroupText>
                              </InputGroupAddon>
                              <select
                                className="form-control"
                                value={item.attribute}
                                onChange={event => {
                                  handleBoostRuleTextChange({
                                    type: 'attribute',
                                    attribute: event.target.value,
                                    id: item.id
                                  });
                                }}
                                defaultValue=""
                                placeholder="Attribute"
                                type="text"
                              >
                                <option>Choose</option>
                                {Object.keys(boostRuleList).map(key => {
                                  return (
                                    <option value={key} title={boostRuleList[key].hint}>
                                      {boostRuleList[key].label}
                                    </option>
                                  );
                                })}
                                <option value="collection">Collection</option>
                              </select>
                              <select
                                value={item.operator}
                                onChange={event => {
                                  handleBoostRuleTextChange({
                                    operator: event.target.value,
                                    type: 'operator',
                                    id: item.id
                                  });
                                }}
                                className=" form-control"
                                style={{ height: 40 }}
                              >
                                <option value="is">is</option>
                                <option value="lte">&le;</option>
                                <option value="gte">&ge;</option>
                                <option value="between">between</option>
                                <option value="contains">contains</option>
                                <option value="exists">exists</option>
                              </select>
                              <Input
                                value={item.value}
                                onChange={event => {
                                  handleBoostRuleTextChange({
                                    type: 'value',

                                    value: event.target.value,
                                    id: item.id
                                  });
                                }}
                                defaultValue=""
                                placeholder="Value"
                                type="text"
                              />
                              <InputGroupAddon
                                addonType="append"
                                onClick={() => handleBoostRuleDelete(item.id, deboost)}
                              >
                                <InputGroupText>
                                  <span className="c-red">X</span>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </ReactSortable>
              <Button color="info" size="sm" className="btn-round" onClick={() => handleAddNewRule(deboost)}>
                Add new rule
              </Button>
              <span className="fs-10 c-brown-gray" style={{ float: 'right' }}>
                Note: Values are case-sensitive
              </span>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
