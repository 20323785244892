/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Category from 'views/Category.jsx';
import Listing from 'views/Listing';
import Addsearchableattribute from 'views/SearchableAttributes/Addsearchableattribute';
import FilterListing from 'views/FilterListing';
// import CustomGlobalRanking from 'views/CustomGlobalRanking/depc.jsx';
import CustomRanking from 'views/CustomGlobalRanking/CustomRanking';
import CategorySlotting from 'views/CategorySlotting/CategorySlotting';
import NewCategorySet from 'views/CategorySlotting/newCategorySet';
import ProductBadge from 'views/PromoBadge';
import Monitoring from 'views/Monitoring';
import MonitoringIndicies from 'views/Monitoring/indices';
import AddFilterAttribute from 'views/FilterListing/AddFilterAttribute';
import Feed from 'views/Feed/Feed';
import PDPBanner from 'views/PDPBanner';
import verifyotp from 'views/verify-otp';

let dashRoutes = [
  {
    path: '/listing',
    name: 'Curated Categories',
    icon: 'shopping_box',
    component: Listing,
    layout: '/admin'
  },
  {
    path: '/collections/listing',
    name: 'Collections',
    icon: 'files_box',
    component: Listing,
    layout: '/admin'
  },

  {
    path: '/collections/flashsale',
    name: 'Flash Sale',
    icon: 'c-white fa fa-2x fa-bolt',
    'fa-icons': true,
    component: Listing,
    layout: '/admin'
  },
  {
    path: '/promobadge',
    name: 'Promo Badge',
    icon: 'business_badge',
    component: ProductBadge,
    layout: '/admin'
  },
  {
    path: '/pdpbanner',
    name: 'PDP Banner',
    icon: 'design_vector',
    component: PDPBanner,
    layout: '/admin'
  },
  {
    path: '/categories/:type/:id',
    name: 'Collections',
    icon: 'design_app',
    params: { type: 'collection' },
    component: Category,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/categories/:type',
    name: 'Collections',
    icon: 'design_app',
    params: { type: 'collection' },
    component: Category,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/category/type/:type',
    name: 'Collections',
    icon: 'design_app',
    component: Category,
    layout: '/admin',
    isHidden: true
  },

  // {
  //   path: '/categories/:id',
  //   name: 'Categories',
  //   icon: 'design_app',
  //   component: Category,
  //   layout: '/admin',
  //   isHidden: true
  // },
  {
    path: '/categories',
    name: 'Categories',
    icon: 'design_app',
    component: Category,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/facets',
    name: 'Global Facets',
    icon: 'design-2_ruler-pencil',
    component: Category,
    layout: '/admin',
    isHidden: true
  },
  // {
  //   path: '/settings',
  //   name: 'Fields',
  //   icon: 'ui-1_settings-gear-63',
  //   component: Category,
  //   layout: '/admin',
  //   isHidden: false
  // },
  // {
  //   path: '/searchableattributes',
  //   name: 'Searchable attributes',
  //   icon: 'ui-1_settings-gear-63',
  //   component: SearchableAttributes,
  //   layout: '/admin',
  //   isHidden: false
  // },
  {
    path: '/addsearchableattribute',
    name: 'Addsearchableattribute',
    icon: 'ui-1_settings-gear-63',
    component: Addsearchableattribute,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/custom-ranking/:id',
    name: 'Custom Global Ranking Detail',
    icon: 'design_bullet-list-67',
    component: CustomRanking,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/custom-ranking/',
    name: 'Custom Global Ranking',
    icon: 'design_bullet-list-67',
    component: CustomRanking,
    layout: '/admin',
    isHidden: false
  },
  {
    path: '/category-slotting/:id',
    name: 'Category Slotting Details',
    icon: 'business_chart-pie-36',
    component: CategorySlotting,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/category-slotting/',
    name: 'Category Slotting',
    icon: 'business_chart-pie-36',
    component: CategorySlotting,
    layout: '/admin',
    isHidden: false
  },
  {
    path: '/category-set/:uuid',
    name: 'Category Slotting',
    icon: 'business_chart-pie-36',
    component: NewCategorySet,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/category-set/',
    name: 'Category Slotting',
    icon: 'business_chart-pie-36',
    component: NewCategorySet,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    icon: 'education_glasses',
    component: Monitoring,
    layout: '/admin',
    isHidden: false
  },
  {
    path: '/feed',
    name: 'Feed',
    icon: 'files_box',
    component: Feed,
    layout: '/admin',
    isHidden: false
  },
  {
    path: '/monitoring-indicies',
    name: 'Monitoring',
    icon: 'ui-1_settings-gear-63',
    component: MonitoringIndicies,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/addfilterattribute',
    name: 'Add Filter Attribute',
    icon: 'ui-1_settings-gear-63',
    component: AddFilterAttribute,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/editfilterattribute/:id',
    name: 'Edit Filter Attribute',
    icon: 'ui-1_settings-gear-63',
    component: AddFilterAttribute,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/deletefilterattribute/:id',
    name: 'Delete Filter Attribute',
    icon: 'ui-1_settings-gear-63',
    component: FilterListing,
    layout: '/admin',
    isHidden: true
  },
  {
    path: '/filters',
    name: 'Developer Settings',
    icon: 'ui-1_settings-gear-63',
    component: FilterListing,
    layout: '/admin',
    isHidden: false
  },
  {
    path: '/verify-otp',
    name: 'verify-otp',
    component: verifyotp,
    layout: '/admin',
    isHidden: true
  }
  // {
  //   path: '/ultron',
  //   name: 'Ultron',
  //   icon: 'ui-1_settings-gear-63',
  //   component: UltronListing,
  //   layout: '/admin',
  //   isHidden: false
  // }
  // {
  //   path: '/icons',
  //   name: 'Icons',
  //   icon: 'design_image',
  //   component: Icons,
  //   layout: '/admin'
  // },
  // {
  //   path: '/maps',
  //   name: 'Maps',
  //   icon: 'location_map-big',
  //   component: Maps,
  //   layout: '/admin'
  // },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   icon: 'ui-1_bell-53',
  //   component: Notifications,
  //   layout: '/admin'
  // },
  // {
  //   path: '/user-page',
  //   name: 'User Profile',
  //   icon: 'users_single-02',
  //   component: UserPage,
  //   layout: '/admin'
  // },
  // {
  //   path: '/extended-tables',
  //   name: 'Table List',
  //   icon: 'files_paper',
  //   component: TableList,
  //   layout: '/admin'
  // },
  // {
  //   path: '/typography',
  //   name: 'Typography',
  //   icon: 'design-2_ruler-pencil',
  //   component: Typography,
  //   layout: '/admin'
  // },
  // {
  //   pro: true,
  //   path: '/upgrade',
  //   name: 'Upgrade to PRO',
  //   icon: 'objects_spaceship',
  //   component: Upgrade,
  //   layout: '/admin'
  // }
];
export default dashRoutes;
