import React, { useEffect, useState } from 'react';
import axios from '../../variables/axios';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { rankingList, SERVER_ENDPOINT } from 'constants/index';
import AssignList from 'views/FeatureRanking/AssignList';

const fetchEndpoint = `${SERVER_ENDPOINT}/fetch-global-rules`;

export default ({
  newRankingRules = null,
  handleNewRankChange = () => {},
  handleSaveRules = () => {},
  isVMMetricEnable
}) => {
  const defaultRankingRule = {
    rank: 'def_rank',
    order: 'asc',
    isContextMultiCategory: false
  };
  const [globalRanks, setGlobalRanks] = useState([]);
  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <select
                      className="form-control"
                      name={`rank`}
                      value={newRankingRules ? newRankingRules['rank'] : defaultRankingRule['rank']}
                      onChange={event => {
                        handleNewRankChange({
                          name: `rank`,
                          value: event.target.value
                        });
                      }}
                      defaultValue=""
                      placeholder="Attribute"
                      type="text"
                    >
                      {isVMMetricEnable && isVMMetricEnable === true && <option value="def_rank">Default Rank</option>}
                      <option value="bau_rank">BAU Rank</option>
                      <option value="sale_rank">Sale Rank</option>
                      <option value="fresh_rank">Fresh Rank</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
