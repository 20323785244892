import firebaseConfig from '../../firebaseConfig';
const db = firebaseConfig.firestore();
firebaseConfig.firestore().settings({ experimentalForceLongPolling: true });
export const getFilterAttributes = (opts, dispatch) => {
  db.collection('curofy_filterable_fields')
    .orderBy('order')
    .get()
    .then(data => {
      const docChanges = data.docChanges();
      const filterattributes = [];
      docChanges.forEach(change => {
        const { doc } = change;
        const docData = doc.data();
        filterattributes.push({ ...docData, id: doc.id });
      });
      dispatch({
        type: 'LIST_FILTERATTRIBUTES',
        data: { filterattributes }
      });
    });
};

export const getFilterAttribute = ({ id }, dispatch) => {
  db.collection('curofy_filterable_fields')
    .doc(id)
    .get()
    .then(doc => {
      const docChanges = doc.data();

      dispatch({
        type: 'GET_FILTERATTRIBUTE',
        data: { filterAttributesDetail: docChanges }
      });
    });
};

export const addFilterAttribute = (filterAttributeForm, dispatch, history) => {
  const { fieldLabel, fieldValue, fieldHint, order, id } = filterAttributeForm;
  if (!id) {
    db.collection('curofy_filterable_fields')
      .add({
        fieldLabel,
        fieldValue,
        fieldHint,
        order
      })
      .then(() => {
        getFilterAttributes({}, dispatch);
        history.push(`/admin/filters`);
      })
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  } else {
    db.collection('curofy_filterable_fields')
      .doc(id)
      .update({
        fieldLabel,
        fieldValue,
        fieldHint
      })
      .then(() => {
        console.log('Successfully updated in filter attributes');
        getFilterAttributes({}, dispatch);
        history.push(`/admin/filters`);
      })
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  }

  dispatch({ type: 'ADD_FILTERATTRIBUTE', data: {} });
};

export const deleteFilterAttribute = (
  filterAttributeForm,
  dispatch,
  history
) => {
  const { id } = filterAttributeForm;
  db.collection('curofy_filterable_fields')
    .doc(id)
    .delete()
    .then(() => {
      getFilterAttributes({}, dispatch);
      history.push(`/admin/filters`);
      dispatch({ type: 'DELETE_FILTERATTRIBUTES', data: {} });
    })
    .catch(error => {
      console.log('Error deleting document: ', error);
    });
};
