import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const getSearchableAttributes = (opts, dispatch) => {
  const firestore = firebase.firestore();
  firestore
    .collection('searchableattributes')
    .get()
    .then(data => {
      const docChanges = data.docChanges();
      const searchableattributes = [];
      docChanges.forEach(change => {
        const { doc } = change;
        const docData = doc.data();
        searchableattributes.push({ ...docData, id: doc.id });
      });
      dispatch({
        type: 'LIST_SEARCHABLEATTRIBUTES',
        data: { searchableattributes }
      });
    });
};

export const getSearchableAttribute = ({ id }, dispatch) => {
  const firestore = firebase.firestore();
  firestore
    .collection('searchableattributes')
    .doc(id)
    .get()
    .then(doc => {
      const docChanges = doc.data();

      dispatch({
        type: 'GET_SEARCHABLEATTRIBUTE',
        data: { searchableAttributesDetail: docChanges }
      });
    });
};

export const addSearchableAttribute = (
  searchableAttributeForm,
  dispatch,
  history
) => {
  const firestore = firebase.firestore();

  const {
    attributeName,
    attributeLabel,
    attributeType,
    id
  } = searchableAttributeForm;
  if (!id) {
    firestore
      .collection('searchableattributes')
      .add({
        attributeName,
        attributeLabel,
        attributeType
      })
      .then(() => {
        getSearchableAttributes({}, dispatch);
        history.push(`/admin/searchableattributes`);
      })
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  } else {
    firestore
      .collection('searchableattributes')
      .doc(id)
      .update({
        attributeName,
        attributeLabel,
        attributeType
      })
      .then(() => {
        console.log('Successfully updated searchable attributes');
        getSearchableAttributes({}, dispatch);
        history.push(`/admin/searchableattributes`);
      })
      .catch(error => {
        console.log('Error writing document: ', error);
      });
  }

  dispatch({ type: 'ADD_SEARCHABLEATTRIBUTE', data: {} });
};

export const deleteSearchableAttribute = (
  searchableAttributeForm,
  dispatch,
  history
) => {
  const { id } = searchableAttributeForm;
  const firestore = firebase.firestore();
  firestore
    .collection('searchableattributes')
    .doc(id)
    .delete()
    .then(() => {
      getSearchableAttributes({}, dispatch);
      history.push(`/admin/searchableattributes`);
      dispatch({ type: 'DELETE_SEARCHABLEATTRIBUTES', data: {} });
    })
    .catch(error => {
      console.log('Error deleting document: ', error);
    });
};
