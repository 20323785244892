import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import axios from '../../variables/axios';
import { SERVER_ENDPOINT, SERVER_ENDPOINT_FEED } from 'constants/index';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
// reactstrap components
// core components
import Product from 'components/Product/index.jsx';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import StoreSelection from 'views/ProductNavigation/StoreSelection';

import { getProducts } from 'variables/products';
import { useStateValue } from 'context/RuleContext';
import { ACTION_SHOW_TOAST, showToast } from '../Expression/actions.js';
import RowSequencingAdvanced from '../Expression/RowSequencingAdvanced';
import Sizebrokenness from '../Expression/Sizebrokenness';
import { getCurrentUser } from '../../utils/index';

const endpoint = `${SERVER_ENDPOINT}/save-global-rules`;
const fetchEndpoint = `${SERVER_ENDPOINT}/fetch-global-rules`;
const singleRankUpdate = `${SERVER_ENDPOINT_FEED}/v2/updateGlobalRanks`;
let newID = new Date();

const CustomRanking = ({
  match: {
    params: { id, type }
  }
}) => {
  const subType = window.location.search.indexOf('flashsale') > -1 ? 'flashsale' : '';
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [pinBySKU, showPinBySKU] = useState(false);
  const [rowSeqFilters, setRowSeqFilters] = useState({});
  const [globalRules, setGlobalRules] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [categoryId, setCategoryId] = useState(343);
  const [idCustom, setIdCustom] = useState('');
  const [ruleGroups, setRuleGroups] = useState();
  const [totalInfo, setProductTotal] = useState({});
  const [sortState, setSortState] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [ruleInfo, setRuleInfo] = useState({});
  const [updatedBy, setRuleUpdatedBy] = useState({});
  const [timeTook, setProductTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [listingError, setListingError] = useState('');
  const [pinRules, setPinRules] = useState([]);
  const [collectionInfo, setCollectionFile] = useState({});
  const [pinRulesAvailable, setPinRulesAvailable] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const [boostRules, setBoostRules] = useState();
  const [demoteRules, setDemoteRules] = useState();
  const [rankingRules, setRankingRules] = useState();
  const [modal, setModal] = useState(false);
  const [cloneRuleId, setCloneRuleId] = useState('');
  const toggleModal = () => setModal(!modal);

  const [contextRuleIdEdited, setContextRuleIdEdited] = useState(!id);
  const [dustbins, setDustbins] = useState([{ accepts: ['product'], lastDroppedItem: [] }]);
  const [pinnedProductsAddedFlag, setPinnedProductsAddedFlag] = useState(false);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  // const [items, setItems] = useState([]);
  const [sizeBrokenness, setSizeBrokenness] = useState({});
  const [storeFront, setStoreFront] = useState({
    categoryLevel: 'level3'
  });

  const [daysRangeDropDownOpen, setDaysRangeDropDownOpen] = useState(false);
  const [daysRange, setDaysRange] = useState(7);

  const toggle = () => setDaysRangeDropDownOpen(prevState => !prevState);

  const history = useHistory();

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  const fetchProducts = async ({ ruleGroups }) => {
    window.clearInterval(window.timer);
    window.timer = setTimeout(async () => {
      try {
        setListingError('');
        setLoading(true);
        const productResult = await getProducts({
          ruleGroups,
          boostRules,
          demoteRules,
          pinRules,
          sortState,
          rankingRules,
          rowSeqFilters,
          sizeBrokenness,
          page,
          type,
          ruleInfo,
          env: ruleState.env,
          storeId: ruleState.storeId
        });
        const pinProducts = [];
        const pinProductsAvailable = [];
        const otherProducts = [];
        const skus = pinRules.map(pin => pin.value.split(':')[1]);

        if (productResult && productResult.hits)
          productResult.hits.hits.forEach(hit => {
            if (skus.indexOf(hit._source.sku && hit._source.sku[0]) > -1) {
              pinProducts.push({ type: 'product', product: hit });
              pinProductsAvailable.push({
                value: `sku:${hit._source.sku[0]}`
              });
            } else {
              otherProducts.push(hit);
            }
          });

        if (productResult && productResult.aggregations) {
          setBrandNames(productResult.aggregations.brand_name.buckets);
        }

        setProducts(otherProducts);
        if (!pinnedProductsAddedFlag) {
          setDustbins([{ accepts: ['product'], lastDroppedItem: pinProducts }]);
          setPinnedProductsAddedFlag(true);

          setPinRulesAvailable(pinProductsAvailable);
        }
        setProductTotal(productResult.hits.total);
        setProductTime(productResult.took);

        //Update brand_name
        setLoading(false);
        console.log(updatedBy);
        const { email, timestamp } = updatedBy;
        const msg =
          type === 'collection' && timestamp + 180000 > new Date().getTime()
            ? 'Updating Collection.. it may take 1-3 minutes'
            : ``;
        window.clearInterval(window.collectionInterval);
        if (type === 'collection' && timestamp + 180000 > new Date().getTime()) {
          window.collectionInterval = setInterval(() => {
            fetchProducts({ ruleGroups });
          }, 30000);
        }
        setListingError(msg);
      } catch (e) {
        setLoading(false);
        const { email, timestamp } = updatedBy;
        const msg =
          type === 'collection' && timestamp + 180000 > new Date().getTime()
            ? 'Updating Collection.. it may take 1-3 minutes'
            : `Error: Something is wrong, please check the form`;
        setListingError(msg);
        window.clearInterval(window.collectionInterval);
        window.collectionInterval = setInterval(() => {
          fetchProducts({ ruleGroups });
        }, 30000);

        console.error(e);
      }
    }, 500);
  };

  useEffect(() => {
    fetchProducts({
      ruleGroups,
      boostRules,
      demoteRules,
      rowSeqFilters,
      sortState,
      pinRules,
      rankingRules,
      sizeBrokenness
    });
  }, [rowSeqFilters, sizeBrokenness, ruleInfo, page]);

  const refresh = () => {
    fetchProducts({
      ruleGroups,
      boostRules,
      demoteRules,
      rowSeqFilters,
      sortState,
      pinRules,
      rankingRules,
      sizeBrokenness
    });
  };

  const handleRankingUpdate = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const proMode = urlParams.get('proMode');
    if (!proMode) {
      alert('Manual Global rank update has been disabled.');
      return;
    }
    // //TODO: Run loop for all the pages
    //update ranking in elastic
    const ranks = {};
    ruleInfo.ruleType = 'global-default-rank';
    ruleInfo.idCustom = idCustom;

    await axios.get(`${singleRankUpdate}?env=${ruleState.env}&ruleId=${id}`);
    console.log('Api url ', `${singleRankUpdate}?env=${ruleState.env}&ruleId=${id}`);
    alert('Ranking update in progress. This should take appx 20-25mins.');
  };

  const handleSaveRules = async () => {
    const userDetails = getCurrentUser();

    ruleInfo.ruleType = 'global-default-rank';
    ruleInfo.idCustom = idCustom;

    // id = id ? id : "custom-rank7";
    setSaving(true);
    const data = {
      sizeBrokenness,
      rowSeqFilters,
      ruleInfo,
      updatedBy: {
        email: userDetails.email,
        timestamp: new Date().getTime()
      },
      idCustom,
      id
    };

    const result = await axios.post(endpoint, data, {
      timeout: 36000000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setSaving(false);
    showToast(
      {
        type: ACTION_SHOW_TOAST,
        content: 'Rule Saved Successfully',
        timeOut: 5000
      },
      ruleDispatch
    );
    window.location.reload();
  };

  const onPageChange = page => {
    setPage(page.selected);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchGlobalRules = async id => {
      const result = await axios.post(
        fetchEndpoint,
        { id },
        {
          timeout: 36000000,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (result && result.data && result.data.body && result.data.body.hits) {
        setGlobalRules(result.data.body.hits.hits);
      }
      // setGlobalRules(result.data.body.hits.hits);
      if (result.data && result.data.body && result.data.body._source) {
        const rowSeqFilters = result.data.body._source.rowSeqFilters;
        const sizeBrokenness = result.data.body._source.sizeBrokenness;
        const ruleInfo = result.data.body._source.ruleInfo;
        if (ruleInfo && Object.keys(ruleInfo).length) {
          setIdCustom(ruleInfo.idCustom);
          setRuleInfo(ruleInfo);
        }
        if (rowSeqFilters && Object.keys(rowSeqFilters).length) {
          setRowSeqFilters(rowSeqFilters);
        }
        if (sizeBrokenness && Object.keys(sizeBrokenness).length) {
          setSizeBrokenness(sizeBrokenness);
        }
      }
    };

    fetchGlobalRules(id);
  }, [id]);

  const handleCollectionFile = values => {
    setCollectionFile({ ...collectionInfo, ...values });
  };
  const handleSortPinnedItems = items => {
    const pRules = items.map(item => ({
      value: `sku:${item.product._source.sku[0]}`
    }));
    // setTimeout(() => {
    setPinRules([...pRules]);
    // }, 0);
    setDustbins([{ accepts: ['product'], lastDroppedItem: items }]);
  };
  const handleRuleAdd = () => {
    ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined } });
    history.replace(`/admin/categories`);
  };

  const handleBoostRuleSorting = rules => {
    onPageChange({ selected: 0 });
    setBoostRules(rules);
  };
  const handleRankingSorting = rules => {
    onPageChange({ selected: 0 });
    setRankingRules(rules);
  };

  const stringToBoolean = string => (string === 'false' ? false : !!string);

  const handleSizeBrokenness = ({ name, value }) => {
    onPageChange({ selected: 0 });
    setSizeBrokenness({ ...sizeBrokenness, [name]: value });
  };
  const handleStorefront = ({ name, value }) => {
    setStoreFront({ ...storeFront, [name]: value });
  };

  const handleAddNewRowSequencing = () => {
    const newSeqFilter = {};
    const keyArr = Object.keys(rowSeqFilters).map(v => parseInt(v));
    let maxKey = 0;
    let newIndex = 0;
    if (keyArr.length) {
      maxKey = Math.max(...keyArr);
      newIndex = maxKey + 1;
    }
    // const newIndex = Object.keys(rowSeqFilters).length;
    setRowSeqFilters({ ...rowSeqFilters, [newIndex]: newSeqFilter });
  };
  const setRowSeqFiltersWrap = (filters, key) => {
    rowSeqFilters[key] = filters;
    setRowSeqFilters({ ...rowSeqFilters });
    //TODO:
    //Handle new seg. If empty key append filters into array.
  };

  const deleteFilterRowSeq = key => {
    newID = new Date();
    let tmpObj = rowSeqFilters;
    let rowSeqFiltersMap = {};
    delete tmpObj[key];
    let tmp = Object.values(tmpObj);
    // Object.keys(tmpObj).map((k,i) => {
    //     tmp.push(tmpObj[k]);
    // });
    for (let i = 0; i < tmp.length; i++) {
      rowSeqFiltersMap[i] = tmp[i];
    }
    setRowSeqFilters(rowSeqFiltersMap);
  };

  //Clones Rule Tuple
  const cloneRuleTuple = ruleKey => {
    newID = new Date();
    let rowSeqFiltersMap = {};
    let tmp = [];
    // Object.keys(rowSeqFilters).map((key) => {
    //     tmp.push(rowSeqFilters[key]);
    //     if(Number(key) == Number(ruleKey)){
    //         tmp.push(rowSeqFilters[key]);
    //     }
    // });
    for (const key of Object.keys(rowSeqFilters)) {
      tmp.push(rowSeqFilters[key]);
      if (Number(key) == Number(ruleKey)) {
        tmp.push(rowSeqFilters[key]);
      }
    }

    for (let i = 0; i < tmp.length; i++) {
      rowSeqFiltersMap[i] = tmp[i];
    }
    setRowSeqFilters(rowSeqFiltersMap);
  };

  //handleCloneRule
  const handleCloneRule = async e => {
    const ruleId = cloneRuleId;
    console.log(ruleId, '@@@ruleIdddd');
    // setIdCustom(`${ruleInfo.idCustom}_cpy`);
    const userDetails = getCurrentUser();

    // ruleInfo.idCustom = idCustom;

    //get rule info
    let matchingRule = globalRules.filter(v => v._id == ruleId);
    matchingRule = matchingRule[0];
    const {
      _source: { rowSeqFilters, ruleInfo, sizeBrokenness }
    } = matchingRule;

    ruleInfo.ruleType = 'global-default-rank';
    ruleInfo.idCustom = idCustom || `${ruleInfo.idCustom}_cpy`;

    setSaving(true);
    const data = {
      sizeBrokenness,
      rowSeqFilters,
      ruleInfo,
      updatedBy: {
        email: userDetails.email,
        timestamp: new Date().getTime()
      },
      idCustom: `${ruleInfo.idCustom}_cpy`,
      id
    };

    await axios.post(endpoint, data, {
      timeout: 36000000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setSaving(false);
    setCollectionFile({});
    showToast(
      {
        type: ACTION_SHOW_TOAST,
        content: 'Rule Cloned Successfully',
        timeOut: 5000
      },
      ruleDispatch
    );
    window.location.reload();
  };

  //handleDeleteRule
  const handleDeleteRule = async e => {
    const ruleId = e.target.id;

    //get rule info
    let matchingRule = globalRules.filter(v => v._id == ruleId);
    matchingRule = matchingRule[0];
    const {
      _source: { rowSeqFilters, ruleInfo, updatedBy }
    } = matchingRule;
    ruleInfo.is_deleted = true;
    const data = {
      rowSeqFilters,
      ruleInfo,
      updatedBy,
      idCustom: ruleInfo.idCustom,
      id: ruleId
    };
    setSaving(true);

    const result = await axios.post(endpoint, data, {
      timeout: 36000000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setSaving(false);
    setCollectionFile({});
    showToast(
      {
        type: ACTION_SHOW_TOAST,
        content: 'Rule Deleted Successfully',
        timeOut: 5000
      },
      ruleDispatch
    );
    window.location.reload();
  };

  const LoadRules = () => {
    let ret = [];

    globalRules.map(v => {
      const idCustom = v._source.ruleInfo.idCustom;
      if (v._source.ruleInfo.is_deleted) {
        // return;
      }
      ret.push(
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0',
            borderBottom: '1px solid #e9eae3'
          }}
        >
          <div style={{ width: '20%' }}>
            <a
              style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word' }}
              href={`/admin/custom-ranking/${v._id}`}
            >
              {' '}
              {idCustom || v._id}
            </a>
          </div>
          <div style={{ width: '20%' }}>-</div>
          <div
            style={{
              width: '20%',
              fontSize: '14px',
              fontWeight: '400'
            }}
          >
            {stringToBoolean(v._source.ruleInfo.is_deleted) ? 'Inactive' : 'Active'}
          </div>
          <div
            style={{
              fontSize: '14px',
              fontWeight: '300',
              color: 'rgb(69, 112, 185)',
              width: '20%',
              padding: '0',
              cursor: 'pointer'
            }}
            id={v._id}
            color="info"
            className="btn-round"
          >
            <a style={{ color: 'rgb(69, 112, 185)' }} href={`/admin/custom-ranking/${v._id}`}>
              {' '}
              Edit
            </a>
          </div>
          <div
            style={{ width: '20%', fontSize: '14px', fontWeight: '300', color: 'red', padding: '0', cursor: 'pointer' }}
            id={v._id}
            color="info"
            className="btn-round"
            onClick={handleDeleteRule}
          >
            Disable
          </div>
          <div
            style={{ width: '20%', fontSize: '14px', fontWeight: '300', color: 'red', padding: '0', cursor: 'pointer' }}
            id={v._id}
            idCustom={v._source.ruleInfo.idCustom}
            color="info"
            className="btn-round"
            onClick={e => {
              setCloneRuleId(e.target.id);
              setIdCustom(`${e.target.getAttribute('idCustom')}_cpy`);
              toggleModal();
            }}
          >
            Clone
          </div>
        </div>
      );
    });
    return ret;
  };

  const typeName =
    type === 'collection' ? (subType === 'flashsale' && 'Flash Sale') || 'Collection' : 'Curated Category';
  try {
    if (0 && id && isEmpty(ruleState.ruleDetail)) {
      return (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col md={12} xs={12} lg={12}>
                <Card
                  style={{
                    padding: 40
                  }}
                >
                  No List.
                  <Button color="info" className="btn-round" onClick={handleRuleAdd}>
                    Create New {typeName}
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
      // const result = Array.from(list);
      // const [removed] = result.splice(startIndex, 1);
      // result.splice(endIndex, 0, removed);
      //
      // return result;
      newID = new Date();
      let startObj = rowSeqFilters[startIndex];
      let endObj = rowSeqFilters[endIndex];
      let rowSeqFiltersMap = {};
      if (startIndex == endIndex) {
        return rowSeqFilters;
      }
      for (const key of Object.keys(rowSeqFilters)) {
        // Object.keys(rowSeqFilters).map((key) => {
        rowSeqFiltersMap[key] = rowSeqFilters[key];
        if (key == startIndex) {
          rowSeqFiltersMap[key] = endObj;
        }
        if (key == endIndex) {
          rowSeqFiltersMap[key] = startObj;
        }
      }
      setRowSeqFilters(rowSeqFiltersMap);
      return rowSeqFiltersMap;
    };

    const grid = 1;

    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 2,
      margin: `${grid}px 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? 'grey' : 'white',

      // styles we need to apply on draggables
      ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? 'lightblue' : 'white',
      padding: grid
    });

    const onDragEnd = result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(result, result.source.index, result.destination.index);
      return items;
    };

    return (
      <>
        <PanelHeader size="sm" />
        {!id ? (
          <div style={{ height: 'auto', padding: '3em' }}>
            <Row>
              <Card style={{ height: '450px', 'overflow-y': 'scroll', padding: '20px 20px' }}>
                <div style={{ padding: '15px 15px 0 15px', fontSize: '16px', fontWeight: 'bold' }}>All Rules</div>

                <div
                  style={{
                    padding: '15px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '20%' }}>Name</div>
                    <div style={{ width: '20%' }}>Description</div>
                    <div style={{ width: '20%' }}>Status</div>
                    <div style={{ width: '20%' }}>Edit</div>
                    <div style={{ width: '20%' }}>Disable</div>
                    <div style={{ width: '20%' }}>Clone</div>
                  </div>
                  {LoadRules()}
                  <Modal isOpen={modal} toggle={toggleModal} unmountOnClose={false}>
                    <ModalHeader toggle={toggle}>Set new name for the Cloned Rule</ModalHeader>
                    <ModalBody>
                      <Input
                        placeholder="Rule name goes here"
                        value={idCustom}
                        onChange={e => {
                          let fixStr = e.target.value;
                          fixStr = fixStr.replace(/\W/g, '_');
                          setIdCustom(fixStr);
                        }}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={handleCloneRule}>
                        Save & Clone
                      </Button>{' '}
                      <Button
                        color="secondary"
                        onClick={() => {
                          setIdCustom('');
                          toggleModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </Card>
            </Row>
          </div>
        ) : (
          ''
        )}
        <div className="content">
          <Row>
            <Col md={5} xs={12} lg={4}>
              <Card>
                <Card>
                  <div style={{ padding: '15px', fontSize: '16px', fontWeight: 'bold' }}>
                    <p>
                      Custom Ranking Widget <span style={{ fontWeight: '500' }}>{!id && ' (Create New)'}</span>
                    </p>
                    <Input
                      onChange={e => {
                        let fixStr = e.target.value;
                        fixStr = fixStr.replace(/\W/g, '_');
                        setIdCustom(fixStr);
                      }}
                      readOnly={!ruleInfo.idCustom ? false : true}
                      name="idCustom"
                      value={idCustom}
                      placeholder="Set global rank name (Once set this value can not be changed)"
                    />
                    <div style={{ margin: '20px 0' }}>
                      {/*<span style={{fontSize: "14px", fontWeight: "400"}}>Select Default Sort</span>*/}
                      <select
                        className="form-control"
                        // style={{ height: 40, width: 30 }}
                        value={ruleInfo.defaultSort}
                        onChange={e => {
                          setRuleInfo({ ...ruleInfo, defaultSort: e.target.value });
                        }}
                        placeholder="Name"
                        type="text"
                      >
                        <option>Choose Default Sort</option>
                        <option value={'order'}>Ordered Quantity (Desc)</option>
                        <option value={'revenue'}>Revenue Desc (Desc)</option>
                      </select>
                    </div>
                    <div style={{ margin: '20px 0' }}>
                      <select
                        className="form-control"
                        value={stringToBoolean(ruleInfo.is_deleted)}
                        onChange={e => {
                          setRuleInfo({ ...ruleInfo, is_deleted: e.target.value });
                        }}
                        placeholder="Name"
                        type="text"
                      >
                        <option>Choose Default Sort</option>
                        <option value={true}>Deleted</option>
                        <option value={false}>Active</option>
                      </select>
                    </div>
                  </div>
                </Card>
                <Tabs>
                  <TabList>
                    <Tab>Row Sequencing</Tab>
                    <Tab>Fragmentation</Tab>
                  </TabList>
                  <TabPanel>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 20px',
                          alignItems: 'baseline'
                        }}
                      >
                        <p>Add combination of different rules</p>
                        <Button color="info" size="sm" className="btn-round" onClick={handleAddNewRowSequencing}>
                          +Add
                        </Button>
                        <Button color="info" size="sm" className="btn-round" onClick={refresh}>
                          Refresh
                        </Button>
                      </div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {Object.keys(rowSeqFilters).map((item, index) => {
                                // console.log(item, index, 'item@index@.map@rowseqfilters')
                                return (
                                  <Draggable key={newID + '' + item} draggableId={item} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <RowSequencingAdvanced
                                          setRowSeqFiltersWrap={setRowSeqFiltersWrap}
                                          deleteFilterRowSeq={deleteFilterRowSeq}
                                          cloneRuleTuple={cloneRuleTuple}
                                          rowSeqFilters={rowSeqFilters[item]}
                                          brandNames={brandNames}
                                          index={index}
                                          key={item + ''}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Sizebrokenness
                      showPinned={false}
                      handleSizeBrokenness={handleSizeBrokenness}
                      sizeBrokenness={sizeBrokenness}
                    />
                  </TabPanel>
                </Tabs>

                <div className="px-3 text-right">
                  <Button disabled={isSaving} color="info" className="btn-round" onClick={handleRankingUpdate}>
                    {'Update Global Ranking'}
                  </Button>
                  <Button disabled={isSaving} color="info" className="btn-round" onClick={handleSaveRules}>
                    {isSaving ? 'Saving. Please wait...' : 'Save & Publish'}
                  </Button>
                </div>
              </Card>
            </Col>
            <Col md={7} xs={12} lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div style={{ display: 'inline-block' }}>
                      <ul
                        style={{
                          listStyle: 'none',
                          padding: 0,
                          display: 'inline'
                        }}
                      >
                        <StoreSelection />
                      </ul>{' '}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {' '}
                      <span>
                        Products ({totalInfo.value} Items) <span className="fs-11">in {timeTook}ms</span>
                      </span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <DndProvider backend={Backend}>
                    {type === 'curated' ? (
                      <>
                        <div
                          className=""
                          style={{
                            position: 'sticky',
                            top: 10,
                            left: 0,
                            zIndex: 100,
                            maxHeight: 400,
                            overflowY: 'scroll',
                            background: 'rgb(238, 238, 238)',
                            boxShadow: '2px 4px 2px -2px #c9c9c9'
                          }}
                        ></div>
                        <Row className="border-bottom mb-3 mt-3 pb-3">
                          <div className="col-6"></div>
                          <div className="col-6">
                            <div
                              className=""
                              style={{ textAlign: 'right', paddingRight: 10 }}
                              onClick={() => showPinBySKU(!pinBySKU)}
                            >
                              <span className="fs-12">Pin items to the top by SKU</span>
                              <span className="caret" />
                            </div>
                            {pinBySKU ? (
                              <FormGroup>
                                <Input
                                  value={searchQuery}
                                  onChange={event => setSearchQuery(event.target.value)}
                                  defaultValue=""
                                  placeholder="ADD by SKU(newline seperated)"
                                  type="textarea"
                                />

                                <Button color="secondary" size="sm" onClick={() => {}}>
                                  Add
                                </Button>
                              </FormGroup>
                            ) : null}
                          </div>
                        </Row>
                      </>
                    ) : null}

                    <Row>
                      <Col>
                        <span className="c-pastel-red">
                          {loading ? 'Loading please wait...' : ''}
                          {listingError}
                        </span>
                      </Col>
                      <Col>
                        <ReactPaginate
                          forcePage={page}
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={Math.ceil(totalInfo.value / 60)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={onPageChange}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                          style={{ justifyContent: 'flex-end' }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9}></Col>
                      <Col style={{ textAlign: 'right' }}>
                        <Dropdown isOpen={daysRangeDropDownOpen} toggle={toggle} style={{ textAlign: 'right' }}>
                          <DropdownToggle caret>Metrics: Last {daysRange} Days</DropdownToggle>
                          <DropdownMenu style={{ textAlign: 'right' }}>
                            <DropdownItem onClick={() => setDaysRange(7)}>Last 7 Days</DropdownItem>
                            <DropdownItem onClick={() => setDaysRange(15)}>Last 15 Days</DropdownItem>
                            <DropdownItem onClick={() => setDaysRange(30)}>Last 30 Days</DropdownItem>
                            <DropdownItem onClick={() => setDaysRange(90)}>Last 90 Days</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row>
                      {products.map((product, key) => {
                        return (
                          <Col xs={4} lg={2} key={key}>
                            <Product
                              hasDrag={type === 'curated'}
                              product={product}
                              isDropped={isDropped}
                              type={type}
                              rowSeqFilters={rowSeqFilters}
                              collectionInfo={collectionInfo}
                              contextRuleId={ruleInfo.contextRuleId}
                              daysRange={daysRange}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <ReactPaginate
                      forcePage={page}
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={Math.ceil(totalInfo.value / 60)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={onPageChange}
                      containerClassName={'pagination '}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                    {/* </tbody>
                  </Table> */}
                  </DndProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    window.location.reload();
  }
};

export default CustomRanking;
