import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { getActivityLogs } from 'variables/rules';
import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import Button from 'reactstrap/lib/Button';

const renderArrayDiff = ({ title, data = [], dataPath = [] }) => {
  return (
    <>
      <b>{title}</b>
      {data.map(d => {
        const value = get(d, [1, 'value']);
        if (d[0] === '+') {
          return <p>Added {value}</p>;
        } else if (d[0] === '-') {
          return <p>Removed {value}</p>;
        }
      })}
    </>
  );
};
const renderFileDownload = ({ title, file }) => {
  return (
    <>
      <p>
        <a href={file} target="_blank">
          <b>{title}: </b>
          <i className="fa fa-download" />
          &nbsp; Download
        </a>
      </p>
    </>
  );
};
const renderOldAndNewDiff = ({ title, data = {} }) => {
  return (
    <>
      <b>{title}</b>
      {Array.isArray(data) &&
        data.map(arr => {
          return renderOldAndNewDiff({ title: '', data: arr });
        })}
      {!Array.isArray(data) &&
        Object.keys(data).map(d => {
          if (data[d]?.__old || data[d]?.__new) {
            return (
              <p>
                <b>{capitalize(d)}&nbsp;</b>
                {data[d]?.__old} changed to {data[d]?.__new}
              </p>
            );
          } else if (d.indexOf('__added') > -1 || d.indexOf('__removed') > -1) {
            if (typeof data[d] === 'object') {
              return renderOldAndNewDiff({ title: '', data: data[d]?.[0] || data[d] });
            }
            return (
              <p>
                <b>{capitalize(d)}&nbsp;</b>
                {typeof data[d] === 'string' && data[d]}
              </p>
            );
          }
        })}
    </>
  );
};
export default ({ id, onDownload, downloadLoading }) => {
  const [activitiesDisplayed, setShowActivities] = useState(false);
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    if (activitiesDisplayed) {
      getActivityLogs({ id }).then(data => {
        setActivities(data);
      });
    }
  }, [activitiesDisplayed]);
  return (
    <>
      <Button
        color="default"
        size="sm"
        outline
        onClick={() => {
          setShowActivities(!activitiesDisplayed);
        }}
      >
        {activitiesDisplayed ? 'Hide' : 'Show'} Activities
      </Button>
      <Button
        diasbled={downloadLoading}
        color="primary"
        size="sm"
        outline
        onClick={() => {
          onDownload();
        }}
      >
        {downloadLoading ? 'Downloading CSV file please wait...' : 'Download Curation as CSV'}
      </Button>
      {activitiesDisplayed ? (
        <Timeline>
          {activities.map(activity => {
            const diff = JSON.parse(activity?.diff);
            console.log('activity', activity);
            return (
              <TimelineEvent
                title={`${activity?.updatedBy}`}
                createdAt={moment(new Date(Number(activity._id))).format('ddd, Do MMM hh:mm a')}
                icon={<i className="fa fa-clock fa-2x" />}
              >
                {activity?.collectionFile &&
                  renderFileDownload({ title: 'Uploaded File', file: activity?.collectionFile })}
                {activity?.collectionReportPath &&
                  renderFileDownload({
                    title: 'Collection/Flash sale Report',
                    file: activity?.collectionReportPath
                  })}
                {diff.ruleInfo && renderOldAndNewDiff({ title: 'Basic Info Changed', data: diff.ruleInfo })}
                {diff.ruleGroups && renderOldAndNewDiff({ title: 'Filters Changed', data: diff.ruleGroups })}
                {diff.boostRules && renderOldAndNewDiff({ title: 'Boost Changed ', data: diff.boostRules })}
                {diff.rankingRules && renderOldAndNewDiff({ title: 'Rank Changed', data: diff.rankingRules })}
                {diff.sizeBrokenness &&
                  renderOldAndNewDiff({ title: 'Fragmentation Changed ', data: diff.sizeBrokenness })}

                {diff.storeFront && renderOldAndNewDiff({ title: 'Settings Changed', data: diff.storeFront })}
                {diff.pinRules &&
                  renderArrayDiff({ title: 'Pinned products', data: diff.pinRules, dataPath: [1, 'value'] })}
              </TimelineEvent>
            );
          })}
        </Timeline>
      ) : null}
    </>
  );
};
