import axios from './../../variables/axios';
import { SERVER_ENDPOINT_FEED } from 'constants/index';
import { toXML } from 'jstoxml';

export const generateFeed = async req => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${SERVER_ENDPOINT_FEED}/feed/get-data`,
        { lang_store: req.lang_store },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        console.log('Error', error);
        reject('Error', error);
      });
  });
};

export const formatAndRearrangeData = async (feedData, prefix) => {
  return new Promise(resolve => {
    let resDataList = [];
    let sm = '';
    if (prefix === 'google') {
      sm = 'g:';
    }
    for (let key in feedData) {
      let resData = {};
      let discount_percentage = '0%';
      if ('discount_percentage' in feedData[key]._source) {
        discount_percentage = feedData[key]._source.discount_percentage + '%';
      }
      resData[sm + 'id'] = feedData[key]._id;
      resData[sm + 'title'] = feedData[key]._source.name;
      resData[sm + 'link'] = feedData[key]._source.url;
      // resData[sm + 'image_link'] = feedData[key]._source.url;
      resData[sm + 'availability'] = feedData[key]._source.in_stock ? 'in_stock' : 'out_stock';
      resData[sm + 'price'] = feedData[key]._source.price.AED.default_formated;
      resData[sm + 'sale_price'] = feedData[key]._source.price.AED.default_formated;
      resData[sm + 'brand'] = feedData[key]._source.brand_name;
      resData[sm + 'color'] = feedData[key]._source.color[0];
      resData[sm + 'fabric_type'] = feedData[key]._source.fabric_1;
      resData[sm + 'gender'] = feedData[key]._source.categories.level0[0] === 'Women' ? 'Female' : 'Male';
      resData[sm + 'product_type'] = feedData[key]._source.categories.level3[0];
      resData[sm + 'description'] = 'Enjoy cash on delivery &amp; free returns when you order now!';
      resData[sm + 'identifier_exists'] = 'false';
      resData[sm + 'condition'] = 'New';
      resData[sm + 'age_group'] = 'adult';
      resData[sm + 'mobile_link'] = feedData[key]._source.url;
      resData[sm + 'custom_label_0'] = discount_percentage;
      // resData[sm + ':additional_image_link'] = feedData[key]._source.url;
      if (prefix === 'snapchat') {
        resData[sm + 'con_media_url'] = 'https://storage.googleapis.com/snapchat-pcs-test-assets/ounass_icon.png';
        resData[sm + 'ios_app_name'] = 'STYLI (For English) ستايلي (For Arabic)';
        resData[sm + 'ios_app_store_id'] = '1469688029';
        resData[sm + 'ios_url'] = feedData[key]._source.url;
        resData[sm + 'android_app_name'] = 'STYLI (For English) ستايلي (For Arabic)';
        resData[sm + 'android_package'] = 'com.stylishop.styli';
        resData[sm + 'android_url'] = feedData[key]._source.url;
      }
      resDataList.push(resData);
    }
    resolve(resDataList);
  });
};

export const genertaeXML = async (feedDataStr, prefix) => {
  return new Promise(resolve => {
    let xml = '';
    const xmlOptions = {
      header: true,
      indent: '  '
    };
    if (prefix === 'google') {
      xml = toXML(
        {
          _name: 'rss',
          _attrs: {
            'xmlns:g': 'https://base.google.com/ns/1.0',
            version: '2.0'
          },
          _content: {
            channel: [JSON.parse(feedDataStr)]
          }
        },
        xmlOptions
      );
    } else if (prefix === 'snapchat') {
      xml = toXML(
        {
          _name: 'rss',
          _attrs: {
            'xmlns:g': 'https://base.google.com/ns/1.0',
            version: '2.0'
          },
          _content: {
            channel: [JSON.parse(feedDataStr)]
          }
        },
        xmlOptions
      );
    }
    resolve(xml);
  });
};
