import React, { useEffect, useState } from 'react';
import intersection from 'lodash.intersection';
import uniq from 'lodash.uniq';
import get from 'lodash.get';
import { useStateValue } from 'context/RuleContext';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './item.css';

import { Card, Badge, Input, FormGroup, Button } from 'reactstrap';
import { isArray } from 'lodash';
import SpotlightModal from 'views/notificationModal';
import { useParams } from 'react-router-dom';
import { endpoint, endpoint_remove } from 'variables/rules';
import axios from 'variables/axios';
import { getRules } from 'variables/rules';

const getFlashSalePrice = ({ contextRuleId, product }) => {
  const { flashSale = [] } = product._source || {};
  let currentFlashSale = {};
  if (isArray(flashSale)) {
    currentFlashSale = flashSale.find(object => {
      return object['contextId'] === contextRuleId;
    });
  }
  return currentFlashSale ? (
    <div>
      <div style={{ width: 40, height: 40, position: 'absolute', top: -55 }}>
        <CircularProgressbar
          value={((currentFlashSale.f_qty_ordered || 0) / currentFlashSale.stockCap) * 100}
          text={`${currentFlashSale.f_qty_ordered || 0} / ${currentFlashSale.stockCap}`}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textColor: '#363636',
            trailColor: '#d6d6d6'
          })}
        />
      </div>
      ⚡️ {currentFlashSale.f_price_SAR} SAR
    </div>
  ) : null;
};

export default ({
  product,
  renderedFrom,
  sizeBrokenness = {},
  type,
  key,
  collectionInfo = {},
  contextRuleId,
  rowSeqFilters,
  showCheck,
  daysRange = 7,
  setIncludeList,
  setExcludeList,
  includeList,
  excludeList,
  selectAll,
  deselectAllExistingProducts
}) => {
  const flasheSalePrice = getFlashSalePrice({ contextRuleId, product });

  const [openModal, setOpenModal] = useState(false);
  const [clicks, setClicks] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [spStatus, setSpStatus] = useState(false);
  const [collection, setCollection] = useState('');
  const [stat, setStat] = useState(false);
  const [spotlightData, setSpotlight] = useState([]);
  const params = useParams();

  const toggle = () => {
    setIsActive(!isActive);
  };

  const handleDoubleClick = () => {
    if (localStorage.getItem(`${product?._id}`)) {
      localStorage.removeItem(`${product?._id}`);
      window.location.reload();
    } else {
      setOpenModal(true);
      const all = ruleState?.ruleDetail?.spotlight_property;
      const result = all.find(item => item.product_id === product?._id);
      setCollection(result.collection);
      setIsActive(result.showSimilarStatus);
      setClicks(0);
    }
  };

  const data = {
    id: params?.id,
    spotlightStatus: true,
    product_id: product?._id,
    showSimilarStatus: isActive,
    collection: collection,
    typeData: 'spotlight',
    type: params?.type
  };

  const markSpotlight = async () => {
    const result = await axios.post(endpoint, data, {
      timeout: 36000000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await getRules({ id: params.id }, ruleDispatch);
    setSpotlight(ruleState?.ruleDetail?.spotlight_property?.map(product => product.product_id));
    alert('Spotlight Saved Successfully');
    window.location.reload();
  };
  const JSX_CONTENT = (
    <div style={{ marginLeft: '9%' }}>
      <table className="tableClass">
        <tbody>
          <tr>
            <td className="tdClass">Product ID:</td>
            <td className="tdClass">{product?._id}</td>
          </tr>
          <tr>
            <td className="tdClass">Collection:</td>
            <td className="tdClass">
              <input
                placeholder="Mention the context ID"
                name="collection"
                value={collection}
                onChange={e => setCollection(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="tdClass">Show View Similar:</td>
            <td className="tdClass">
              <div className="slider-toggle-container">
                <Button
                  className={`slider-toggle-button ${isActive ? 'active' : ''}`}
                  onClick={toggle}
                  color={isActive ? 'success' : 'danger'}
                >
                  <div className="slider-toggle-thumb" />
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ display: 'flex', marginLeft: '10%' }}>
        <Button
          color="success"
          style={{ backgroundColor: '#B5D7A8', marginRight: '15px', color: 'black', width: '100px' }}
          onClick={async () => {
            setSpStatus(true);
            setOpenModal(false);
            await markSpotlight();
          }}
        >
          OK
        </Button>
        <Button
          color="primary"
          style={{ backgroundColor: '#C9DAF8', color: 'black', width: '100px' }}
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  let rank = product._source?.enrich?.[`c_${contextRuleId}`];
  let rankEnabled = false;
  if (rowSeqFilters && Object.keys(rowSeqFilters).length) {
    let maxRank = global?.baseConfig?.maxRank || 500000;
    rank = maxRank - product._score - 1;
    rankEnabled = true;
  }
  let sku = product._source.sku[0];
  let opacity = product._source.in_stock === 0 ? 0.5 : 1;
  let message = product._source.in_stock === 0 ? 'OUT OF STOCK' : '';
  const [checked, setChecked] = useState(false);

  const {
    rules: [ruleState, ruleDispatch],
    configData: [configState, configDispatch]
  } = useStateValue();
  // console.log('visibleAttributes', ruleState.visibleAttributes);
  const { visibleAttributes = [] } = ruleState;

  useEffect(() => {
    if (selectAll || product._source?.enrich?.[`c_${contextRuleId}`] || product._source?.[`c_${contextRuleId}`]) {
      setChecked(!(excludeList || []).includes(sku));
    } else {
      setChecked((includeList || []).includes(sku));
    }
    // setChecked(selectAll);
  }, [selectAll]);

  useEffect(() => {
    if (deselectAllExistingProducts) {
      setChecked(false);
    }
    // setChecked(selectAll);
  }, [deselectAllExistingProducts]);

  useEffect(() => {
    if (showCheck) {
      if (
        selectAll ||
        (!deselectAllExistingProducts &&
          (product._source?.enrich?.[`c_${contextRuleId}`] || product._source?.[`c_${contextRuleId}`]))
      ) {
        setChecked(!(excludeList || []).includes(sku));
      } else {
        setChecked((includeList || []).includes(sku));
      }
    }
    setSpotlight(ruleState?.ruleDetail?.spotlight_property?.map(product => product.product_id));
  }, []);

  if (renderedFrom === 'pinnedItem' && sizeBrokenness.enabled && sizeBrokenness.pinnedProducts) {
    console.log('sizeBrokenness', sizeBrokenness.pinnedProducts);
    try {
      let matched = true;
      let anyFieldMatched = false;
      for (let i = 1; i <= 5; i++) {
        const field = sizeBrokenness[`sizeBrokenessField${i === 1 ? '' : i}`] || 'size';
        if (product._source[field] && !anyFieldMatched) {
          anyFieldMatched = true;
        }
        anyFieldMatched = true;
        const productSizes = product._source['size_length'];
        const productSizeValue =
          product._source['size'] ||
          product._source['size_beauty'] ||
          product._source['size_kids'] ||
          product._source['size_lingerie'] ||
          product._source['size_phone_cases'] ||
          [];
        const categoryIds = product._source['categoryIds'];
        const noOfSize = sizeBrokenness?.noOfOtherSizes || configState?.data?.fallBackNoOfOtherSizes;
        const whiteListedCategoryIdsFromFragmentation =
          configState?.data?.whiteListedCategoryIdsFromFragmentation || [];
        const whiteListedSizesFromFragmentation = configState?.data?.whiteListedSizesFromFragmentation || [];
        if (
          productSizes &&
          productSizes < parseInt(noOfSize) &&
          !(
            whiteListedCategoryIdsFromFragmentation.some(element => categoryIds.includes(element)) ||
            whiteListedSizesFromFragmentation.some(element => productSizeValue.includes(element))
          )
        ) {
          message = `Fragmentation Sizes not available`;
          opacity = 0.6;
          matched = true;
        } else {
          message = '';
          opacity = 1;
          matched = false;
        }
        if (!matched) {
          message = '';
          opacity = 1;
        }
      }
      if (!message && !anyFieldMatched) {
        message = `Fragmentation Sizes not available`;
        opacity = 0.6;
        matched = true;
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (!product._source.price) {
    console.log('product._source', product._source);
  }

  const handleIncludeList = checkVal => {
    if (selectAll) {
      if (!checkVal) {
        setExcludeList(uniq([...excludeList, sku]));
        // includeList.push(sku);
        // let tmp = includeList;
        // tmp.push(sku);
        // setIncludeList(Array.from(new Set(tmp)));
      } else {
        let tmp = excludeList.filter(item => item !== sku);
        setExcludeList(tmp);
      }
    } else {
      if (checkVal) {
        if (
          deselectAllExistingProducts ||
          (!product._source?.enrich?.[`c_${contextRuleId}`] && !product._source?.[`c_${contextRuleId}`]) ||
          excludeList.includes(sku)
        ) {
          if (
            deselectAllExistingProducts ||
            (!product._source?.enrich?.[`c_${contextRuleId}`] && !product._source?.[`c_${contextRuleId}`])
          ) {
            setIncludeList(uniq([...includeList, sku]));
          }
          let tmp = excludeList.filter(item => item !== sku);
          setExcludeList(uniq([...tmp]));
        }
      } else {
        let tmp = includeList.filter(item => item !== sku);
        setIncludeList(tmp);
        if (
          deselectAllExistingProducts ||
          product._source?.enrich?.[`c_${contextRuleId}`] ||
          product._source?.[`c_${contextRuleId}`]
        ) {
          if (!includeList.includes(sku)) setExcludeList(uniq([...excludeList, sku]));
        }
      }
    }
  };
  //Handle checkbox changes.
  const handleCheck = e => {
    setChecked(e.target.checked);
    handleIncludeList(e.target.checked);
  };

  const all = {
    id: params?.id,
    product_id: product?._id
  };

  const removeSpotlight = async () => {
    if (window.confirm('Do you want to remove this product from spotlight?')) {
      const result = await axios.put(endpoint_remove, all, {
        timeout: 36000000,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result?.data?.statusCode === 200) {
        alert('Spotlight removed successfully');
        window.location.reload();
      }
    } else {
      console.log('cancelled');
    }
  };

  return (
    <>
      <div>
        <div>
          <Card
            className={`card-user item-rank-${rank}`}
            style={spotlightData?.includes(product._id) ? { border: `2px solid black`, marginBottom: '0px' } : {}}
          >
            <div
              onDoubleClick={handleDoubleClick}
              onClick={() => {
                if (showCheck) {
                  let tmp = !checked;
                  setChecked(tmp);
                  handleIncludeList(tmp);
                }
              }}
              style={{
                position: 'relative',
                opacity
              }}
              title={`Name: ${product._source.name}
Category Name: ${product._source.categories_without_path.join(' / ')}
Brand Name: ${product._source.brand_name}
Units sold - Last 7 Days: ${(product._source && product._source.last7qty) || ''}
Units sold - Last 15 Days: ${(product._source && product._source.last15qty) || ''}
Units sold - Last 30 Days: ${(product._source && product._source.last30qty) || ''}
Units sold - Last 90 Days: ${(product._source && product._source.last90qty) || ''}
Revenue - Last 7 Days: ${(product._source && product._source.last7rev) || ''}
Revenue - Last 15 Days: ${(product._source && product._source.last15rev) || ''}
Revenue - Last 30 Days: ${(product._source && product._source.last30rev) || ''}
Revenue - Last 90 Days: ${(product._source && product._source.last90rev) || ''}
Views - Last 30 Days: ${(product._source && product._source.last30views) || ''}
Conversion Rate - Last 30 Days: ${(product._source.enrich && product._source.last30cr) || ''}
Sizes: ${product._source.size || product._source.size_lingerie || ''}${product._source.size_kids || ''}(${(product._source.size_lingerie && 'Lingerie') || 'Size'
                }) 
Discount: ${product._source.discount_percentage || 0}%
Enabled At: ${product._source.enabled_at}
Color: ${product._source.color}
Fabic: ${product._source.fabric_1 || ''}
Available Qty: ${product._source.available_qty || ''}
Price: ${(product._source.price.SAR && product._source.price.SAR.default) ||
                (product._source.price.AED && product._source.price.AED.default) ||
                (product._source.price.KWD && product._source.price.KWD.default)
                }${' '} ${product._source.price.AED ? 'AED' : (product._source.price.KWD && 'KWD') || 'SAR'}
Category Unique ID: ${product._source?.uniq_category_id || ''}
Ageing: ${product._source?.vm_data?.vm_aging || 0}
Rebuys Count: ${product._source?.vm_data?.rebuys_count || 0}
Sorting Metric: ${product._source?.vm_data?.vm_metric || 0}
Lihc: ${product._source?.vm_data?.lihc || 0}
ROS: ${product._source?.vm_data?.ros_criteria || 0}
VM Metric KSA: ${product._source?.vm_metric_ksa || 0}
VM Metric UAE: ${product._source?.vm_metric_uae || 0}
Category Slot for KSA: ${product._source?.mode_of_ranking_ksa || ''}
Category Slot for UAE: ${product._source?.mode_of_ranking_uae || ''}
Sort: ${product.sort}        `}
            >
              {product._source.image_url ? (
                <img
                  alt="..."
                  src={`${product._source.image_url.indexOf('https:') > -1 ? '' : 'https:'
                    }${product._source.image_url.replace(/cache\/(.*?)\//g, '')}`}
                />
              ) : null}
              {(type === 'collection' &&
                contextRuleId &&
                product._source.enrich &&
                product._source.enrich[`c_${contextRuleId}`]) ||
                rankEnabled ? (
                <Badge
                  color="light"
                  pill
                  style={{
                    position: 'absolute',
                    zIndex: 99,
                    top: 4,
                    left: 4
                  }}
                >
                  {rank}
                </Badge>
              ) : null}

              {message ? (
                <div
                  style={{
                    position: 'absolute',
                    background: 'rgba(0,0,0,0.5)',
                    textAlign: 'center',
                    paddingTop: 30,
                    top: 0,
                    fontSize: '0.6em',
                    color: 'white'
                  }}
                >
                  {message}
                </div>
              ) : null}

              {showCheck ? (
                <div
                  style={{
                    position: 'absolute',
                    left: 25,
                    top: 0,
                    background: 'rgba(255,255, 255, 0.5)'
                  }}
                >
                  <FormGroup>
                    <Input type="checkbox" checked={checked} onChange={handleCheck} />
                  </FormGroup>
                </div>
              ) : (
                ''
              )}

              {renderedFrom !== 'pinnedItem' &&
                Array.from(
                  product._source.size || product._source.size_lingerie || product._source.size_kids || []
                ).map((size, i) => {
                  return (
                    <div
                      style={{
                        position: 'absolute',
                        top: i * 26,
                        right: 0,
                        background: 'rgba(255,255, 255, 0.5)',
                        minWidth: 20,
                        padding: 2,
                        color: '#000',
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4
                      }}
                    >
                      <span className="fs-9">{size}</span>
                    </div>
                  );
                })}
              {renderedFrom !== 'pinnedItem' ? (
                <>
                  <div
                    className=""
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      left: '0px',
                      background: '#fff',
                      fontSize: 9,
                      padding: 3,
                      borderTopRightRadius: 4
                    }}
                  >
                    {flasheSalePrice}
                    {product._source.brand_name} -{' '}
                    {(product._source.price.SAR && product._source.price.SAR.default) ||
                      (product._source.price.AED && product._source.price.AED.default) ||
                      (product._source.price.KWD && product._source.price.KWD.default)}
                    {product._source.price.AED ? 'AED' : (product._source.price.KWD && 'KWD') || 'SAR'}
                  </div>
                  <div
                    className=""
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      right: '0px',
                      background: '#fff',
                      fontSize: 9,
                      padding: 3,
                      borderTopRightRadius: 4
                    }}
                  >
                    <i className="fa fa-eye" />
                    &nbsp;
                    {product._source.enrich?.last30views || 0}
                    {/* |
              <i className="fa fa-arrow-up" />
              &nbsp;
              {product._source.enrich?.last30cr || 0} */}
                  </div>
                </>
              ) : null}
            </div>
            {renderedFrom !== 'pinnedItem' ? (
              <>
                <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                  <p style={{ fontSize: 9 }}>SKU: {product._source.sku && product._source.sku[0]} </p>
                  <p style={{ fontSize: 9 }}>
                    {visibleAttributes.map(attr => {
                      if (attr.value === 'name') {
                        return (
                          <a
                            key={attr.value}
                            className="line-clamp"
                            href={`https://stylishop.com/sa/en/product-from-curofy-preview-${product._source.sku && product._source.sku[0]
                              }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span href="#pablo">{product._source.name} </span>
                          </a>
                        );
                      }
                      const val = String(get(product._source, attr.value));
                      if (val !== 'undefined') {
                        return (
                          <em key={attr.value}>
                            {attr.label}: <b>{val}</b>
                            <br />
                          </em>
                        );
                      }
                      return null;
                    })}
                  </p>
                </div>
                <div
                  style={{
                    borderTop: '1px solid #eee',
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: '7px' }}>Last {daysRange} Days</small>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      borderRight: '1px solid #eee',
                      width: '48%',
                      textAlign: 'center'
                    }}
                  >
                    <div>{product._source && product._source[`last${daysRange}qty`]}</div>

                    <small style={{ fontSize: '7px' }}>Units Sold</small>
                  </div>
                  <div style={{ padding: 5, textAlign: 'center' }}>
                    <div>{product._source && (product._source[`last${daysRange}rev`] || 0).toLocaleString()}</div>
                    <small style={{ fontSize: '7px' }}>Revenue</small>
                  </div>
                </div>
              </>
            ) : (
              <p className=" " style={{ fontSize: 9, marginBottom: 0, textAlign: 'center' }}>
                <a
                  className="line-clamp"
                  href={`https://stylishop.com/sa/en/product-from-curofy-${product._source.sku && product._source.sku[0]
                    }`}
                  target="_blank"
                >
                  {product._source.sku && product._source.sku[0]}
                </a>
              </p>
            )}
          </Card>
        </div>
        {spotlightData?.includes(product._id) && (
          <div>
            <Button
              style={{
                fontSize: '14px',
                backgroundColor: 'white',
                color: 'black',
                width: '100%',
                border: '0.5px solid black',
                marginLeft: 0,
                marginBottom: '10px'
              }}
              onClick={() => removeSpotlight()}
            >
              Remove Spotlight
            </Button>
          </div>
        )}
      </div>
      <SpotlightModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={spotlightData?.includes(product._id) ? 'Edit Spotlight' : 'Mark Spotlight'}
        content={JSX_CONTENT}
      />
    </>
  );
};
