import React, { createContext, useContext, useReducer } from 'react';

import RuleReducer, { initialState as RuleState } from 'views/Expression/reducer';
import CollectionReducer, { initialState as CollectionState } from 'views/Collections/reducer';

import searchableattributeReducer, {
  initialState as searchableattributeState
} from 'views/SearchableAttributes/reducer';
import filterattributeReducer, { initialState as filterattributeState } from 'views/FilterListing/reducer';

import productBadgeReducer, { initialState as productBadgeState } from 'views/PromoBadge/reducer';
import configDataReducer, { initialState as configDataState } from 'views/configReducer';
import productBannerReducer, { initialState as productBannerState } from 'views/PDPBanner/reducer';
import categorySlottingReducer, { initialState as categorySlottingState } from 'views/CategorySlotting/reducer';

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        rules: useReducer(RuleReducer, RuleState),
        collections: useReducer(CollectionReducer, CollectionState),

        searchattributes: useReducer(searchableattributeReducer, searchableattributeState),
        filterattributes: useReducer(filterattributeReducer, filterattributeState),
        productbadges: useReducer(productBadgeReducer, productBadgeState),
        configData: useReducer(configDataReducer, configDataState),
        productBanner: useReducer(productBannerReducer, productBannerState),
        categorySlotting: useReducer(categorySlottingReducer, categorySlottingState)
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useStateValue = () => useContext(AppContext);
