import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    getFilterAttribute,
    addFilterAttribute,
} from "views/FilterListing/actions";
import isEmpty from "lodash.isempty";
// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
    Input,
    Row,
    Col,
    Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import { validator } from "utils/validator";
import { useStateValue } from "context/RuleContext";

export default ({
    match: {
        params: { id }
    }
}) => {
    const history = useHistory();
    const [filterAttributeForm, setFilterAttributeForm] = useState({});
    const [filterAttributeFormErrors, setFilterAttributeFormErrors] = useState(
        {}
    );
    const {
        filterattributes: [filterAttributesState, filterAttributesDispatch],
    } = useStateValue();
    useEffect(() => {
        if (id) {
            getFilterAttribute({ id }, filterAttributesDispatch);
        } else {
            setFilterAttributeForm({});
        }
    }, []);
    useEffect(() => {
        if (id && filterAttributesState.filterAttributesDetail) {
            setFilterAttributeForm(filterAttributesState.filterAttributesDetail);
        }
    }, [filterAttributesState.filterAttributesDetail]);

    const handleAddFilterAttributefield = () => {
        const {
            fieldLabel,
            fieldValue,
            fieldHint,
            order
        } = filterAttributeForm;
        const validationErrors = validator({
            fieldLabel: {
                value: fieldLabel,
                name: "Label",
                validate: ["empty"],
            },
            fieldValue: {
                value: fieldValue,
                name: "Value",
                validate: ["empty"],
            },
            fieldHint: {
                value: fieldHint,
                name: "Hint",
                validate: ["empty"],
            },
            order: {
                value: order,
                name: "Order",
                validate: [],
            }
        });

        setFilterAttributeFormErrors(validationErrors);


        if (isEmpty(validationErrors)) {
            addFilterAttribute(
                { ...filterAttributeForm, id },
                filterAttributesDispatch,
                history
            );
            setFilterAttributeFormErrors({});
        }


    };

    const handleFilterAttributeFormChange = event => {
        const inputVal = event.target.value;
        const inputName = event.target.name;
        setFilterAttributeForm({
            ...filterAttributeForm,
            [inputName]: inputVal,
        });
    };
    const currentOrder = parseInt(localStorage.getItem("order")) + 1;
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardHeader>
                                <a
                                    role="link"
                                    href="javascript:;"
                                    className="fs-11"
                                    onClick={() => history.goBack()}
                                >
                                    Go back to list
                                </a>
                                <CardTitle tag="h4">Add filter attribute </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive className="formTable">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 250 }} className="text-left">
                                                <Input
                                                    type="text"
                                                    name="fieldLabel"
                                                    value={filterAttributeForm.fieldLabel}
                                                    placeholder="Label"
                                                    onChange={(e) => handleFilterAttributeFormChange(e)}
                                                />
                                                {filterAttributeFormErrors.fieldLabel ? (
                                                    <span className="validationError">
                                                        {filterAttributeFormErrors.fieldLabel}
                                                    </span>
                                                ) : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 250 }} className="text-left">
                                                <Input
                                                    type="text"
                                                    name="fieldValue"
                                                    value={filterAttributeForm.fieldValue}
                                                    placeholder="Value"
                                                    onChange={(e) => handleFilterAttributeFormChange(e)}
                                                />
                                                {filterAttributeFormErrors.fieldValue ? (
                                                    <span className="validationError">
                                                        {filterAttributeFormErrors.fieldValue}
                                                    </span>
                                                ) : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 250 }} className="text-left">
                                                <Input
                                                    type="text"
                                                    name="fieldHint"
                                                    value={filterAttributeForm.fieldHint}
                                                    placeholder="Hint"
                                                    onChange={(e) => handleFilterAttributeFormChange(e)}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="order"
                                                    value={id ? filterAttributeForm.order : filterAttributeForm.order = currentOrder}
                                                    onChange={(e) => handleFilterAttributeFormChange(e)}
                                                />
                                                {filterAttributeFormErrors.fieldHint ? (
                                                    <span className="validationError">
                                                        {filterAttributeFormErrors.fieldHint}
                                                    </span>
                                                ) : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    className="btn-round"
                                                    onClick={handleAddFilterAttributefield}
                                                >
                                                    {id ? "Update" : "Add"}
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};
