import React from 'react';
import { useDrag } from 'react-dnd';

import Item from './Item.jsx';

export default ({
  hasDrag,
  product,
  isDropped,
  sizeBrokenness,
  selectAll,
  type,
  collectionInfo,
  rowSeqFilters,
  contextRuleId,
  showCheck = false,
  daysRange = 7,
  setIncludeList,
  includeList,
  deselectAllExistingProducts,
  setExcludeList,
  excludeList,
  key
}) => {
  let drag;
  if (hasDrag) {
    const [{ opacity }, dragValue] = useDrag({
      item: {
        name: product._source.name,
        id: product._id,
        product: product,
        type: 'product'
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    });
    drag = dragValue;
  }

  return (
    <div className="" ref={drag}>
      <Item
        product={product}
        selectAll={selectAll}
        sizeBrokenness={sizeBrokenness}
        type={type}
        setExcludeList={setExcludeList}
        excludeList={excludeList}
        rowSeqFilters={rowSeqFilters}
        deselectAllExistingProducts={deselectAllExistingProducts}
        key={key}
        setIncludeList={setIncludeList}
        includeList={includeList}
        showCheck={showCheck}
        collectionInfo={collectionInfo}
        contextRuleId={contextRuleId}
        daysRange={daysRange}
      />
    </div>
  );
};
