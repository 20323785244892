import { getLocalURL, getStores } from '../utils/index';
export const SERVER_ENDPOINT = `${getLocalURL('CUROFY_APP_SERVER_ENDPOINT')}/rules`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const FEED_SERVER_ENDPOINT = `${getLocalURL('CUROFY_APP_FEED_SERVER_ENDPOINT')}`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_RANKING = `${getLocalURL('CUROFY_APP_SERVER_ENDPOINT')}/rankUpdate`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_FEED = `${getLocalURL('CUROFY_APP_SERVER_ENDPOINT')}`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_COLLECTION = `${getLocalURL('CUROFY_APP_SERVER_ENDPOINT')}/product/collection`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_FEATURE_RANKING_LIST = `${getLocalURL(
  'CUROFY_APP_SERVER_ENDPOINT'
)}/rules/feature-ranking/list`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_FEATURE_RANKING_SET = `${getLocalURL(
  'CUROFY_APP_SERVER_ENDPOINT'
)}/rules/feature-ranking/save`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_FEATURE_RANKING_DELETE = `${getLocalURL(
  'CUROFY_APP_SERVER_ENDPOINT'
)}/rules/feature-ranking/delete`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const SERVER_ENDPOINT_API = `${getLocalURL('CUROFY_APP_SERVER_ENDPOINT')}/api`; //'//localhost:8080'; // '//vm-dev.stylishop.store';
export const AUTH_SERVICE_URL = `${getLocalURL('CUROFY_APP_AUTH_SERVICE_URL')}`;
export const optionList = {
  categoryIds: {
    value: 'categoryIds',
    label: 'Category IDs',
    hint: 'Select products'
  },
  categories_without_path: {
    value: 'categories_without_path',
    label: 'Category By Name',
    hint: 'Select products'
  },
  'price.SAR.default': { value: 'price.SAR.default', label: 'Price', hint: '' },
  discount_percentage: {
    value: 'discount_percentage',
    label: 'Discount',
    hint: 'Products having discounts'
  },
  brand_name: {
    value: 'brand_name',
    label: 'Brand Name',
    hint: 'Select brand names like Max, Splash Shoeexpress'
  },

  enabled_at: {
    value: 'enabled_at',
    label: 'Enabled At',
    hint: 'Can be used for new arrivals'
  },
  color: { value: 'color', label: 'Color', hint: 'Select Color' },
  size: {
    value: 'size',
    label: 'Size',
    hint: 'Promote Sizes: S, M, XL, etc..'
  },
  ranking: {
    value: 'ranking',
    label: 'Custom Ranking',
    hint: 'Use only ranked products'
  },
  shape_dresses: {
    value: 'shape_dresses',
    label: 'Shape Dresses',
    hint: 'shape_dresses'
  },
  product_type_coats_jackets: {
    value: 'product_type_coats_jackets',
    label: 'Product Type Coats & Jackets W',
    hint: 'product_type_coats_jackets'
  },
  fit_tshirts: {
    value: 'fit_tshirts',
    label: 'Fit T-Shirts W',
    hint: 'fit_tshirts'
  },
  top_length: {
    value: 'top_length',
    label: 'Top Length W',
    hint: 'top_length'
  },
  product_type_jeans: {
    value: 'product_type_jeans',
    label: 'Product Type Jeans W',
    hint: 'product_type_jeans'
  },
  length_skirts_dresses_lingerie: {
    value: 'length_skirts_dresses_lingerie',
    label: 'Length Skirts',
    hint: 'length_skirts_dresses_lingerie'
  },
  product_type_trousers: {
    value: 'product_type_trousers',
    label: 'Product Type Trousers W',
    hint: 'product_type_trousers'
  },
  product_type_top_women: {
    value: 'product_type_top_women',
    label: 'Product Type Top W',
    hint: 'product_type_top_women'
  },
  sleeve_length_women_topwear_dr: {
    value: 'sleeve_length_women_topwear_dr',
    label: 'Sleeve Length Top W',
    hint: 'sleeve_length_women_topwear_dr'
  },
  neck: {
    value: 'neck',
    label: 'Neck Top W',
    hint: 'neck'
  }
};

export const boostRuleList = {
  categoryIds: { label: 'Category IDs', hint: 'Boost categories' },
  categories_without_path: {
    value: 'categories_without_path',
    label: 'Category By Name',
    hint: 'Select products'
  },
  'price.SAR.default': { label: 'Price SAR', hint: 'Boost by SAR price' },
  'price.AED.default': { label: 'Price AED', hint: 'Boost by AED price' },
  'price.KWD.default': { label: 'Price KWD', hint: 'Boost by KWD price' },
  'price.QAR.default': { label: 'Price QAR', hint: 'Boost by QAR price' },
  'price.BHD.default': { label: 'Price BHD', hint: 'Boost by BHD price' },
  available_qty: { label: 'Available Qty', hint: 'Boost by Available Qty' },
  'size_qty.S': { label: 'Available Qty(S)', hint: 'Boost by Available Qty' },
  'size_qty.M': { label: 'Available Qty(M)', hint: 'Boost by Available Qty' },
  'size_qty.L': { label: 'Available Qty(L)', hint: 'Boost by Available Qty' },
  'size_qty.XL': { label: 'Available Qty(XL)', hint: 'Boost by Available Qty' },
  discount_percentage: { label: 'Discount', hint: 'Boost by discount' },
  sold_by: { label: 'Sold By', hint: 'Sold By' },
  brand_name: {
    label: 'Brand Name',
    hint: 'Boost by brand name'
  },
  last7qty: {
    label: 'Units Sold - Last 7 Days',
    hint: 'Trending products'
  },
  last15qty: {
    label: 'Units Sold - Last 15 Days',
    hint: 'Trending products'
  },
  last30qty: {
    label: 'Units Sold - Last 30 Days',
    hint: 'Trending products'
  },
  last30qtyros: {
    label: 'Units Sold - Last 30 Days ROS',
    hint: 'Trending products'
  },
  last90qty: {
    label: 'Units Sold - Last 90 Days',
    hint: 'Trending products'
  },
  last7rev: {
    label: 'Revenue - Last 7 Days',
    hint: 'Trending products'
  },
  last15rev: {
    label: 'Revenue - Last 15 Days',
    hint: 'Trending products'
  },
  last30rev: {
    label: 'Revenue - Last 30 Days',
    hint: 'Trending products'
  },
  last30revros: {
    label: 'Revenue - Last 30 Days ROS',
    hint: 'Trending products'
  },
  last90rev: {
    label: 'Revenue - Last 90 Days',
    hint: 'Trending products'
  },
  last30views: {
    label: 'Views - Last 30 Days',
    hint: 'Views products'
  },
  last30cr: {
    label: 'Conversion Rate - Last 30 Days',
    hint: 'Conversion Rate '
  },
  uniq_category_id: {
    label: 'BigQuery- UniqCategoryId',
    hint: 'Conversion Rate '
  },
  enabled_at: { label: 'Enabled At', hint: 'boost by new arrivals' },
  enabled_at_days: { label: 'Enabled At Days', hint: 'enabled at days' },
  color: { label: 'Color', hint: 'Select by color' },
  backInStore: { label: 'Back In store', hint: 'Back in store' }
  // ranking: { label: 'Custom Ranking', hint: 'Use only ranked products' }
};

export const rankingList = {
  last7qty: {
    label: 'Units Sold - Last 7 Days',
    hint: 'Trending products'
  },
  last15qty: {
    label: 'Units Sold - Last 15 Days',
    hint: 'Trending products'
  },
  last30qty: {
    label: 'Units Sold - Last 30 Days',
    hint: 'Trending products'
  },
  last30qtyros: {
    label: 'Units Sold - Last 30 Days ROS',
    hint: 'Trending products'
  },
  last90qty: {
    label: 'Units Sold - Last 90 Days',
    hint: 'Trending products'
  },

  last7rev: {
    label: 'Revenue - Last 7 Days',
    hint: 'Trending products'
  },
  last15rev: {
    label: 'Revenue - Last 15 Days',
    hint: 'Trending products'
  },
  last30rev: {
    label: 'Revenue - Last 30 Days',
    hint: 'Trending products'
  },
  last30revros: {
    label: 'Revenue - Last 30 Days ROS',
    hint: 'Trending products'
  },
  last90rev: {
    label: 'Revenue - Last 90 Days',
    hint: 'Trending products'
  },
  last30views: {
    label: 'View - Last 30 Days',
    hint: 'Views'
  },
  last30cr: {
    label: 'Conversion Rate - Last 30 Days',
    hint: 'Conversion Rate'
  },
  ranking: { label: 'Custom ranking', hint: 'Ranking uploaded in magento' }
  // size: { label: 'Size', hint: 'Boost by size' }
  // ranking: { label: 'Custom Ranking', hint: 'Use only ranked products' }
};

export const STORES = getStores();

export const locations = [
  { label: 'GCC', value: 'GCC', key: 0 },
  { label: 'INDIA', value: 'IN', key: 1 }
];
