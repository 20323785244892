import React, { useEffect, useState } from 'react';
import firebase from 'firebase';

import firebaseConfig from './firebaseConfig';
import 'firebase/auth';
import withFirebaseAuth from 'react-with-firebase-auth';

import User from 'views/UserPage';
import axios from './variables/axios';
import { AUTH_SERVICE_URL } from './constants';
import { setCurrentUser, getCurrentUser, removeFromCookie } from './utils/index';
import { toast } from 'react-toastify';
import VerifyOtp from 'views/verify-otp';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

/** See the signature above to find out the available providers */
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

/** providers can be customised as per the Firebase documentation on auth providers **/
providers.googleProvider.setCustomParameters({
  hd: 'stylishop.com'
});

/** Create the FirebaseAuth component wrapper */

const App = ({
  /** These props are provided by withFirebaseAuth HOC */
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithGoogle,
  signInWithFacebook,
  signInWithGithub,
  signInWithTwitter,
  signInAnonymously,
  signOut,
  setError,
  user,
  error,
  children
}) => {
  const [loading, setLoading] = useState(false);
  const [sso_user, setSso_user] = useState(false);
  const userEmail = localStorage.getItem('email');
  useEffect(() => {
    const loggedInUser = getCurrentUser();
    if (loggedInUser && loggedInUser?.token !== undefined) {
      setSso_user(true);
    } else {
        setSso_user(false);
    }
  }, []);

  const regenerateToken = async () => {
    const loggedInUser = getCurrentUser();
    if (!loggedInUser) {
      window.location.href = '/app/login';
    }
    const { refreshToken, token } = loggedInUser;
    try {
      const result = await axios.post(
        `${AUTH_SERVICE_URL}/regenerate-token`,
        {
          refreshToken
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      const authUser = result.data.data;
      if (result.data.status) {
        setCurrentUser(authUser);
      } else {
        removeFromCookie();
      }
    } catch (e) {
      if (400 === e.response?.status) {
        toast.error(`Token was expired.Please login again`, { position: 'top-right' });
      }
      removeFromCookie();
      setCurrentUser();
    }
  };

  useEffect(() => {
    if (sso_user) {
      regenerateToken();
      const intervalId = setInterval(() => {
        regenerateToken(); // Call the function here
      }, 2 * 60 * 1000);

      // Cleanup interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [sso_user]);

  return (
    <React.Fragment>
      {loading && (
        <span class="loader">
          <span class="loader-inner">loading...</span>
        </span>
      )}
      {sso_user ? children : loading ? null : userEmail ? <VerifyOtp setSso_user={setSso_user} /> :<User setSso_user={setSso_user}></User>}
    </React.Fragment>
  );
};

/** Wrap it */
export default App;
