import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './index.scss';

export default ({
  children,
  // type = 'error',
  timeOut = 2500,
  showBar = false
}) => {
  const [showSnackBar, setShowSnackBar] = useState(false);

  useEffect(() => {
    setShowSnackBar(showBar);
    setTimeout(() => {
      setShowSnackBar(false);
    }, timeOut);
  }, [showBar]);

  return (
    <div
      className={classnames('toast', {
        _show: showSnackBar
        // 'error-bg': type === 'error',
        // success: type === 'success'
      })}
    >
      {children}
    </div>
  );
};
