import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Button
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import { generateFeed, formatAndRearrangeData, genertaeXML } from './action';

export default () => {
  const [feedData, setFeedData] = useState({});
  const [statusFlag, setStatusFlag] = useState({
    isActive: false
  });
  function isEmpty(obj) {
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== 'object') return true;
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }

  const handleFeedGenerationClick = async () => {
    setStatusFlag({ isActive: true });
    generateFeed({ lang_store: 'magneto_auto_upload_ae_en_products' })
      .then(response => {
        setFeedData(response.data);
        setStatusFlag({ isActive: false });
      })
      .catch(error => {
        console.log('Error', error);
        setStatusFlag({ isActive: false });
      });
  };

  const handleFeedDownloadClick = async socialMedia => {
    if (!isEmpty(feedData)) {
      setStatusFlag({ isActive: true });
      let items = '[';
      formatAndRearrangeData(feedData.data, socialMedia)
        .then(formattedData => {
          for (let i = 0; i < formattedData.length; i++) {
            items += '{"item":' + JSON.stringify(formattedData[i]) + '},';
          }
          items = items.replace(/,\s*$/, '');
          items += ']';
          genertaeXML(items, socialMedia)
            .then(xml => {
              let pom = document.createElement('a');
              let filename = socialMedia + '.xml';
              let bb = new Blob([xml], { type: 'text/plain' });
              pom.setAttribute('href', window.URL.createObjectURL(bb));
              pom.setAttribute('download', filename);
              pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
              pom.draggable = true;
              pom.classList.add('dragout');
              pom.click();
              setStatusFlag({ isActive: false });
            })
            .catch(error => {
              console.log('Error', error);
              setStatusFlag({ isActive: false });
            });
        })
        .catch(error => {
          console.log('Error', error);
          setStatusFlag({ isActive: false });
        });
    } else {
      alert('Generate');
    }
  };

  return (
    <>
      <PanelHeader
        size="sm"
        // content={
        //   <Line
        //     data={dashboardPanelChart.data}
        //     options={dashboardPanelChart.options}
        //   />
        // }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <LoadingOverlay active={statusFlag.isActive} spinner>
              <Card className="card-tasks">
                <CardHeader>
                  <h5 className="card-category">Feed Generator </h5>
                  <CardTitle tag="h4" className="text-right">
                    <Button
                      color="info"
                      size="sm"
                      className="btn-round"
                      id="g_generate"
                      onClick={() => handleFeedGenerationClick()}
                    >
                      Generate &nbsp;
                      <i class="fa fa-cogs"></i>
                    </Button>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <Table>
                      <tbody>
                        <tr>
                          <td className="text-left">Google/Facebook</td>
                          <td className="td-actions text-right">
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="info"
                              id="g_generate"
                              type="button"
                              onClick={() => handleFeedDownloadClick('google')}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </Button>
                          </td>
                        </tr>
                        {/* <tr>
                        <td className="text-left">
                          Facebook
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id="g_generate"
                            type="button"
                            onClick={() => handleFeedDownloadClick('facebook')}
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </Button>
                        </td>
                      </tr> */}
                        <tr>
                          <td className="text-left">Snapchat</td>
                          <td className="td-actions text-right">
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="info"
                              id="g_generate"
                              type="button"
                              onClick={() => handleFeedDownloadClick('snapchat')}
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </LoadingOverlay>
          </Col>
        </Row>
      </div>
    </>
  );
};
