import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AUTH_SERVICE_URL, SERVER_ENDPOINT_API } from '../constants';
import { setCurrentUser, getCurrentUser } from '../utils/index';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
  Spinner // Add Spinner for loading indicator
} from 'reactstrap';

function VerifyOtp({ setSso_user }) {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [loading, setLoading] = useState(false); // State for handling loading
  const userEmail = localStorage.getItem('email');
  const domain = new URL(SERVER_ENDPOINT_API).hostname;

  useEffect(() => {
    if (!userEmail) {
      window.location.href = '/app/login';
    }
  }, []);


  useEffect(async() => {
    const user = await getCurrentUser();
    if (user && user.token!== undefined) {
      setCurrentUser(user);
      setSso_user(true);
    }
  }, []);
  const handleVerifyOtp = async () => {
    try {
      if (otp.length < 6) {
        setOtpError('OTP must be 6 digits long');
        return;
      }
      setLoading(true); // Start loader
      const verifyOtpResp = await axios.post(`${AUTH_SERVICE_URL}/verify-otp`, {
        email: userEmail,
        domain,
        otp,
      });
      if (verifyOtpResp.status === 200) {
        localStorage.removeItem('email');
        setCurrentUser(verifyOtpResp.data.data.userData);
        setSso_user(true);
      } else {
        setOtpError('OTP entered is wrong');
      }
    } catch (error) {
      console.error('Verify OTP error:', error);
      setOtpError('OTP entered is wrong');
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleBack = () => {
    localStorage.removeItem('email');
    window.location.href = '/app/login';
  };

  return (
    <div className="content">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <Card style={{ marginTop: 200 }}>
            <CardBody style={{ alignItems: 'center' }}>
              <Row className="mb-2">
                <Col className="title">
                  <span 
                    style={{ cursor: 'pointer', color: 'black', }} 
                    onClick={handleBack}
                  >
                    ← Back to Login
                  </span>
                </Col>
              </Row>
              <CardHeader>
                <h5 className="title">Verify OTP</h5>
              </CardHeader>
              <Form>
                <Row>
                  <Col>
                    {otpError && (
                      <Alert color="danger">{otpError}</Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label htmlFor="otp">Enter OTP</label>
                      <Input
                        placeholder="OTP"
                        type="text"
                        value={otp}
                        onChange={(event) => {
                          const input = event.target.value;
                          // Only allow numbers
                          if (!/^\d*$/.test(input)) {
                            return; 
                          }
                          setOtpError('');
                          setOtp(input);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    {loading ? ( // Show loader when loading is true
                      <Spinner color="primary" />
                    ) : (
                      <Button
                        color="info"
                        size="sm"
                        className="btn-round"
                        onClick={handleVerifyOtp}
                        disabled={loading} // Disable button during loading
                      >
                        Verify OTP
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" />
      </Row>
    </div>
  );
}

export default VerifyOtp;
