import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Alert,
  CustomInput
} from 'reactstrap';
import { countryOptions } from 'utils';

export default ({
  ruleId,
  onRuleInfoChange,
  ruleName = '',
  ruleNameArabic = '',
  searchKeywordEn = '',
  searchKeywordAr = '',
  flashSaleInfo = {
    active: true,
    isDisplayOutOfStockProducts: false,
    color: '#fcecbc',
    flashSalePriority: 1,
    countriesApplicable: []
  },
  mainCategory = '',
  contextRuleId = '',
  typeName,
  type,
  subType
}) => {
  let title =
    type === 'collection' ? (subType === 'flashsale' && '⚡️Flash Sale ⚡️') || 'Collections' : 'Curated Categories';
  if (subType === 'curated_search') {
    title = 'Curated Search'
  }
  if (searchKeywordAr && searchKeywordAr !== '' && Array.isArray(searchKeywordAr)) {
    searchKeywordAr = searchKeywordAr.join('،');
  }
  const [copied, setCopy] = useState(false);
  const [checkOutOfStock, setCheckOutOfStock] = useState(flashSaleInfo.isDisplayOutOfStockProducts || true);
  const [active, setActive] = useState(flashSaleInfo.active || true);
  const history = useHistory();
  const handleRuleNameChange = event => {
    onRuleInfoChange({ ruleName: event.target.value || '', name: event.target.name });
    setCopy(false);
  };
  const handlMainCategoryChange = event => {
    onRuleInfoChange({
      mainCategory: event.target.value,
      name: event.target.name
    });
  };
  const handleContextRuleIdChange = event => {
    onRuleInfoChange({ contextRuleId: event.target.value });
  };
  const [flashSaleIdOld, setFlashSaleIdStateChange] = useState(false);
  const handleFlashSaleDateChange = (momentObj, key) => {
    onRuleInfoChange({ flashSaleInfo: { ...flashSaleInfo, [key]: momentObj.toISOString() } });
  };
  const handleFlashSaleInfo = (event, key) => {
    let isChecked = event.target.checked;
    setCheckOutOfStock(isChecked);
    if (key === 'flashSaleId' && !flashSaleIdOld) {
      setFlashSaleIdStateChange(flashSaleInfo.flashSaleId);
    }
    onRuleInfoChange({
      flashSaleInfo: { ...flashSaleInfo, [key]: key === 'isDisplayOutOfStockProducts' ? isChecked : event.target.value }
    });
  };

  const handleFlashSaleColorChange = event => {
    onRuleInfoChange({ flashSaleInfo: { ...flashSaleInfo, ['color']: event.target.value } });
  };

  const handleFlashSaleActive = (event, key) => {
    setActive(event.target.checked);
    onRuleInfoChange({
      flashSaleInfo: { ...flashSaleInfo, [key]: event.target.checked }
    });
  };
  const handleFlashSalePriority = (event, key) => {
    onRuleInfoChange({
      flashSaleInfo: { ...flashSaleInfo, [key]: event.target.value }
    });
  };

  useEffect(() => {
    setCheckOutOfStock(flashSaleInfo.isDisplayOutOfStockProducts || false);
  }, [flashSaleInfo.isDisplayOutOfStockProducts]);

  useEffect(() => {
    setActive(flashSaleInfo.active);
  }, [flashSaleInfo.active]);

  const handleCountry = ({ name, value }) => {
    // console.log('handleCountry', name, value);
    onRuleInfoChange({
      flashSaleInfo: { ...flashSaleInfo, countriesApplicable: value }
    });
  };
  return (
    <>
      <div className="">
        <Card className="">
          <CardHeader tag="h4" style={{ marginTop: -10 }}>
            <a role="link" href="javascript:;" className="fs-11" onClick={() => history.goBack()}>
              Go back to list
            </a>
            <CardTitle>
              {ruleId ? 'Edit' : 'Create'} {title}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {subType === 'flashsale' ? (
              <Alert color="info">
                <b>To Start a new flash sale campaign. Please follow below steps:</b>
                <ol>
                  <li>Change the Flash Sale ID</li>
                  <li>Check "Replace Existing"</li>
                  <li>Check "Reset Sold Quantity"</li>
                  <li>Upload new CSV file</li>
                </ol>
              </Alert>
            ) : null}
            <Form className="text-center">
              <Row>
                <Col className="px-3 text-left" md="6">
                  <FormGroup>
                    {subType === 'curated_search' ? <label>Search Term English</label> : <label>Name</label>}
                    <Input
                      required
                      name="ruleName"
                      value={ruleName || ''}
                      onChange={handleRuleNameChange}
                      defaultValue=''
                      placeholder={`${typeName} Name`}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col className="px-3 text-right" md="6" lang="sa-ar" dir="rtl">
                  <FormGroup>
                    {subType === 'curated_search' ? <label>Search Term Arabic</label> : <label>Arabic Name</label>}
                    <Input
                      lang="sa-ar"
                      dir="rtl"
                      name="ruleNameArabic"
                      value={ruleNameArabic ? ruleNameArabic : ''}
                      onChange={handleRuleNameChange}
                      defaultValue={ruleNameArabic ? ruleNameArabic : ''}
                      placeholder={`${typeName} Name`}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 text-left" md="6">
                  <FormGroup>
                    {subType === 'curated_search' ? <label>Search Curation Name</label> : <label>Context Name</label>}
                    <Input
                      value={contextRuleId}
                      onChange={handleContextRuleIdChange}
                      defaultValue=""
                      placeholder={subType === 'curated_search' ? 'Curation Name' : 'Context Name for Strapi'}
                      type="text"
                    />
                    {contextRuleId ? (
                      <CopyToClipboard text={contextRuleId} onCopy={() => setCopy(true)}>
                        <span className="fs-10 c-brown-gray">
                          Context Name: <span className="c-dark-gray">{contextRuleId} </span>
                          <a href="javascript:;" style={{ color: copied ? 'green' : 'red' }}>
                            {copied ? 'Copied' : 'Copy'}
                          </a>
                        </span>
                      </CopyToClipboard>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col className="px-3 text-left" md="6">
                  {subType === 'curated_search' ? null : (
                    <FormGroup>
                      <label>Main Category</label>
                      <select
                        className="form-control"
                        name="mainCategory"
                        value={mainCategory}
                        onChange={handlMainCategoryChange}
                        defaultValue=""
                        type="text"
                      >
                        <option value="">--Select--</option>
                        <option value="Women">Women</option>
                        <option value="Men">Men</option>
                        <option value="Beauty">Beauty</option>
                        <option value="Kids">Kids</option>
                      </select>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              {typeName === 'Curated Category' || typeName === 'Collection' ? (
                <Row>
                  <Col className="px-3 text-left" md="6">
                    <FormGroup>
                      {<label>Search Keywords English</label>}
                      <Input
                        required
                        name="searchKeywordEn"
                        value={searchKeywordEn || ''}
                        onChange={handleRuleNameChange}
                        defaultValue={searchKeywordEn ? searchKeywordEn : ''}
                        placeholder="search keyword, comma separated values"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-3 text-right" md="6" lang="sa-ar" dir="rtl">
                    <FormGroup>
                      {<label>Search Keywords Arabic</label>}
                      <Input
                        lang="sa-ar"
                        dir="rtl"
                        name="searchKeywordAr"
                        value={searchKeywordAr ? searchKeywordAr : ''}
                        onChange={handleRuleNameChange}
                        defaultValue={searchKeywordAr ? searchKeywordAr : ''}
                        placeholder="search keyword, comma separated values"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
              {subType === 'flashsale' ? (
                <>
                  <Row>
                    <Col className="px-3 text-left" md="6">
                      <FormGroup>
                        <label>Flash Sale ID *</label>
                        <Input
                          name="flashSaleId"
                          value={flashSaleInfo.flashSaleId}
                          onChange={event => {
                            handleFlashSaleInfo(event, 'flashSaleId');
                          }}
                          placeholder={`Flash Sale ID`}
                          type="text"
                        />
                        {flashSaleInfo.flashSaleId && flashSaleIdOld && flashSaleIdOld !== flashSaleInfo.flashSaleId ? (
                          <div class="alert alert-info fade show mt-1" role="alert">
                            👆 Important: It is required to re-upload the entire products(CSV) if you change the Flash
                            Sale ID
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="px-3 text-left" md="6">
                      <FormGroup>
                        <label></label>
                        <CustomInput
                          type="checkbox"
                          id="isDisplayOutOfStockProducts"
                          name="isDisplayOutOfStockProducts"
                          checked={checkOutOfStock}
                          label={'Display Out Of Stock products in the list'}
                          value={checkOutOfStock}
                          // disabled="disabled"
                          onChange={event => handleFlashSaleInfo(event, 'isDisplayOutOfStockProducts')}
                        // invalid={invalidFile}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left" md="6">
                      <FormGroup>
                        <small className="c-dark-gray">Start Time</small>
                        <Datetime
                          inputProps={{
                            readOnly: true,
                            style: { color: 'var(--dark-gray)' }
                          }}
                          style={{ color: 'var(--dark-gray)' }}
                          dateFormat="ddd, Do MMM"
                          value={flashSaleInfo.start && new Date(flashSaleInfo.start)}
                          onChange={event => {
                            handleFlashSaleDateChange(event, 'start');
                          }}
                        />
                        <label>KSA Time: {moment(flashSaleInfo.start).tz('Asia/Riyadh').format('hh:mm a')}</label>
                      </FormGroup>
                    </Col>
                    <Col className="text-left" md="6">
                      <FormGroup>
                        <small className="c-dark-gray">End Time</small>
                        <Datetime
                          inputProps={{
                            readOnly: true,
                            style: { color: 'var(--dark-gray)' }
                          }}
                          dateFormat="ddd, Do MMM "
                          value={flashSaleInfo.end && new Date(flashSaleInfo.end)}
                          onChange={event => {
                            handleFlashSaleDateChange(event, 'end');
                          }}
                        />
                        <label>KSA Time: {moment(flashSaleInfo.end).tz('Asia/Riyadh').format('hh:mm a')}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left" md="6">
                      <FormGroup>
                        <small className="c-dark-gray">Background Color</small>
                        <Input
                          type="color"
                          value={flashSaleInfo.color || '#fcecbc'}
                          style={{ height: 48, width: 100 }}
                          onChange={handleFlashSaleColorChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-3 text-left" md="6">
                      <FormGroup>
                        <label></label>
                        <CustomInput
                          type="checkbox"
                          id="active"
                          name="active"
                          checked={active}
                          label={'Flash sale active'}
                          value={active}
                          onChange={event => handleFlashSaleActive(event, 'active')}
                        // invalid={invalidFile}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left" md="6">
                      <FormGroup>
                        <small className="c-dark-gray">Priority</small>
                        <Input
                          value={flashSaleInfo.flashSalePriority}
                          onChange={event => handleFlashSalePriority(event, 'flashSalePriority')}
                          defaultValue=""
                          placeholder="Flash sale priority"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-left" md="6">
                      <FormGroup>
                        <small className="c-dark-gray">Countries Applicable</small>
                        <Select
                          isMulti={true}
                          name="countriesApplicable"
                          options={countryOptions}
                          value={flashSaleInfo.countriesApplicable}
                          onChange={data => {
                            handleCountry({
                              name: 'countriesApplicable',
                              value: data
                            });
                          }}
                          defaultValue=""
                        ></Select>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
