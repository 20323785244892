import React from 'react';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default ({ openModal, setOpenModal, title, content }) => {
  return (
    <Modal isOpen={openModal} style={{}} className={`no-border`} contentClassName={'no-border'}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody style={{ marginBottom: 20 }}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            {content}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{}}>
        <Button color="info" size="sm" className="btn-round" onClick={() => setOpenModal(false)}>
          Close
        </Button>

        {/* <Button color="primary" onClick={importProducts}>
      Select &amp; Proceed
    </Button>{' '} */}
      </ModalFooter>
    </Modal>
  );
};
