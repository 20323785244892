import { SERVER_ENDPOINT_FEATURE_RANKING_DELETE } from 'constants';
import {
  SERVER_ENDPOINT_FEATURE_RANKING_LIST,
  SERVER_ENDPOINT_FEATURE_RANKING_SET
} from 'constants/index';
import axios from '../../variables/axios';

export const ACTION_TYPE_LIST_FEATURE_RANKING =
  'ACTION_TYPE_LIST_FEATURE_RANKING';
export const ACTION_TYPE_SET_FEATURE_RANKING =
  'ACTION_TYPE_SET_FEATURE_RANKING';

const endpoint = `${SERVER_ENDPOINT_FEATURE_RANKING_LIST}`;

export async function listFeatureRanking(data, dispatch) {
  try {
    const result = await axios.post(endpoint, data, {
      timeout: 36000000,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    dispatch({ type: ACTION_TYPE_LIST_FEATURE_RANKING, data: result.data });
    return result;
  } catch (e) {
    return { error: e.message };
  }
}
export async function deleteFeatureRanking(data, dispatch) {
  try {
    const result = await axios.post(
      SERVER_ENDPOINT_FEATURE_RANKING_SET,
      { ...data, isDelete: true },
      {
        timeout: 36000000,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    // dispatch({ type: ACTION_TYPE_LIST_FEATURE_RANKING, data: result.data });
    listFeatureRanking({}, dispatch);
    return result;
  } catch (e) {
    return { error: e.message };
  }
}

export async function setFeatureRanking(data, dispatch) {
  try {
    const result = await axios.post(
      SERVER_ENDPOINT_FEATURE_RANKING_SET,
      { featureRanking: data },
      {
        timeout: 36000000,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    dispatch({ type: ACTION_TYPE_SET_FEATURE_RANKING, data: result.data });
    listFeatureRanking({}, dispatch);
    return result;
  } catch (e) {
    return { error: e.message };
  }
}
