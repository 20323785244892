import React, { useState } from 'react';
import {
  Card,
  Row,
  Col,
  FormGroup,
  CardBody,
  CardTitle,
  Form,
  Input,
  InputGroup,
  Button,
  Table,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { STORES } from '../constants';

const Banner = ({ storeFront, handleStorefront }) => {
  const eachBanner = { rowName: '', startPosition: '0', repeatInterval: '0' };
  const initBanners = [{ rowName: '', startPosition: '0', repeatInterval: '0' }];
  const [allBanners, setAllBanners] = useState({ ...(storeFront.interstitialBanners || initBanners) });

  const updateBanner = (storeId, key, bannerObj) => {
    let storeBanners = allBanners[storeId] || [];
    storeBanners[key] = bannerObj;
    allBanners[storeId] = storeBanners;
    setAllBanners({ ...allBanners });

    handleStorefront({
      name: 'interstitialBanners',
      value: allBanners
    });
  };

  const deleteBanner = (storeId, key) => {
    const storeBanners = allBanners[storeId];
    if (storeBanners) {
      storeBanners.splice(key, 1);
      if (storeBanners?.length > 0) {
        allBanners[storeId] = storeBanners;
        setAllBanners({ ...allBanners });
        handleStorefront({
          name: 'interstitialBanners',
          value: allBanners
        });
      }
    }
  };

  const addBanner = storeId => {
    const storeBanners = allBanners[storeId] || [];
    storeBanners.push(eachBanner);
    allBanners[storeId] = storeBanners;
    setAllBanners({ ...allBanners });
    handleStorefront({
      name: 'interstitialBanners',
      value: allBanners
    });
  };
  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <CardTitle className="fw-6">Interstitial Banner</CardTitle>
              <label>(These banners will be shown on PLP page)</label>

              {STORES.map(store => {
                return (
                  <div>
                    <Row>
                      <Col className="px-3" md="12">
                        <FormGroup>
                          <label className="fw-6">{store.name} Banner</label>
                          <Button
                            color="info"
                            size="sm"
                            className="btn-round"
                            style={{
                              paddingLeft: '12px',
                              paddingRight: '12px'
                            }}
                            onClick={() => {
                              addBanner(store.storeId);
                            }}
                          >
                            +
                          </Button>
                        </FormGroup>

                        <Table>
                          <tbody>
                            {(allBanners[`${store.storeId}`] || initBanners).map((bannerWidget, k) => {
                              console.log(allBanners);
                              return (
                                <tr>
                                  <BannerRow
                                    deleteBanner={deleteBanner}
                                    ruleKey={'' + k}
                                    bannerWidget={bannerWidget}
                                    updateBanner={updateBanner}
                                    storeId={store.storeId}
                                  />
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const BannerRow = ({ bannerWidget, deleteBanner, ruleKey, updateBanner, storeId }) => {
  const [rowName, setRowName] = useState(bannerWidget.rowName || '');
  const [startPosition, setStartPosition] = useState(bannerWidget.startPosition || '');
  const [repeatInterval, setRepeatInterval] = useState(bannerWidget.repeatInterval || '');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputGroup>
        <Input
          name="rowName"
          value={rowName}
          onChange={e => {
            setRowName(e.target.value);
            updateBanner(storeId, ruleKey, { rowName: e.target.value, startPosition, repeatInterval });
          }}
          style={{
            maxWidth: '250px',
            height: '30px'
          }}
          defaultValue=""
          placeholder="Strapi Row Name"
          type="text"
          className="border mr-3"
        />

        <Input
          name="startPosition"
          value={startPosition}
          onChange={e => {
            setStartPosition(e.target.value);
            updateBanner(storeId, ruleKey, { rowName, startPosition: e.target.value, repeatInterval });
          }}
          style={{
            maxWidth: '50px',
            padding: '2px',
            textAlign: 'center',
            height: '30px'
          }}
          defaultValue=""
          placeholder="Start"
          type="number"
          className="border mr-3"
        />

        <Input
          name="repeatInterval"
          value={repeatInterval}
          onChange={e => {
            setRepeatInterval(e.target.value);
            updateBanner(storeId, ruleKey, { rowName, startPosition, repeatInterval: e.target.value });
          }}
          style={{
            maxWidth: '50px',
            padding: '1px',
            textAlign: 'center',
            height: '30px'
          }}
          defaultValue=""
          placeholder="Repeat"
          type="number"
          className="border"
        />
        {ruleKey > 0 ? (
          <InputGroupAddon
            style={{
              height: '30px'
            }}
            addonType="append"
            onClick={() => deleteBanner(storeId, ruleKey)}
          >
            <InputGroupText>
              <a href="javascript:;" className="c-red">
                X
              </a>
            </InputGroupText>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
    </div>
  );
};

export default Banner;
