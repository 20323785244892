import { enable } from 'debug';
import React, { useState, useEffect } from 'react';
import { Input, Card, CardBody, Form, CardTitle, Row, Col, FormGroup, InputGroup } from 'reactstrap';

export const getStores = () => {
  const cCuntry = localStorage.getItem('LOCATION');
  const STORES = [
    { id: 'ae_en', storeId: 7, name: 'UAE English', lang: 'en', country: 'UAE', url: '' },
    { id: 'kw_en', storeId: 12, name: 'KWT English', lang: 'en', country: 'KWT', url: '' },
    { id: 'qa_en', storeId: 15, name: 'QAT English', lang: 'en', country: 'QAT', url: '' },
    { id: 'bh_en', storeId: 19, name: 'BAH English', lang: 'en', country: 'BAH', url: '' }
  ];
  if (cCuntry && cCuntry === 'IN') {
    return [{ id: 'in_en', storeId: 51, name: 'IND English', lang: 'en', country: 'IND' }];
  }
  return STORES;
};

const DuplicateLinks = props => {
  const { ruleInfo, links, setLinks } = props;
  useEffect(() => {
    const defaultLinks = {};
    getStores().forEach(st => {
      defaultLinks[st.country] = {
        name: st.country,
        enabled: false,
        url: `${ruleInfo.contextRuleId ? ruleInfo.contextRuleId : ''}-${st.country?.toLowerCase()}`
      };
    });
    setLinks(defaultLinks);
  }, [ruleInfo.contextRuleId, setLinks]);

  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <CardTitle className="fw-6">Duplicate links</CardTitle>
              <label>(This will clone the rule and crate a duplicate link.)</label>
              {getStores().map(store => {
                return (
                  <div>
                    <Row>
                      <Col className="px-5" md="12">
                        <FormGroup>
                          <label className="fw-6">{store.country}</label>
                          <InputGroup>
                            <Input
                              name="Select Store"
                              style={{
                                maxWidth: '50px',
                                padding: '20px',
                                textAlign: 'center'
                              }}
                              placeholder="Select Store"
                              type="checkbox"
                              className="border mr-3"
                              onChange={event => {
                                links[store.country].enabled = event.target.checked;
                                setLinks({ ...links });
                              }}
                            />
                            <Input
                              name="Duplicate links"
                              style={{
                                maxWidth: '250px'
                              }}
                              value={links[store.country]?.url}
                              disabled={!links[store.country]?.enabled}
                              placeholder="Duplicate link"
                              type="text"
                              className="border mr-3"
                              onChange={event => {
                                links[store.country].url = event.target.value;
                                setLinks({ ...links });
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default DuplicateLinks;
