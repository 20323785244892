import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
// reactstrap components
// core components

import { getCategorySetList, setCategorySetList } from './action';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import { useStateValue } from 'context/RuleContext';
import { ACTION_SHOW_TOAST, showToast } from '../Expression/actions.js';
import CategorySetForm from './CategorySetForm';
import { cloneWith, concat, set } from 'lodash';
import { NonceProvider } from 'react-select';
import { getCurrentUser } from '../../utils/index';

let newID = new Date();

const CategorySlotting = ({
  match: {
    params: { id, type }
  }
}) => {
  const handleCategorySlotting = () => {
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    history.push(`/admin/category-slotting `);
  };
  const [isSaving, setSaving] = useState(false);

  const history = useHistory();

  const [isEdit, setisEdit] = useState(false);
  const [categorySet, setCategorySet] = useState([]);
  const [editaCategorySetData, setEditaCategorySetData] = useState({});
  const [editCatSlotIndex, setEditCatSlotIndex] = useState(-1);
  const [originalData, setOriginalData] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [edit, setEdit] = useState(false);
  const {
    categorySlotting: [categorySlottingState, categorySlottingDispatch]
  } = useStateValue();
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  useEffect(() => {
    getCategorySetList(categorySlottingDispatch);
  }, [categorySlottingDispatch]);

  const { getCategorySet = [] } = categorySlottingState;

  useEffect(() => {
    if (getCategorySet) {
      setCategorySet(getCategorySet);
    }
    if (id && getCategorySet) {
      setisEdit(true);
    }
  }, [getCategorySet]);

  const editCategorySet = (index, uuid) => {
    setEdit(true);
    if (index >= 0) {
      setEditIndex(index);
      setOriginalData(categorySet[index]);
      setEditaCategorySetData(categorySet.category_slot[index]);
      setEditCatSlotIndex(index);
      // history.push(`/admin/category-set/${uuid}`);
    }
  };

  const handleSaveNewCategorySet = async () => {
    try {
      setSaving(false);
      const userDetails = getCurrentUser();

      convertStringToIntegerCategorySlots({ editaCategorySetData });
      if (editIndex >= 0) {
        const unique = checkUniqueName({ categorySet });
        if (unique) {
          categorySet.category_slot[editIndex] = editaCategorySetData;
          setCategorySetList(
            { catSetData: categorySet, edit, editaCategorySetData, updateBy: userDetails.email },
            categorySlottingDispatch
          );
          showToast(
            {
              type: ACTION_SHOW_TOAST,
              content: 'Category Set Updated Successfully',
              timeOut: 5000
            },
            ruleDispatch
          );
        }
      } else {
        if (
          categorySet.category_slot.length &&
          editaCategorySetData.category_set &&
          editaCategorySetData.slots &&
          editaCategorySetData.mainCategory &&
          editaCategorySetData.unique_category_id.length
        ) {
          const unique = checkUniqueName({ categorySet });
          if (unique) {
            categorySet.category_slot.push(editaCategorySetData);
            setCategorySetList(
              { catSetData: categorySet, edit, editaCategorySetData, updateBy: userDetails.email },
              categorySlottingDispatch
            );
            showToast(
              {
                type: ACTION_SHOW_TOAST,
                content: 'Category Set Added Successfully',
                timeOut: 5000
              },
              ruleDispatch
            );
          }
        } else {
          showToast(
            {
              type: ACTION_SHOW_TOAST,
              content: 'All Fields are compolsory',
              timeOut: 5000
            },
            ruleDispatch
          );
        }
      }
      setEditaCategorySetData({});
      setEditIndex(-1);
      setEdit(false);
      // window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const convertStringToIntegerCategorySlots = ({ editaCategorySetData }) => {
    editaCategorySetData.slots = parseInt(editaCategorySetData.slots);
    editaCategorySetData.unique_category_id = (editaCategorySetData.unique_category_id || []).map(item => {
      return parseInt(item, 10);
    });
  };

  const checkUniqueName = ({ categorySet }) => {
    let hasMatch = false;
    let ret = true;
    let i = 0;
    for (const cat of categorySet.category_slot) {
      if (cat.category_set === editaCategorySetData.category_set && editIndex !== i) {
        hasMatch = true;
        break;
      }
      i++;
    }
    if (hasMatch) {
      showToast(
        {
          type: ACTION_SHOW_TOAST,
          content: 'Category Set Already Exist, Try a different one',
          timeOut: 5000
        },
        ruleDispatch
      );
      ret = false;
    }
    return ret;
  };

  const LoadCatSet = () => {
    let ret = [];
    const categorySetData = categorySet.category_slot || [];
    categorySetData.map((cat, index) => {
      ret.push(
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0',
            borderBottom: '1px solid #e9eae3'
          }}
        >
          <div style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word', width: '30%' }}>
            {cat.category_set}
          </div>
          <div style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word', width: '20%' }}>
            {cat.slots}
          </div>
          <div style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word', width: '20%' }}>
            {cat.unique_category_id && cat.unique_category_id.join(',')}
          </div>
          <div style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word', width: '20%' }}>
            {cat.mainCategory || ''}
          </div>

          <div
            style={{
              fontSize: '14px',
              fontWeight: '300',
              color: 'rgb(69, 112, 185)',
              width: '10%',
              padding: '0',
              cursor: 'pointer'
            }}
            color="info"
            className="btn-round"
          >
            <a style={{ color: 'rgb(69, 112, 185)' }} onClick={() => editCategorySet(index, cat.uuid)}>
              {' '}
              Edit
            </a>
          </div>
        </div>
      );
    });
    return ret;
  };

  try {
    return (
      <>
        <PanelHeader size="sm" />

        <div className="content">
          <Row>
            <Col md={5} xs={12} lg={4}>
              <Card>
                <CardHeader>
                  <a role="link" href="javascript:;" className="fs-11" onClick={() => history.goBack()}>
                    Go back to list
                  </a>
                  <CardTitle>Create Category Set</CardTitle>
                </CardHeader>
                <CardBody>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 20px',
                        alignItems: 'baseline'
                      }}
                    ></div>

                    <CategorySetForm
                      editaCategorySetData={editaCategorySetData}
                      setEditaCategorySetData={setEditaCategorySetData}
                      editIndex={editIndex}
                    />
                  </div>

                  <div className="px-3 text-right">
                    <Button disabled={isSaving} color="info" className="btn-round" onClick={handleSaveNewCategorySet}>
                      {isSaving ? 'Saving. Please wait...' : 'Save & Publish'}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={7} xs={12} lg={8}>
              {/* <Card style={{ height: '650px', 'overflow-y': 'scroll', padding: '20px 20px' }}> */}
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <CardTitle tag="h4">
                        Category Sets
                        {/* <Button color="info" size="sm" className="btn-round" onClick={handleCategoprySetAdd}>
                          Add New Cat Set
                        </Button> */}
                      </CardTitle>

                      <div
                        style={{
                          padding: '15px',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '30%' }}>Name</div>
                          <div style={{ width: '20%' }}>Slot</div>
                          <div style={{ width: '20%' }}>Unique Category IDs</div>
                          <div style={{ width: '20%' }}>Main Category</div>
                          <div style={{ width: '10%' }}>Edit</div>
                        </div>
                        {LoadCatSet()}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    console.log(e);
    // window.location.reload();
  }
};

export default CategorySlotting;
