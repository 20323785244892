import axios from 'variables/axios';
import { SERVER_ENDPOINT } from 'constants/index';

export const ACTION_SHOW_TOAST = 'ACTION_SHOW_TOAST';
export const ACTION_HIDE_TOAST = 'ACTION_HIDE_TOAST';

const endpoint = `${SERVER_ENDPOINT}/`;

export async function getRule({ ruleId }) {
  const result = await axios.post(
    endpoint,
    { ruleId },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return result.data.body.hits;
}

export const showToast = (
  { type = 'success', content = '', timeOut = 2000 },
  dispatch
) => {
  if (content) {
    dispatch({
      type: ACTION_SHOW_TOAST,
      data: { content, type, timeOut }
    });

    setTimeout(() => {
      dispatch({
        type: ACTION_HIDE_TOAST
      });
    }, timeOut);
  }
};
