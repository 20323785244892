import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'firebase/auth';
import classnames from 'classnames';
import { STORES } from '../../constants';
import Rules from './Rules';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { useEffect } from 'react';
import { copySync } from 'fs-extra';

export default ({ typeName, updatePDPBannerList, isEdit = false, editBannerData = {} }) => {
  const history = useHistory();
  const [bannerData, setBannerData] = useState({});
  const [isSameTxt, setIsSameTxt] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleBannerRowChange = ({ textKey = '', textValue = '', lang = 'en' }) => {
    let newbannerData = { ...bannerData };
    if (isSameTxt) {
      STORES.filter(store => store.lang === lang).forEach(store => {
        newbannerData[store.storeId] = textValue;
      });
    } else {
      newbannerData[textKey] = textValue;
    }
    setBannerData(newbannerData);
  };

  const hasBannerTxt = bannerData => {
    let result = false;
    STORES.forEach(store => {
      if (bannerData[store.storeId] && bannerData[store.storeId] !== '') {
        result = true;
      }
    });
    return result;
  };

  const [isSaving, setSaving] = useState(false);

  const handleSaveBanner = async () => {
    const { startDate, endDate } = bannerData;
    const currentTime = new Date().getTime();

    // if (!startDate || !!endDate) {
    //   alert('One of the date is missing.');
    //   return;
    // }
    if (startDate > endDate) {
      alert('Banner start date can not be greater than the end date.');
      return;
    }
    if (endDate && new Date(endDate).getTime() < currentTime) {
      alert('Banner Schedule date can not be set in the past.');
      return;
    }
    if (bannerData) {
      if (!bannerData.name || bannerData.name === '') {
        alert('Please enter the Name field.');
        return;
      } else if (!bannerData.basedOn) {
        alert('Please select Based On information.');
        return;
      } else if (bannerData.basedOn === 'collections' && (!bannerData.collections || bannerData.collections === '')) {
        alert('Please provide Collections.');
        return;
      } else if (bannerData.basedOn === 'rules' && (!bannerData.ruleType || bannerData.ruleType === '')) {
        alert('Please select Rule Type.');
        return;
      } else if (
        bannerData.basedOn === 'rules' &&
        bannerData.ruleType === 'best_sellers' &&
        (!bannerData.ruleNewArrivalsDaySlot || bannerData.ruleNewArrivalsDaySlot === '')
      ) {
        alert('Please choose a Day Slot.');
        return;
      } else if (bannerData.basedOn === 'rules' && (!bannerData.ruleLogical || bannerData.ruleLogical === '')) {
        alert('Please provide Rule Logical.');
        return;
      } else if (bannerData.basedOn === 'rules' && (!bannerData.ruleValue || bannerData.ruleValue <= 0)) {
        alert('Please provide Rule Value.');
        return;
      } else {
        if (hasBannerTxt(bannerData)) {
          bannerData.status = isEdit ? bannerData.status : true;
          updatePDPBannerList(bannerData);
          setSaving(true);
        } else {
          alert('Please enter Text for any one Store at least.');
          return;
        }
      }
    } else {
      alert('Something wrong!');
    }
  };

  useEffect(() => {
    if (isEdit == true) {
      if (editBannerData.startDate) {
        setStartDate(new Date(editBannerData.startDate));
      } else {
        setStartDate(undefined);
      }
      if (editBannerData.endDate) {
        setEndDate(new Date(editBannerData.endDate));
      } else {
        setEndDate();
      }

      setBannerData(editBannerData);
    } else {
      setBannerData({});
      setSaving(false);
      setEndDate();
      setStartDate();
    }
  }, [isEdit, editBannerData]);
  return (
    <>
      <div className="">
        <Card className="">
          <CardHeader tag="h4" style={{ marginTop: -10 }}>
            <CardTitle>
              {isEdit ? 'Edit' : 'Create'} {typeName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>Name</label>
                  <Input
                    name="ruleName"
                    value={bannerData.name || ''}
                    onChange={event => setBannerData({ ...bannerData, name: event.target.value })}
                    defaultValue=""
                    placeholder={`${typeName} Name`}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>
                    <Input
                      checked={isSameTxt}
                      onChange={event => setIsSameTxt(event.target.checked)}
                      // defaultValue={true}
                      type="checkbox"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    Same for other country.
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>Schedule</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', color: '#9a9a9a' }}>
                    <div style={{ width: '100%' }}>
                      <div>From </div>
                      <div style={{ display: 'flex' }}>
                        <DatePicker
                          dateFormat="dd-M-yyyy hh:mm:ss"
                          style={{ color: '#9a9a9a' }}
                          wrapperClassName="datePicker"
                          placeholderText={'Banner Start Date'}
                          selected={startDate}
                          showTimeSelect
                          onChange={date => {
                            setStartDate(date);
                            setBannerData({ ...bannerData, startDate: date.toISOString() });
                          }}
                        />
                        <Button
                          close
                          style={{ margin: '0 10px' }}
                          onClick={() => {
                            setStartDate();
                            setBannerData({ ...bannerData, startDate: undefined });
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      <div>To</div>
                      <div style={{ display: 'flex' }}>
                        <Button
                          close
                          style={{ margin: '0 10px' }}
                          onClick={() => {
                            setEndDate();
                            setBannerData({ ...bannerData, endDate: undefined });
                          }}
                        />

                        <DatePicker
                          dateFormat="dd-M-yyyy hh:mm:ss"
                          selected={endDate}
                          placeholderText={'Banner End Date'}
                          showTimeSelect
                          onChange={date => {
                            setEndDate(date);
                            setBannerData({ ...bannerData, endDate: date.toISOString() });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {STORES.map((store, index) => {
                return (
                  <Col
                    className={classnames('px-3', store.lang === 'ar' ? 'text-right' : 'text-left')}
                    md="6"
                    dir={store.lang === 'ar' ? 'rtl' : 'ltr'}
                  >
                    <FormGroup>
                      <label>{store.name}</label>
                      <Input
                        name="ruleName"
                        value={bannerData[store.storeId] || ''}
                        onChange={event =>
                          handleBannerRowChange({
                            textKey: store.storeId,
                            textValue: event.target.value,
                            lang: store.lang
                          })
                        }
                        defaultValue=""
                        placeholder={`${typeName} Row`}
                        type="text"
                        disabled={store.storeId === 1 || store.storeId === 3 || !isSameTxt ? false : true}
                      />
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col className="px-3 text-left" md="12" style={{ margin: '10px 0 0 0' }}>
                <FormGroup>
                  <label style={{ margin: '0 15px 0 0' }}>
                    <Input
                      name="collections"
                      checked={bannerData.basedOn === 'collections'}
                      onChange={event =>
                        setBannerData({
                          ...bannerData,
                          basedOn: event.target.value ? 'collections' : 'rules'
                        })
                      }
                      type="radio"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    For Collections
                  </label>
                  <label>
                    <Input
                      name="rules"
                      checked={bannerData.basedOn === 'rules'}
                      onChange={event =>
                        setBannerData({
                          ...bannerData,
                          basedOn: event.target.value ? 'rules' : 'collections'
                        })
                      }
                      type="radio"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    For Rules
                  </label>
                </FormGroup>
              </Col>
              {bannerData.basedOn === 'collections' && (
                <Col className="px-3 text-left" md="12">
                  <FormGroup>
                    <label>Collection Name</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 100,
                        padding: 15
                      }}
                      className="form-control"
                      name="sizeBrokenessField"
                      multiple={true}
                      value={bannerData.collections || ''}
                      onChange={event =>
                        setBannerData({
                          ...bannerData,
                          collections: event.target.value
                        })
                      }
                      defaultValue=""
                    ></textarea>
                  </FormGroup>
                </Col>
              )}
              {bannerData.basedOn === 'rules' && (
                <>
                  <Col className="px-3 text-left" md="12">
                    <Rules setBannerData={setBannerData} bannerData={bannerData} />
                  </Col>
                </>
              )}
            </Row>
            <div className="px-3 text-right">
              <Button disabled={isSaving} color="info" className="btn-round" onClick={handleSaveBanner}>
                {isSaving ? 'Saving. Please wait...' : 'Save & Publish'}
              </Button>
            </div>
            {/* </Form> */}
          </CardBody>
        </Card>
      </div>
    </>
  );
};
