import { ACTION_SHOW_TOAST, ACTION_HIDE_TOAST } from '../Expression/actions';

export const initialState = {
  env: window.localStorage.env,
  storeId: window.localStorage.storeId
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COLLECTION': {
      const { collections } = action.data;
      return {
        ...state,
        collectionList: collections.hits,
        collectionListTotal: collections.total
      };
    }
    case 'COLLECTION_DETAIL': {
      const { collectionDetail = {} } = action.data;
      return {
        ...state,
        collectionDetail: { rankingRules: [], ...collectionDetail }
      };
    }
    case 'SET_STORE': {
      return {
        ...state,
        storeId: action.data.storeId
      };
    }
    case 'SET_ENV': {
      return {
        ...state,
        env: action.data.env
      };
    }
    case 'STRAPI_IMAGES': {
      const { strapiImages = {}, widgets = {} } = action.data;
      return {
        ...state,
        strapiImages,
        widgets
      };
    }
    case ACTION_SHOW_TOAST: {
      const { content, type, timeOut } = action.data;
      return {
        ...state,
        showToast: true,
        toastContent: content,
        toastType: type,
        toastTimeout: timeOut
      };
    }
    case ACTION_HIDE_TOAST: {
      return {
        ...state,
        showToast: false,
        toastContent: undefined,
        toastType: undefined,
        toastTimeout: undefined
      };
    }
    default: {
      return state;
    }
  }
};
