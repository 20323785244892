import * as firebase from 'firebase/app';
import 'firebase/firestore';

import axios from 'variables/axios';
import { SERVER_ENDPOINT } from 'constants/index';

export async function getPDPBannerList(dispatch) {
  const result = await axios.get(`${SERVER_ENDPOINT}/get-pdp-banner-list`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  dispatch({
    type: 'GET_PDP_BANNER',
    data: {
      pdpBannerList: result.data.Value
    }
  });
}
export async function setPDPBannerList({ banners }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/set-pdp-banner-list`,
    { banners },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getPDPBannerList(dispatch);
}
