import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Select from 'react-select';
import { locations } from '../constants';
import axios from '../variables/axios';
import { AUTH_SERVICE_URL,SERVER_ENDPOINT_API } from '../constants';
import { setCurrentUser, getCurrentUser, removeFromCookie } from '../utils/index';

// core components

function User({ setSso_user }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrors, setLoginError] = useState('');
  const errorCodes = {
    500: 'Internal Server Error',
    400: 'Invalid Credentials (Bad Request)',
    401: 'Unauthorized',
    403: 'Forbidden'
  };
  const domain = new URL(SERVER_ENDPOINT_API).hostname;
  const handleLogin = async () => {
    try {
      const loginResp = await axios.post(`${AUTH_SERVICE_URL}/login`, { email, password,domain });
      if(loginResp.status){
        const { data : {data: { message, uuid, displayName, registered, userEmail, token, refreshToken } = {} } = {}} = loginResp;
        const verifyOtpStatus = message;
        const item = {
          uuid: uuid,
          name: displayName,
          email: userEmail || email,
          emailVerified: registered,
          token: token,
          refreshToken: refreshToken
        };
        if (verifyOtpStatus) {
          localStorage.setItem('email',email);
          window.location.href = '/admin/verify-otp';
      }else{
        setCurrentUser(loginResp.data?.data);
        setSso_user(true);
      }
    }else {
        console.log('Login error: invalid response');
        setLoginError('Login error: invalid response');
        removeFromCookie();
      }
    } catch (error) {
      console.error('login error:', error);
      setLoginError('login error: ' + errorCodes[error.message.substring(error.message.length - 3)]);
      removeFromCookie();
    }
  };

  useEffect(() => {
    const loggedInUser = getCurrentUser();
    if (loggedInUser) {
      setSso_user(true);
    } else {
      setSso_user(false);
    }
  }, []);

  const handleResetPassword = () => {
    if (!email) {
      return window.alert('Please enter the email address');
    }
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return alert('Reset password sent successfully');
      })

      .catch(error => {
        // Handle Errors here.
        return alert(error.message);
      });
  };
  const [cLocation, setCLocation] = useState('');
  useEffect(() => {
    const cLoc = localStorage.getItem('LOCATION');
    locations.forEach(location => {
      if (cLoc === location.value) {
        setCLocation(location);
      }
    });
    if (!cLoc) {
      setCLocation(locations[0]);
      localStorage.setItem('LOCATION', locations[0].value);
    }
  }, []);
  const switchLocation = target => {
    setCLocation(target);
    localStorage.setItem('LOCATION', target.value);
    if (target.value === 'IN') {
      window.localStorage.storeId = 51;
    } else {
      window.localStorage.storeId = 1;
    }
    window.location.reload();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <Card style={{ marginTop: 100 }}>
              <CardHeader>
                <h5 className="title">Sign in</h5>
              </CardHeader>
              <CardBody style={{ alignItems: 'center' }}>
                <Form>
                  <Row>
                    <Col>{loginErrors ? <Alert color="danger">{loginErrors}</Alert> : null}</Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={email}
                          onChange={event => {
                            setLoginError('');
                            setEmail(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <FormGroup>
                        <label htmlFor="password">Password</label>

                        <InputGroup>
                          <Input
                            placeholder="password"
                            type="password"
                            value={password}
                            onChange={event => {
                              setLoginError('');
                              setPassword(event.target.value);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        name="form-field-name"
                        value={cLocation}
                        onChange={switchLocation}
                        options={locations}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <Button color="info" size="sm" className="btn-round" onClick={handleLogin}>
                        Login
                      </Button>
                    </Col>
                  </Row>
                  {/* <hr />
                  <Row>
                    <Col>
                     
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" />
        </Row>
      </div>
    </>
  );
}

export default User;
