import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
// reactstrap components
// core components

import { getCategorySlottingList, setCategorySlottingList } from './action';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import { useStateValue } from 'context/RuleContext';
import { ACTION_SHOW_TOAST, showToast } from '../Expression/actions.js';
import CategorySetCriteria from '../Expression/CategorySetCriteria';
import { set } from 'lodash';
import { getCurrentUser } from '../../utils/index';

let newID = new Date();

const CategorySlotting = ({
  match: {
    params: { id, type }
  }
}) => {
  const [isSaving, setSaving] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const history = useHistory();

  const [isEdit, setisEdit] = useState(false);
  const [categorySet, setCategorySet] = useState([]);
  const [editaCategorySlottingData, setEditaCategorySlottingData] = useState({});
  const [editCatSlotIndex, setEditCatSlotIndex] = useState(-1);
  const [rankMode, setRankMode] = useState('bau_mode');
  const [rankData, setRankData] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const {
    categorySlotting: [categorySlottingState, categorySlottingDispatch]
  } = useStateValue();
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  useEffect(() => {
    getCategorySlottingList(categorySlottingDispatch);
  }, [categorySlottingDispatch]);

  const { getCategorySlotting = [] } = categorySlottingState;

  useEffect(() => {
    if (getCategorySlotting) {
      setCategorySet(getCategorySlotting);
    }
    if (id && getCategorySlotting) {
      setisEdit(true);
      setEditaCategorySlottingData(getCategorySlotting[id]);
    }
    if (id && getCategorySlotting && getCategorySlotting[id]) {
      setRankData(getCategorySlotting[id][rankMode]);
    }
  }, [getCategorySlotting]);

  const handleCategoprySetAdd = id => {
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    history.push(`/admin/category-set`);
  };

  const editCategorySet = index => {
    if (index >= 0) {
      console.log(JSON.stringify(originalData), 'edit data ++++++', JSON.stringify(editaCategorySlottingData));
      if (
        Object.keys(originalData).length &&
        JSON.stringify(originalData) !== JSON.stringify(editaCategorySlottingData)
      ) {
        console.log('edit data stay on same page');
      } else {
        console.log('edit data redirect');
      }

      history.push(`/admin/category-slotting/${index}`);
      setOriginalData(categorySet[index]);
      setEditaCategorySlottingData(categorySet[index]);
      setEditCatSlotIndex(index);
      setRankData(categorySet[index][rankMode]);
    }
  };
  console.log(JSON.stringify(originalData), 'edit data123 ++++++');

  const handleRankMode = rank => {
    setRankMode(rank);
    setRankData(getCategorySlotting[id][rank]);
  };

  const handleSaveRules = async () => {
    if (!window.confirm(`Are you sure you want to Save & Plublish`)) {
      console.log('No action performed.');
      return;
    }
    setSaving(true);
    setIsSaveClicked(true);
    setSaving(false);
    const userDetails = getCurrentUser();
    setCategorySlottingList(
      { catSlotData: editaCategorySlottingData, updateBy: userDetails?.email },
      categorySlottingDispatch
    );
    setTimeout(() => {
      showToast(
        {
          type: ACTION_SHOW_TOAST,
          content: 'Category Slotting Updating Successfully',
          timeOut: 5000
        },
        ruleDispatch
      );
      setIsSaveClicked(false);
    }, 10000);
    // window.location.reload();
  };

  const LoadRules = () => {
    let ret = [];
    Object.keys(categorySet).forEach((cat, index) => {
      ret.push(
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0',
            borderBottom: '1px solid #e9eae3'
          }}
        >
          <div class="loading-overlay" style={isSaveClicked ? { display: 'inline-block' } : { display: 'none' }}>
            <div class="loading"></div>
          </div>
          <div style={{ width: '80%' }}>
            <a
              style={{ color: '#3b6694', fontSize: '14px', fontWeight: '500', wordBreak: 'break-word' }}
              onClick={() => editCategorySet(index)}
            >
              {' '}
              {categorySet[cat]._id /* {categorySet[indVal]._id} */}
            </a>
          </div>

          <div
            style={{
              fontSize: '14px',
              fontWeight: '300',
              color: 'rgb(69, 112, 185)',
              width: '20%',
              padding: '0',
              cursor: 'pointer'
            }}
            id={'sanjib'}
            color="info"
            className="btn-round"
          >
            <a style={{ color: 'rgb(69, 112, 185)' }} onClick={() => editCategorySet(index)}>
              {' '}
              Edit
            </a>
          </div>
        </div>
      );
    });
    return ret;
  };

  try {
    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
      newID = new Date();
      let startObj = rankData[startIndex];
      let endObj = rankData[endIndex];
      let rankDataMap = [];
      if (startIndex == endIndex) {
        return rankData;
      }
      rankData.map((item, key) => {
        rankDataMap[key] = rankData[key];
        if (key == startIndex) {
          rankDataMap[key] = endObj;
        }
        if (key == endIndex) {
          rankDataMap[key] = startObj;
        }
      });
      let sortRankMode = editaCategorySlottingData;
      sortRankMode[rankMode] = rankDataMap;
      setEditaCategorySlottingData(sortRankMode);
      setRankData(rankDataMap);
      return rankDataMap;
    };

    const grid = 1;

    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 2,
      margin: `${grid}px 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? 'grey' : 'white',

      // styles we need to apply on draggables
      ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? 'lightblue' : 'white',
      padding: grid
    });

    const onDragEnd = result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const items = reorder(result, result.source.index, result.destination.index);
      return items;
    };

    return (
      <>
        <PanelHeader size="sm" />

        <div className="content">
          <Row>
            {isEdit ? (
              <Col md={5} xs={12} lg={4}>
                <Card>
                  <Card>
                    <div style={{ padding: '15px', fontSize: '16px', fontWeight: 'bold' }}>
                      <p>
                        Category Slotting of {editaCategorySlottingData && editaCategorySlottingData._id}
                        <span style={{ fontWeight: '500' }}>{!id && ' (Create New)'}</span>
                      </p>

                      <div style={{ margin: '20px 0' }}>
                        {/*<span style={{fontSize: "14px", fontWeight: "400"}}>Select Default Sort</span>*/}
                        <select
                          className="form-control"
                          // style={{ height: 40, width: 30 }}
                          value={rankMode}
                          onChange={e => {
                            handleRankMode(e.target.value);
                          }}
                          placeholder="Name"
                          type="text"
                        >
                          <option>Choose Rank Mode</option>
                          <option value={'bau_mode'}>BAU Mode</option>
                          <option value={'sale_mode'}>Sale Mode</option>
                          <option value={'fresh_mode'}>Fresh Mode</option>
                        </select>
                      </div>
                    </div>
                  </Card>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 20px',
                        alignItems: 'baseline'
                      }}
                    ></div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {rankData.map((item, index) => {
                              console.log(item, index, 'item@index@.map@rowseqfilters');
                              return (
                                <Draggable key={newID + '' + index} draggableId={'dragId' + index} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <CategorySetCriteria
                                        rankModeData={item}
                                        editaCategorySlottingData={editaCategorySlottingData}
                                        rankMode={rankMode}
                                        setEditaCategorySlottingData={setEditaCategorySlottingData}
                                        setRankData={setRankData}
                                        index={index}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {/* ):('')} */}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>

                  <div className="px-3 text-right">
                    <Button disabled={isSaving} color="info" className="btn-round" onClick={handleSaveRules}>
                      {isSaving ? 'Saving. Please wait...' : 'Save & Publish'}
                    </Button>
                  </div>
                </Card>
              </Col>
            ) : (
              ''
            )}

            <Col md={7} xs={12} lg={8}>
              {/* <Card style={{ height: '650px', 'overflow-y': 'scroll', padding: '20px 20px' }}> */}
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="12">
                      <CardTitle tag="h4">
                        Category Slotting
                        <Button color="info" size="sm" className="btn-round" onClick={handleCategoprySetAdd}>
                          Add New Cat Set
                        </Button>
                      </CardTitle>

                      <div
                        style={{
                          padding: '15px',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '80%' }}>Name</div>
                          <div style={{ width: '20%' }}>Edit</div>
                        </div>
                        {LoadRules()}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    console.log(e);
    // window.location.reload();
  }
};

export default CategorySlotting;
