export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_PDP_BANNER': {
      const { en, ar, disabledPDPBanner = [] } = action.data;
      return {
        ...state,
        badgesListEn: en,
        badgesListAr: ar,
        disabledPDPBanner
      };
    }
    case 'GET_BANNER': {
      const { PDPBanner } = action.data;
      return {
        ...state,
        PDPBanner
      };
    }
    case 'GET_PDP_BANNER': {
      const { pdpBannerList = [] } = action.data;
      return {
        ...state,
        pdpBannerList
      };
    }
    default: {
      return state;
    }
  }
};
