const subdomain = window.location.hostname;
const subdomainParts = subdomain.split('.');
const domainName = subdomainParts.slice(-2).join('.');
console.log(domainName);
export const countryOptions = [
  {
    label: 'KSA',
    value: 1
  },
  {
    label: 'UAE',
    value: 3
  },
  {
    label: 'Kuwait',
    value: 4
  },
  {
    label: 'Qatar',
    value: 5
  },
  {
    label: 'Bahrain',
    value: 7
  }
];

export const getStoreIndices = () => {
  return {
    dev: {
      1: 'magento2_dev_en_products',
      3: 'magento2_dev_ar_products',
      4: 'magento2_dev_ae_en_products',
      5: 'magento2_dev_ae_ar_products',
      7: 'magento2_dev_ae_en_products',
      11: 'magento2_dev_ae_ar_products'
    },
    qa: {
      1: 'magento2_qanew_en_products',
      3: 'magento2_qanew_ar_products',
      4: 'magento2_qanew_ae_en_products',
      5: 'magento2_qanew_ae_ar_products',
      7: 'magento2_qanew_ae_en_products',
      11: 'magento2_qanew_ae_ar_products'
    },
    qa01: {
      1: 'magento2_qa01_en_products',
      3: 'magento2_qa01_ar_products',
      4: 'magento2_qa01_ae_en_products',
      5: 'magento2_qa01_ae_ar_products',
      7: 'magento2_qa01_ae_en_products',
      11: 'magento2_qa01_ae_ar_products'
    },
    prod: {
      1: 'magento2_live_en_products',
      3: 'magento2_live_ar_products',
      4: 'magento2_live_ae_en_products',
      5: 'magento2_live_ae_ar_products',
      7: 'magento2_live_ae_en_products',
      11: 'magento2_live_ae_ar_products'
    }
  };
};

export const getLocalURL = envKey => {
  const cCuntry = localStorage.getItem('LOCATION');
  let iURL = process.env[envKey];
  if (iURL?.includes('localhost')) return iURL;
  if (iURL && cCuntry && cCuntry !== 'GCC') {
    const [protcol, path] = iURL.split('://');
    iURL = `${protcol}://${cCuntry.toLocaleLowerCase()}-${path}`;
  }
  return iURL;
};

export const getStores = () => {
  const cCuntry = localStorage.getItem('LOCATION');
  let STORES = [
    { id: 'sa_en', storeId: 1, name: 'KSA English', lang: 'en', country: 'KSA' },
    { id: 'sa_ar', storeId: 3, name: 'KSA Arabic', lang: 'ar', country: 'KSA' },
    { id: 'ae_en', storeId: 7, name: 'UAE English', lang: 'en', country: 'UAE' },
    { id: 'ae_ar', storeId: 11, name: 'UAE Arabic', lang: 'ar', country: 'UAE' },
    { id: 'kw_en', storeId: 12, name: 'KWT English', lang: 'en', country: 'KWT' },
    { id: 'kw_ar', storeId: 13, name: 'KWT Arabic', lang: 'ar', country: 'KWT' },
    { id: 'qa_en', storeId: 15, name: 'QAT English', lang: 'en', country: 'QAT' },
    { id: 'qa_ar', storeId: 17, name: 'QAT Arabic', lang: 'ar', country: 'QAT' },
    { id: 'bh_en', storeId: 19, name: 'BAH English', lang: 'en', country: 'BAH' },
    { id: 'bh_ar', storeId: 21, name: 'BAH Arabic', lang: 'ar', country: 'BAH' }
  ];
  if (cCuntry && cCuntry === 'IN') {
    return [{ id: 'in_en', storeId: 51, name: 'IND English', lang: 'en', country: 'IND' }];
  }
  return STORES;
};

export const productSTORES = () => {
  const cCuntry = localStorage.getItem('LOCATION');
  const STORES = {
    1: { storeId: 1, name: 'KSA English Store' },
    3: { storeId: 3, name: 'KSA Arabic Store' },
    7: { storeId: 7, name: 'UAE English Store' },
    11: { storeId: 11, name: 'UAE Arabic Store' },
    12: { storeId: 12, name: 'KWT English Store' },
    13: { storeId: 13, name: 'KWT Arabic Store' },
    15: { storeId: 15, name: 'QAT English Store' },
    17: { storeId: 17, name: 'QAT Arabic Store' },
    19: { storeId: 19, name: 'BAH English Store' },
    21: { storeId: 21, name: 'BAH Arabic Store' }
  };
  if (cCuntry && cCuntry === 'IN') {
    return {
      51: { storeId: 51, name: 'IND English Store' }
    };
  }
  return STORES;
};

export const removeFromCookie = () => {
  const cookieName = 'styli_sso_user';
  document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
};

const getCookie = (cookieName, domain) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    // Check if the cookie starts with the specified name
    if (cookie.indexOf(`${cookieName}=`) === 0) {
      // Check if the cookie domain matches the specified domain
      if (domain && cookie.indexOf(`domain=${domain}`) === -1) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  }
  return null;
};

const getJsonCookie = (cookieName, domain) => {
  const cookieValue = getCookie(cookieName, domain);
  if (cookieValue) {
    try {
      // Parse the JSON value from the cookie
      return JSON.parse(decodeURIComponent(cookieValue));
    } catch (error) {
      // Handle parsing errors if necessary
      console.error('Error parsing JSON from cookie:', error);
    }
  }

  return null; // Return null if the cookie or JSON value doesn't exist
};

const setJsonCookie = (cookieName, jsonObject, expirationDays, domain) => {
  const jsonString = JSON.stringify(jsonObject);

  let cookieString = `${cookieName}=${encodeURIComponent(jsonString)}; path=/;`;

  if (expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }

  if (domain) {
    cookieString += `; domain=${domain}`;
  }

  document.cookie = cookieString;
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = getJsonCookie('styli_sso_user', domainName);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = user => {
  const cookieName = 'styli_sso_user';
  try {
    if (user) {
      setJsonCookie(cookieName, user, 0, domainName);
      // localStorage.setItem('styli_sso_user', JSON.stringify(user));
    } else {
      // localStorage.removeItem('styli_sso_user');
      document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};
