import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './index.scss';
import axiosMobileInstance from 'variables/axios';
import { MULIN_SERVER_ENDPOINT } from '../../constants/index';

const backgroundColorRef = {
  2: '#aaa',
  3: '#ddd',

  4: '#eee',
  5: 'fff'
};
export default ({
  children,
  // type = 'error',
  timeOut = 2500,
  showBar = false
}) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    console.log("MULIN_SERVER_ENDPOINT",MULIN_SERVER_ENDPOINT)
    const resultCat = await axiosMobileInstance.get(
      `${MULIN_SERVER_ENDPOINT}/v1/categories/public/1`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setCategories(resultCat?.data?.response?.categories || []);
  };
  useEffect(() => {
    getCategories();
  }, []);
  const renderCell = cat => {
    return (
      <td
        style={{
          width: 250,
          verticalAlign: 'top',
          padding: 10,
          borderBottom: '1px solid #aaa',
          backgroundColor: backgroundColorRef[cat.level]
        }}
      >
        {cat.enName} - <b>{cat.id}</b>
      </td>
    );
  };
  const renderRow = categories => {
    return categories.map(cat => {
      if (cat.isActive === false) return null;
      return (
        <tr>
          {renderCell(cat)}

          {cat.subCategories?.length ? renderRow(cat.subCategories) : null}
        </tr>
      );
    });
  };

  return (
    <div
      className={classnames('tree-table', {
        // 'error-bg': type === 'error',
        // success: type === 'success'
      })}
    >
      <table>{renderRow(categories)}</table>
    </div>
  );
};
