import React, { Component, useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import map from 'lodash.map';
import './storefrontsettings.css';
import { STORES } from '../../constants';
import SortableSelectInput from 'react-sortable-select';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
// reactstrap components
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Form, Input } from 'reactstrap';

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle(props => <components.MultiValueLabel {...props} />);

const SortableSelect = SortableContainer(Select);

export default ({
  storeFront,
  handleStorefront,
  filterableAttributesList = {},
  fixedFilterableAttributesList = {}
}) => {
 
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(storeFront.fixedmixAggregations, oldIndex, newIndex);

    // setSelected(newValue);
    handleStorefront({
      name: 'fixedmixAggregations',
      value: newValue
    });
  };

  const onAddSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(storeFront.aggregations, oldIndex, newIndex);

    // setSelected(newValue);
    handleStorefront({
      name: 'aggregations',
      value: newValue
    });
  };

  const options = [];
  const coreOptions = [];
  // const optionsToExclude = ['brand_name', 'size', 'size_lingerie', 'size_phone_cases', 'size_beauty', 'color'];
  map(filterableAttributesList, (opt, value) => {
    options.push({ value, label: `${opt.en} (${value})` });
  });

  map(fixedFilterableAttributesList, (opt, value, index) => {
    coreOptions.push({ value, label: `${opt.en} (${value})`, order: index });
  });

  const handleWidgetNameChange = ({ storeId, value }) => {
    let newWidgetData = { ...storeFront.widgetData };
    newWidgetData[storeId] = value;
    handleStorefront({
      name: 'widgetData',
      value: newWidgetData
    });
  };
  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <label>Exclude From Autocomplete</label>
                    <select
                      className="form-control"
                      name="autoComp"
                      value={storeFront.exludeFromAutoComplete}
                      onChange={event => {
                        handleStorefront({
                          name: 'exludeFromAutoComplete',
                          value: event.target.value
                        });
                      }}
                      defaultValue=""
                      placeholder="Value"
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <label>Category Filter Level</label>
                    <select
                      className="form-control"
                      name="sizeBrokenessField"
                      value={storeFront.categoryLevel}
                      onChange={event => {
                        handleStorefront({
                          name: 'categoryLevel',
                          value: event.target.value
                        });
                      }}
                      defaultValue=""
                      placeholder="Value"
                    >
                      <option value="level2">LEVEL 3</option>
                      <option value="level3">LEVEL 4</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <label> Core Filters </label>

                  <SortableSelect
                    useDragHandle
                    axis="y"
                    onSortEnd={onSortEnd}
                    distance={4}
                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                    isMulti
                    options={coreOptions.map((item, index) => ({
                      value: item.value,
                      label: item.label
                    }))}
                    value={storeFront.fixedmixAggregations}
                    onChange={data => {
                      handleStorefront({
                        name: 'fixedmixAggregations',
                        value: data
                      });
                    }}
                    components={{
                      MultiValue: SortableMultiValue,
                      MultiValueLabel: SortableMultiValueLabel
                    }}
                    closeMenuOnSelect={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <label>Aditional Filters </label>
                  {/* <Select
                    isMulti={true}
                    name="aggregations"
                    options={options}
                    value={storeFront.aggregations}
                    onChange={data => {
                      handleStorefront({
                        name: 'aggregations',
                        value: data
                      });
                    }}
                    defaultValue=""
                  ></Select> */}
                  <SortableSelect
                    useDragHandle
                    axis="y"
                    onSortEnd={onAddSortEnd}
                    distance={4}
                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                    isMulti
                    options={options.map((item, index) => ({
                      value: item.value,
                      label: item.label
                    }))}
                    value={storeFront.aggregations}
                    onChange={data => {
                      handleStorefront({
                        name: 'aggregations',
                        value: data
                      });
                    }}
                    components={{
                      MultiValue: SortableMultiValue,
                      MultiValueLabel: SortableMultiValueLabel
                    }}
                    closeMenuOnSelect={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12" style={{ paddingTop: 10 }}>
                  <label>Applicable For Tag Pills </label>
                  <select
                    className="form-control"
                    name="applicable_for_tag_pills"
                    value={storeFront.applicable_for_tag_pills}
                    onChange={event => {
                      handleStorefront({
                        name: 'applicable_for_tag_pills',
                        value: event.target.value
                      });
                    }}
                    defaultValue=""
                    placeholder="Value"
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <label>Category Filter </label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'vertical',
                        maxHeight: 300,
                        minHeight: 250
                      }}
                      className="form-control"
                      name="sizeBrokenessField"
                      multiple={true}
                      value={storeFront.categoryFilter}
                      onChange={event => {
                        console.log(event.target.value);
                        handleStorefront({
                          name: 'categoryFilter',
                          value: event.target.value
                        });
                      }}
                      defaultValue=""
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <CardTitle className="fw-6">Widgets - PLP Banner</CardTitle>
              <label>(These banners will be shown on PLP page)</label>
              <Row>
                {STORES.map(store => {
                  return (
                    <Col className={classnames('px-3 text-left')} md="6">
                      <FormGroup>
                        <label>{store.name}</label>
                        <Input
                          name="ruleName"
                          value={storeFront.widgetData && storeFront.widgetData[store.storeId]}
                          onChange={event => {
                            handleWidgetNameChange({
                              storeId: store.storeId,
                              value: event.target.value
                            });
                          }}
                          defaultValue=""
                          placeholder="Widget Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
