import React, {useState} from 'react';
import {boostRuleList} from 'constants/index';


// reactstrap components
import {Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Label, Row} from 'reactstrap';

export const brandOptions = [
    {value: 'splash', label: 'Splash', color: '#000000'},
    {value: 'sunburn', label: 'SunBurn', color: '#000000'},
    {value: 'closet', label: 'Closet', color: '#000000'},
]

export const colourOptions = [
    {value: 'grey', label: 'Grey', color: '#A9A9A9', isFixed: true},
    {value: 'white', label: 'White', color: '#D3D3D3', isFixed: true},
    {value: 'black', label: 'Black', color: '#000000', isFixed: true},
    {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
    {value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: false},
    {value: 'purple', label: 'Purple', color: '#5243AA'},
    {value: 'red', label: 'Red', color: '#FF5630', isFixed: false},
    {value: 'orange', label: 'Orange', color: '#FF8B00'},
    {value: 'yellow', label: 'Yellow', color: '#FFC400'},
    {value: 'green', label: 'Green', color: '#36B37E'},
    {value: 'forest', label: 'Forest', color: '#00875A'},
    {value: 'slate', label: 'Slate', color: '#253858'},
    {value: 'silver', label: 'Silver', color: '#666666'},
];


const SequenceRow = ({rowSeqFilters, brandNames, deleteRuleFilter, ruleKey, updateRuleFilter}) => {
    const [operator, setOperator] = useState(rowSeqFilters.operator || "is");
    const [filterField, setFilterField] = useState(rowSeqFilters.filterField || "");
    const [filterValues, setFilterValues] = useState(rowSeqFilters.filterValues || "");

    const handleChange = (e) => {
        setFilterField(e.target.value)
        setFilterValues("")
        setOperator("is")
        updateRuleFilter(ruleKey, {operator, filterField: e.target.value, filterValues});
        console.log(e.target.name, e.target.value, "@handleChange", filterValues)
    }
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <InputGroup style={{"display": "flex", marginBottom: "0px"}}>
                <select
                    className="form-control"
                    onChange={handleChange}
                    name={"filterField"}
                    value={filterField}
                    id="filterField"
                    style={{
                        padding: '10px',
                        width: "100%",
                        margin: "10px 0 ",
                        border: "1px solid #e3e3e3"
                    }}
                    defaultValue=""
                    placeholder="Attribute"
                    type="text"
                >
                    <option>Choose</option>
                    {Object.keys(boostRuleList).map((val) => {
                        return <option value={val}>{boostRuleList[val].label}</option>
                    })}
                    <option value="size">Size</option>
                    <option value="size_lingerie">Size Lingerie</option>
                    <option value="size_phone_cases">
                        Size Phone Cases
                    </option>
                    <option value="size_beauty">Size Beauty</option>
                    <option value="uniq_category_id">Unique Category Id</option>
                    <option value="collection">Collection</option>
                    <option value="enabled_at_days">Enabled At (No of Days)</option>
                    <option value="backInStore">Back in Store</option>
                </select>
            </InputGroup>
            <InputGroup style={{width: "40%", marginBottom: "0px"}}>
                <select
                    value={operator}
                    onChange={e => {
                        setOperator(e.target.value)
                        updateRuleFilter(ruleKey, {operator: e.target.value, filterField, filterValues});
                    }}
                    className="form-control"
                    style={{height: 40, border: "1px solid rgb(227, 227, 227)"}}
                >
                    <option value="is">is</option>
                    <option value="lte">&le;</option>
                    <option value="gte">&ge;</option>
                    {/*<option value="between">between</option>*/}
                    {/*<option value="contains">contains</option>*/}
                </select>
            </InputGroup>
            <InputGroup style={{width: "100%", marginBottom: "0px"}}>
                <Input
                    value={filterValues}
                    style={{height: 40, border: "1px solid rgb(227, 227, 227)"}}
                    onChange={event => {
                        setFilterValues(event.target.value);
                        updateRuleFilter(ruleKey, {operator, filterField, filterValues: event.target.value});
                    }}
                    defaultValue=""
                    placeholder="Value"
                    type="text"
                />
            </InputGroup>
            <Button
                style={{
                    padding: "0 15px",
                    height: "40px",
                    background: "white",
                    color: "#7b7878",
                    fontWeight: "bold",
                    border: "1px solid rgb(227, 227, 227)"
                }}
                className="btn-round"
                onClick={() => {
                    deleteRuleFilter(ruleKey)
                }}>
                x
            </Button>
        </div>
    )
}

const blackRule = {filterField: "", operator: "is", filterValues: ""};
const initFilters = [{filterField: "", operator: "is", filterValues: ""}];
export default ({rowSeqFilters, setRowSeqFiltersWrap, brandNames, cloneRuleTuple, index, deleteFilterRowSeq}) => {

    const [ruleFilters, setRuleFilters] = useState(rowSeqFilters.ruleFilters || initFilters);
    const [perRow, setPerRow] = useState(rowSeqFilters.perRow || 1);
    // console.log(ruleFilters, '@@ruleFilters@@')
    const deleteRuleFilter = (key) => {
        console.log("keykeykey@deleteRule", key)
        const tmpRuleFilters = [...ruleFilters];
        // tmpRuleFilters.splice(parseInt(key), 1);
        delete tmpRuleFilters[parseInt(key)];
        setRuleFilters([...tmpRuleFilters])
        setRowSeqFiltersWrap({
            ...rowSeqFilters,
            ruleFilters: tmpRuleFilters.filter(v => v),
            perRow: perRow
        }, index)
    }
    const updateRuleFilter = (key, ruleFilter) => {
        console.log(ruleFilter, '@@@ruleFilter@@@', key)
        const tmpRuleFilters = [...ruleFilters];
        tmpRuleFilters[key] = ruleFilter;
        setRuleFilters([...tmpRuleFilters])
        setRowSeqFiltersWrap({
            ruleFilters: tmpRuleFilters.filter(v => v),
            perRow: perRow
        }, index)
    }
    return (
        <>
            <div className="">
                <Card className="">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display:"flex", justifyContent: "left"}}>
                        <Button
                            color="info"
                            size="sm"
                            className="btn-round"
                            style={{ borderRadius: "50px",
                                width: "28px",
                                padding: "0",
                                height: "28px",
                                background: "white",
                                border: "1px solid #afaaaa",
                                color: "#292929"}}
                        >
                            {index + 1}
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            className="btn-round"
                            onClick={() => deleteFilterRowSeq(index)}
                        >
                            -Remove
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            className="btn-round"
                            onClick={() => cloneRuleTuple(index)}
                        >
                            Clone
                        </Button>
                            </div>
                        <Button
                            color="info"
                            size="sm"
                            className="btn-round"
                            onClick={() => {
                                setRuleFilters([...ruleFilters, {...blackRule}])
                            }}
                        >
                            +
                        </Button>
                    </div>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col className="px-3" md="12">
                                    <FormGroup>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "baseline"
                                        }}>
                                            <label>Select Filter Key and values</label>

                                        </div>
                                        {
                                            ruleFilters.map((rowSeqFilters, k) => {
                                                if(!rowSeqFilters){
                                                    return ""
                                                }
                                                return <SequenceRow deleteRuleFilter={deleteRuleFilter}
                                                                    ruleKey={'' + k}
                                                                    key={'' + k}
                                                                    rowSeqFilters={rowSeqFilters}
                                                                    updateRuleFilter={updateRuleFilter}
                                                                    brandNames={brandNames}/>
                                            })
                                        }
                                        <FormGroup>
                                            <Label for="exampleSelect">Switch tags every</Label>
                                            <Input
                                                type="select"
                                                name="perRow"
                                                id="perRow"
                                                onChange={(e) => {
                                                    setPerRow(e.target.value);
                                                    setRowSeqFiltersWrap({
                                                        ruleFilters,
                                                        perRow: e.target.value
                                                    }, index)
                                                }}>
                                                {[...Array(60).keys()].map((k) => <option
                                                    selected={++k == perRow}>{k}</option>)}}
                                            </Input>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <span className="fs-10 c-brown-gray" style={{float: 'right'}}>
                                                    Note: Pro Feature
                            </span>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
};
