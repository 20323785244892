import React, { useState } from 'react';
// reactstrap components
import {
  Card,
  CardBody,
  Label,
  Button,
  FormGroup,
  Form,
  Input,
  Modal
} from 'reactstrap';
import TreeTable from 'components/TreeTable/index.jsx';
import RuleGroup from './RuleGroup';

export default ({
  ruleGroups,

  setIncludeCollection,

  onRuleGroupChange,

  importCount
}) => {
  const [checked, setChecked] = useState(true);
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);

  const handleNewRuleGroup = (type = 'must') => {
    onRuleGroupChange([
      ...ruleGroups,
      [
        {
          logical: 'contains',
          id: new Date().getTime(),
          type: type === 'must_not' ? 'must_not' : 'must'
        }
      ]
    ]);
  };

  const handleRuleGroupChange = ({ ruleGroup, ruleGroupIndex }) => {
    const newRuleGroups = [...ruleGroups];

    if (ruleGroup.length) {
      newRuleGroups[ruleGroupIndex] = ruleGroup;
    } else {
      newRuleGroups.splice(ruleGroupIndex, 1);
    }
    onRuleGroupChange([...newRuleGroups]);
  };

  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <Form className="text-center">
              {ruleGroups?.map((data, index) => (
                <RuleGroup
                  key={data[0] && data[0].id}
                  ruleGroupIndex={index}
                  data={data}
                  handleRuleGroupChange={handleRuleGroupChange}
                />
              ))}

              <Button
                size="sm btn-outline-default c-sap-green"
                onClick={() => {
                  handleNewRuleGroup('must');
                }}
              >
                MUST
              </Button>
              <Button
                size="sm btn-outline-default c-pastel-red"
                onClick={() => {
                  handleNewRuleGroup('must_not');
                }}
              >
                MUST NOT
              </Button>
              {setIncludeCollection && importCount ? (
                <div style={{ display: 'flex', padding: '5px 20px' }}>
                  <FormGroup>
                    <Input
                      id="setIncludeCollection"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.target.checked);
                        setIncludeCollection(e.target.checked);
                      }}
                    />
                    <Label for="setIncludeCollection">
                      Include {importCount} Product(s) from collection?
                    </Label>
                  </FormGroup>
                </div>
              ) : (
                ''
              )}
            </Form>

            <div className="text-right">
              <a
                href="javascript:;"
                style={{ fontSize: '0.8em' }}
                onClick={() => setCategoryModalOpen(!isCategoryModalOpen)}
              >
                Category ID Reference{' '}
                <i className="now-ui-icons ui-1_zoom-bold" />
              </a>
              {isCategoryModalOpen ? (
                <Modal
                  size={'xl'}
                  isOpen={isCategoryModalOpen}
                  toggle={() => setCategoryModalOpen(!isCategoryModalOpen)}
                >
                  <TreeTable />
                </Modal>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
