// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  visibility: hidden;
  width: 100%;
  background-color: #2d2d2d;
  color: #fff;
  padding: 14px 24px 14px 24px;
  position: fixed;
  z-index: 101;
  bottom: 0px;
  font-size: 0.7rem;
  transition: all 0.5s ease-in-out;
}

.toast.success {
  background-color: #333333;
}

.toast._show {
  visibility: visible;
  bottom: 0;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Toast/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,4BAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,gCAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,SAAA;EACA,UAAA;AAGF","sourcesContent":[".toast {\n  visibility: hidden;\n  width: 100%;\n  background-color: #2d2d2d;\n  color: #fff;\n  padding: 14px 24px 14px 24px;\n  position: fixed;\n  z-index: 101;\n  bottom: 0px;\n  font-size: 0.7rem;\n  transition: all 0.5s ease-in-out;\n}\n.toast.success {\n  background-color: #333333;\n}\n.toast._show {\n  visibility: visible;\n  bottom: 0;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
