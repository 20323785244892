import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { ReactSortable } from 'react-sortablejs';
import 'react-datetime/css/react-datetime.css';
import Switch from 'react-switch';
import FeatureRankingManage from 'views/FeatureRanking/Manage';
// reactstrap components
import { Col, FormGroup, Row, Label } from 'reactstrap';

import { useStateValue } from 'context/RuleContext';

import { listFeatureRanking } from './actions';
import Input from 'reactstrap/lib/Input';
import Modal from 'reactstrap/lib/Modal';
import FeatureRankingListing from 'views/FeatureRanking';
import classNames from 'classnames';

export default ({
  assignedList,
  setAssignedList,
  featureRankingProperties,
  setFeatureRankingProperties
}) => {
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  const [featureRankingItemModalOpen, setFeatureRankingItemModalOpen] =
    useState(false);
  const [selectedRankingId, setSelectedRankingId] = useState(false);
  const { ruleDetail } = ruleState;
  const [manageFeatureRankingModalToggle, setManageFeatureRankingModalToggle] =
    useState(false);

  useEffect(() => {
    listFeatureRanking({}, ruleDispatch);
  }, []);

  const handleRankingAssign = opt => {
    if (opt.value) {
      setAssignedList([...assignedList, { id: Number(opt.value), limit: 2 }]);
    }
  };

  const handleLimitChange = ({ id, limit }) => {
    let featureIndex = assignedList.findIndex(
      item => Number(item.id) === Number(id)
    );
    const feature = { ...assignedList[featureIndex], limit };
    assignedList[featureIndex] = feature;
    setAssignedList([...assignedList]);
  };
  const handleSorting = list => {
    if (list && list.length) {
      setAssignedList([
        ...list.map(item => ({ id: Number(item.id), limit: item.limit }))
      ]);
    }
  };
  const handleRemove = ({ index }) => {
    const newAssignedList = [...assignedList];
    newAssignedList.splice(index, 1);
    setAssignedList(newAssignedList);
  };

  const handleFeatureRankingEnable = enabled => {
    setFeatureRankingProperties({ ...featureRankingProperties, enabled });
  };

  const handleManageFeatureRanking = () => {
    setManageFeatureRankingModalToggle(!manageFeatureRankingModalToggle);
    if (manageFeatureRankingModalToggle) {
      setAssignedList([...assignedList]);
    }
  };

  const handleFeatureRankingProperties = event => {
    const { name, value } = event.target;
    setFeatureRankingProperties({ ...featureRankingProperties, [name]: value });
  };
  const handleRankingEditDirect = id => {
    setSelectedRankingId(id);
    setFeatureRankingItemModalOpen(true);
  };

  const { featureRankingList = [] } = ruleState;
  const options = (featureRankingList || [])
    .filter(feature => {
      return !!!assignedList.find(
        item => Number(item.id) === Number(feature.id)
      );
    })
    .map(feature => ({
      value: feature.id,
      label: `${feature.mainCategory || ''}  ${feature.rankingName}`
    }));
  //TODO Optimize this part
  const usedFeatureRankingList =
    (featureRankingList.length &&
      assignedList
        .filter(item =>
          featureRankingList.find(
            feature => Number(feature.id) === Number(item.id)
          )
        )
        .map(item => {
          return {
            ...featureRankingList.find(
              feature => Number(feature.id) === Number(item.id)
            ),
            limit: item.limit
          };
        })) ||
    [];
  const modalClasses = classNames({
    'modal-content': {
      height: '100%'
    },
    modal: {
      WebkitTransform: 'translate3d(0%, 0, 0)',
      msTransform: 'translate3d(0%, 0, 0)',
      oTransform: 'translate3d(0%, 0, 0)',
      transform: 'translate3d(0%, 0, 0)'
    },
    'modal-dialog': {
      transition: '1s',
      left: '0'
    }
  });
  return (
    <>
      <Modal
        size={'lg'}
        isOpen={manageFeatureRankingModalToggle}
        toggle={handleManageFeatureRanking}
        style={{
          maxWidth: '50%',
          height: '100vh',
          left: '0',
          margin: '0'
        }}
        className={`${modalClasses} no-border`}
        contentClassName={'no-border'}
      >
        <FeatureRankingListing handleClose={handleManageFeatureRanking} />
      </Modal>
      {featureRankingItemModalOpen ? (
        <Modal
          size={'lg'}
          isOpen={featureRankingItemModalOpen}
          toggle={() =>
            setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
          }
          style={{
            maxWidth: '60%',
            height: '100vh',
            left: '0',
            margin: '0'
          }}
          className={`${modalClasses} no-border`}
          contentClassName={'no-border'}
        >
          <FeatureRankingManage
            handleClose={() =>
              setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
            }
            idFromProps={selectedRankingId}
            onSaveComplete={() =>
              setFeatureRankingItemModalOpen(!featureRankingItemModalOpen)
            }
          />{' '}
        </Modal>
      ) : null}
      <FormGroup>
        <Label>Activate Feature Ranking</Label>
        <br />
        <Switch
          onChange={checked => handleFeatureRankingEnable(checked)}
          checked={featureRankingProperties.enabled}
        />
      </FormGroup>
      {featureRankingProperties.enabled ? (
        <>
          <FormGroup>
            <Label>Choose number of sequence</Label>
            <Input
              onChange={handleFeatureRankingProperties}
              name="numberOfSequence"
              placeholder="Choose number of sequence"
              type="number"
              // defaultValue={filterValues}
              value={featureRankingProperties.numberOfSequence || 100}
              // styles={colourStyles}
            />
          </FormGroup>
          <FormGroup>
            <Select
              onChange={handleRankingAssign}
              options={options}
              value={{ label: 'Add a feature ranking' }}
            />
            <div className="text-right">
              <a
                onClick={handleManageFeatureRanking}
                className="pointer underline fs-12 c-red"
              >
                Manage Feature Ranking
              </a>
            </div>
          </FormGroup>
          <div className="mt-3 ml-3 mr-3 ">
            <ReactSortable
              list={usedFeatureRankingList}
              setList={handleSorting}
            >
              {usedFeatureRankingList.map((ranking, index) => {
                const {
                  rankingName,
                  id,
                  limit = 2,
                  mainCategory
                } = ranking || {};

                return (
                  <Row
                    key={id}
                    className={`border-bottom ${!index && 'border-top'}`}
                  >
                    <Col
                      style={{ width: 15 }}
                      lg={1}
                      className="p-2 border-right border-left "
                    >
                      {index + 1}
                    </Col>

                    <Col className="text-left p-2 border-right  " lg={8}>
                      <a
                        role="link"
                        className="pointer cursor"
                        onClick={() => {
                          handleRankingEditDirect(id);
                        }}
                        onKeyPress={() => {
                          handleRankingEditDirect(id);
                        }}
                      >
                        <b>{mainCategory || ''}</b> {rankingName}
                      </a>
                    </Col>
                    <Col
                      className="text-left  p-0 border-right  "
                      style={{ minWidth: 45 }}
                      lg={2}
                    >
                      <select
                        className="form-control border-0 p-0"
                        style={{ height: '100%' }}
                        value={limit}
                        onChange={event =>
                          handleLimitChange({
                            id,
                            limit: Number(event.target.value)
                          })
                        }
                      >
                        {[1, 2, 4, 6, 8, 10, 12].map(item => (
                          <option value={item}>{item} times</option>
                        ))}
                      </select>
                    </Col>
                    <Col
                      lg={1}
                      style={{ 'padding-top': '6%!important' }}
                      className="text-left pt-4 p-2 pl-3 border-right"
                    >
                      <span
                        className="pointer c-pastel-red"
                        onClick={() => {
                          handleRemove({ index });
                        }}
                        onKeyPress={() => {
                          handleRemove({ index });
                        }}
                      >
                        X
                      </span>
                    </Col>
                  </Row>
                );
              })}
            </ReactSortable>
          </div>
        </>
      ) : null}
    </>
  );
};
