// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .MuiInputBase-root .MuiInputBase-input div[class^="makeStyles-valueContainer"] {

    width: 80%;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
    align-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/views/Expression/storefrontsettings.css"],"names":[],"mappings":"CAAC;;IAEG,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[" .MuiInputBase-root .MuiInputBase-input div[class^=\"makeStyles-valueContainer\"] {\n\n    width: 80%;\n    max-height: 300px;\n    overflow-x: hidden;\n    overflow-y: auto;\n    flex-wrap: wrap;\n    align-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
