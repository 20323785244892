// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableClass {
    border-spacing: 0 10px;
}

.tdClass {
    padding: 5px 10px;
}

.slider-toggle-container {
    display: inline-block;
    vertical-align: middle;
}

.slider-toggle-button {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* Hide overflow content */
}

.slider-toggle-thumb {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s ease;
    margin: 2px;
}

.slider-toggle-button.active .slider-toggle-thumb {
    transform: translateX(100%);
}`, "",{"version":3,"sources":["webpack://./src/components/Product/item.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,+BAA+B;IAC/B,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".tableClass {\n    border-spacing: 0 10px;\n}\n\n.tdClass {\n    padding: 5px 10px;\n}\n\n.slider-toggle-container {\n    display: inline-block;\n    vertical-align: middle;\n}\n\n.slider-toggle-button {\n    width: 60px;\n    height: 30px;\n    border-radius: 15px;\n    padding: 0;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n    /* Hide overflow content */\n}\n\n.slider-toggle-thumb {\n    width: 26px;\n    height: 26px;\n    border-radius: 50%;\n    background-color: white;\n    transition: transform 0.3s ease;\n    margin: 2px;\n}\n\n.slider-toggle-button.active .slider-toggle-thumb {\n    transform: translateX(100%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
