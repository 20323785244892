import React, { useEffect, useState, useRef } from 'react';
import 'firebase/auth';
import Switch from 'react-switch';
import { Button, Badge } from 'reactstrap';
import moment from 'moment';
import { STORES } from '../../constants';
import { getPDPBannerList, setPDPBannerList } from './actions';

// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Table, Tooltip, FormGroup } from 'reactstrap';
// reactstrap components

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import BannerForm from './PDPBannerForm.jsx';
import { useStateValue } from '../../context/RuleContext';

import { ReactSortable } from 'react-sortablejs';

const PDPBanner = () => {
  const [pdpBanners, setPDPBanner] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tempPDPBanners, setTempPDPBanners] = useState([]);
  const isSortingRef = useRef(false);

  const toggle = (type, key) => setTooltipOpen({ ...tooltipOpen, [`${type}_${key}`]: !tooltipOpen[`${type}_${key}`] });

  const typeName = 'PDP Banner';
  const {
    productBanner: [productbannerState, productbannerDispatch]
  } = useStateValue();
  useEffect(() => {
    getPDPBannerList(productbannerDispatch);
  }, [productbannerDispatch]);

  const { pdpBannerList = [] } = productbannerState;

  useEffect(() => {
    if (pdpBannerList && pdpBannerList.length) {
      setPDPBanner(JSON.parse(pdpBannerList));
      setTempPDPBanners(JSON.parse(pdpBannerList));
      setisEdit(false);
      seteditBannerData({});
      seteditBannerIndex(-1);
    }
  }, [pdpBannerList]);

  const getBannerRow = ({ startDate, endDate, currentTime, name }) => {
    if (!startDate && !endDate) {
      return 'Not Scheduled';
    }
    let currGte = false;
    let currLte = false;
    if (startDate && new Date(startDate).getTime() <= currentTime) {
      currGte = true;
    }
    if (endDate && new Date(endDate).getTime() >= currentTime) {
      currLte = true;
    }
    if (currLte && currGte) {
      return 'Active';
    }
    if (!currLte && currGte) {
      return 'Expired';
    }
    if (currLte && !currGte) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div>Scheduled</div>
          <div>{`(${moment(startDate).format('DD MMMM')}})`}</div>
          <div>To</div>
          <div>{`(${moment(endDate).format('DD MMMM')}})`}</div>
        </div>
      );
    }
  };

  const renderRow = (row, index) => {
    const { name, collections, ruleType, bgColor, textColor, status, isFilterable, startDate, endDate, basedOn } = row;
    const currentTime = new Date().getTime();

    const labelWidth = 150;
    const labelStyle = {
      display: 'block',
      width: labelWidth,
      backgroundColor: '#B8E2FF',
      color: '#2d2d2d',
      padding: 4,
      marginBottom: '2px',
      borderRadius: 4
    };

    return (
      <Row className="row-list" style={{ alignItems: 'center', padding: '20px 0' }}>
        <Col xs={2} className="text-left myhandle">
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20PX'
              }}
            >
              <i class="fa fa-arrows-alt cursor-move"></i>
              &nbsp;&nbsp;{name}
            </div>
          </div>
          <div>
            <div
              color="secondary"
              pill
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                margin: '20px',
                background: 'grey',
                borderRadius: '20px',
                padding: '5px',
                width: '100px',
                textAlign: 'center',
                color: 'white',
                fontSize: '10px'
              }}
            >
              {getBannerRow({ startDate, endDate, currentTime, name })}
            </div>
          </div>
        </Col>

        <Col xs={3} className="text-left ">
          {STORES.filter(store => store.lang === 'en' && row[store.storeId]).map(store => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{store.country}:&nbsp;</span>
                <span className="nowrap" style={labelStyle}>
                  {row[store.storeId]}
                </span>
                <br />
              </div>
            );
          })}
        </Col>
        <Col xs={2} className="text-right " style={{}} dir="rtl">
          {STORES.filter(store => store.lang === 'ar' && row[store.storeId]).map(store => {
            return (
              <div className="nowrap" style={labelStyle}>
                {row[store.storeId]}
              </div>
            );
          })}
        </Col>
        <Col xs={3} className="text-left">
          {collections || `Rule: ${ruleType}`}
        </Col>

        {/* <Col xs={3} className="text-center p-0 nowrap-block" style={{ width: 150 }}> */}
        <Col xs={1}>
          <label
            style={{
              zoom: 0.7,
              verticalAlign: 'text-top',
              marginTop: 5,
              marginRight: 10
            }}
          >
            <Switch
              onChange={checked =>
                changeBannerStatus({
                  index,
                  checked
                })
              }
              checked={status && 'checked'}
            />
          </label>
        </Col>

        <Col xs={1}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              role="button"
              id={`TooltipEdit_${index}`}
              onClick={() => editPDPBanner(index)}
              onKeyPress={() => editPDPBanner(index)}
              className="btn-neutral pl-3"
              color=""
            >
              <i className="fa fa-edit " />
            </Button>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen[`edit_${index}`]}
              target={`TooltipEdit_${index}`}
              toggle={() => toggle('edit', index)}
            >
              Edit Banner ({name})
            </Tooltip>
            <Button
              role="button"
              onClick={() => removePDPBanner(index)}
              onKeyPress={() => removePDPBanner(index)}
              className="btn-neutral pl-3"
              color="default"
              id={`TooltipDelete_${index}`}
            >
              <i className="fa fa-trash danger" />
            </Button>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen[`delete_${index}`]}
              className="btn-neutral pl-3"
              target={`TooltipDelete_${index}`}
              toggle={() => toggle('delete', index)}
            >
              Delete Banner ({name})
            </Tooltip>
          </div>
        </Col>
      </Row>
    );
  };

  const updatePDPBannerList = (bannerFormData = {}) => {
    let newBanner = [];
    newBanner = JSON.parse(JSON.stringify(pdpBanners));
    if (isEdit && editBadgeIndex >= 0) {
      newBanner[editBadgeIndex] = bannerFormData;
    } else {
      newBanner.push(bannerFormData);
    }
    setPDPBannerList({ banners: newBanner }, productbannerDispatch);
    seteditBannerData({});
    setisEdit(false);
  };

  const removePDPBanner = index => {
    if (window.confirm(`Are you sure to delete?`)) {
      let newBanner = [];
      newBanner = pdpBanners;
      newBanner.splice(index, 1);
      setPDPBannerList({ banners: newBanner }, productbannerDispatch);
    }
  };
  const sortBanner = items => {
    setPDPBanner(items);
    if (!isSortingRef.current) return;
    isSortingRef.current = false;

    setPDPBannerList({ banners: items }, productbannerDispatch);
  };
  const [isEdit, setisEdit] = useState(false);
  const [editBannerData, seteditBannerData] = useState({});
  const [editBadgeIndex, seteditBannerIndex] = useState(-1);
  const editPDPBanner = index => {
    if (index >= 0) {
      setisEdit(true);
      seteditBannerData(pdpBanners[index]);
      seteditBannerIndex(index);
    }
  };

  const changeBannerStatus = ({ index, checked }) => {
    if (index >= 0) {
      let newBanner = [];
      newBanner = pdpBanners;
      newBanner[index].status = checked;

      setPDPBannerList({ banners: newBanner }, productbannerDispatch);
    }
  };

  // const [filterInfo, setFilter] = useState();
  // const handleFilter = event => {
  //   setFilter(event.target.value);
  //   if (event.target.value === 'bottom_badge') {
  //     const filterPromo = tempPDPBanners.filter(promo => promo.isSystemGeneratedTag);
  //     setPDPBanner(filterPromo);
  //   } else if (event.target.value === 'top_badge') {
  //     const filterPromo = tempPDPBanners.filter(
  //       promo => promo.isSystemGeneratedTag === undefined || !promo.isSystemGeneratedTag
  //     );
  //     setPDPBanner(filterPromo);
  //   } else {
  //     setPDPBanner(tempPDPBanners);
  //   }
  // };
  try {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={5} xs={12} lg={4}>
              <BannerForm
                typeName={typeName}
                updatePDPBannerList={updatePDPBannerList}
                isEdit={isEdit}
                editBannerData={editBannerData}
              />
            </Col>
            <Col md={7} xs={12} lg={8}>
              <Card>
                {/* <CardHeader>
                  <Row>
                    <Col md="9">
                      <CardTitle tag="h4">PDP Banner List</CardTitle>
                    </Col>
                    <Col md="3" style={{ alignContent: 'flex-end', 'text-align': 'right' }}>
                      <Row>
                        <Col md="12" style={{ alignContent: 'flex-end', 'text-align': 'left' }}>
                          Badge Type{' '}
                          <FormGroup>
                            <select
                              className="form-control"
                              name="mainCategory"
                              disabled={true}
                              value={filterInfo}
                              onChange={handleFilter}
                            >
                              <option value="all">All Badge</option>
                              <option value="top_badge">Top Badge</option>
                              <option value="bottom_badge">Bottom Badge</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader> */}
                <CardBody>
                  {!pdpBanners.length && (
                    <Row>
                      <Col md={12} xs={12} lg={12}>
                        <div style={{ padding: 40 }}>No List.</div>
                      </Col>
                    </Row>
                  )}
                  {pdpBanners.length > 0 && (
                    <>
                      <Row className="row-list _heading">
                        <Col xs={2}>Badge Name</Col>
                        <Col xs={3}>EN Text</Col>
                        <Col xs={2}>AR Text</Col>
                        <Col xs={3}>Collections/Rules</Col>
                        <Col xs={1}>Status</Col>
                        <Col xs={1}>Action</Col>
                      </Row>
                      <ReactSortable
                        handle={'.myhandle'}
                        list={pdpBanners}
                        onUpdate={() => (isSortingRef.current = true)}
                        setList={items => {
                          sortBanner(items);
                        }}
                      >
                        {pdpBanners.map((row, index) => {
                          return renderRow(row, index);
                        })}
                      </ReactSortable>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    window.location.reload();
  }
};

export default PDPBanner;
