export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONFIG_DATA': {
      const { data } = action;
      return {
        ...state,
        data: data
      };
    }
    default: {
      return state;
    }
  }
};
