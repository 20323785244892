import React, { useEffect, useState } from 'react';

import { Row, Col, FormGroup, Input, InputGroup } from 'reactstrap';

import { useStateValue } from '../../context/RuleContext';
import { getFilterAttributes } from '../../views/FilterListing/actions';

export default ({ handleRuleChange, index, setBannerData = () => {}, bannerData = {} }) => {
  const {
    filterattributes: [filterattributeState, filterAttributeDispatch]
  } = useStateValue();
  useEffect(() => {
    getFilterAttributes({}, filterAttributeDispatch);
  }, []);
  const handleNameChange = event => {
    setBannerData({ ...bannerData, ruleType: event.target.value });
  };
  const handleLogicalChange = event => {
    console.log('ruleLogical', event.target.value);
    setBannerData({ ...bannerData, ruleLogical: event.target.value });
  };
  const handleValueChange = event => {
    setBannerData({ ...bannerData, ruleValue: event.target.value });
  };
  const { filterAttributesList = [] } = filterattributeState;
  return (
    <Row>
      <Col className="px-3" md="12">
        <FormGroup>
          <label>Rule Details</label>
          <InputGroup>
            <select
              className="form-control"
              style={{ height: 40, width: 30 }}
              value={bannerData.ruleType}
              onChange={handleNameChange}
              defaultValue=""
              placeholder="Name"
              type="text"
              id={`ruleAttribute${index}`}
            >
              <option>Choose</option>
              <option value="new_arrivals">New Arrivals</option>
              <option value="last7qty">Best Sellers 7Days</option>
              <option value="last15qty">Best Sellers 15days</option>
              <option value="last30qty">Best Sellers 30Days</option>
              <option value="last90qty">Best Sellers 90days</option>
              <option value="backInStore">Back In Store</option>
            </select>

            <select
              value={bannerData.ruleLogical}
              onChange={handleLogicalChange}
              className="form-control"
              style={{ height: 40, width: 30, textAlign: 'center' }}
            >
              <option>Choose</option>
              <option value="is">is</option>
              <option value="lte">lesser or equal to(&le;)</option>
              <option value="gte">greater or equal to(&ge;)</option>
            </select>
            <Input
              value={bannerData.ruleValue}
              onChange={handleValueChange}
              defaultValue=""
              placeholder="value"
              type="number"
              min="1"
            />
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};
