import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'firebase/auth';
import classnames from 'classnames';
import { STORES } from '../../constants';
import Rules from './Rules';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { useEffect } from 'react';

export default ({ typeName, updatePromoBadgesList, isEdit = false, editBadgeData = {} }) => {
  const history = useHistory();
  const [badgeData, setBadgeData] = useState({});
  const [isSameTxt, setIsSameTxt] = useState(true);
  const [isSystemGeneratedTag, setIsSystemGeneratedTag] = useState(editBadgeData.isSystemGeneratedTag || false);
  const [isFilterable, setIsFilterable] = useState(editBadgeData.isFilterable || false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isPLPActive, setPLPActive] = useState('false');
  const [isPDPActive, setPDPActive] = useState('false');

  const handlePDPToggle = () => {
    setPDPActive(!isPDPActive);
  };
  const handlePLPToggle = () => {
    setPLPActive(!isPLPActive);
  };

  const handleBadgeTextChange = ({ textKey = '', textValue = '', lang = 'en' }) => {
    let newBadgeData = { ...badgeData };
    if (isSameTxt) {
      STORES.filter(store => store.lang === lang).forEach(store => {
        newBadgeData[store.storeId] = textValue;
      });
    } else {
      newBadgeData[textKey] = textValue;
    }
    setBadgeData(newBadgeData);
  };

  const handleBadgePDPTextChange = ({ textPDPKey = '', textPDPValue = '', lang = 'en' }) => {
    let newBadgeData = { ...badgeData };
    if (!('pdp' in newBadgeData)) {
      newBadgeData['pdp'] = {};
    }
    if (isSameTxt) {
      STORES.filter(store => store.lang === lang).forEach(store => {
        newBadgeData['pdp'][store.storeId] = textPDPValue;
      });
    } else {
      newBadgeData['pdp'][textPDPKey] = textPDPValue;
    }
    setBadgeData(newBadgeData);
  };

  const hasPDPBadgeTxt = badgeData => {
    let result = false;
    STORES.forEach(store => {
      if (badgeData['pdp'] && badgeData['pdp'][store.storeId] && badgeData['pdp'][store.storeId] !== '') {
        result = true;
      }
    });
    return result;
  };
  const hasBadgeTxt = badgeData => {
    let result = false;
    STORES.forEach(store => {
      if (badgeData[store.storeId] && badgeData[store.storeId] !== '') {
        result = true;
      }
    });
    return result;
  };

  const [isSaving, setSaving] = useState(false);

  const handleSavePromo = async () => {
    const { startDate, endDate } = badgeData;
    const currentTime = new Date().getTime();

    // if (!startDate || !!endDate) {
    //   alert('One of the date is missing.');
    //   return;
    // }
    if (startDate > endDate) {
      alert('Promo start date can not be greater than the end date.');
      return;
    }
    if (endDate && new Date(endDate).getTime() < currentTime) {
      alert('Promo Schedule date can not be set in the past.');
      return;
    }
    if (badgeData) {
      if (!badgeData.name || badgeData.name === '') {
        alert('Please enter the Name field.');
        return;
      } else if (!badgeData.basedOn) {
        alert('Please select Based On information.');
        return;
      } else if (badgeData.basedOn === 'collections' && (!badgeData.collections || badgeData.collections === '')) {
        alert('Please provide Collections.');
        return;
      } else if (badgeData.basedOn === 'rules' && (!badgeData.ruleType || badgeData.ruleType === '')) {
        alert('Please select Rule Type.');
        return;
      } else if (
        badgeData.basedOn === 'rules' &&
        badgeData.ruleType === 'best_sellers' &&
        (!badgeData.ruleNewArrivalsDaySlot || badgeData.ruleNewArrivalsDaySlot === '')
      ) {
        alert('Please choose a Day Slot.');
        return;
      } else if (badgeData.basedOn === 'rules' && (!badgeData.ruleLogical || badgeData.ruleLogical === '')) {
        alert('Please provide Rule Logical.');
        return;
      } else if (badgeData.basedOn === 'rules' && (!badgeData.ruleValue || badgeData.ruleValue <= 0)) {
        alert('Please provide Rule Value.');
        return;
      } else if (badgeData.basedOn === 'collections' && isSystemGeneratedTag && !hasPDPBadgeTxt(badgeData)) {
        alert('Please enter PDP Text for any one Store at least.');
        return;
      } else {
        if (hasBadgeTxt(badgeData)) {
          if (!badgeData.bgColor || badgeData.bgColor == '') {
            badgeData.bgColor = '#B8E2FF';
          }
          if (!badgeData.textColor || badgeData.textColor == '') {
            badgeData.textColor = '#2d2d2d';
          }
          badgeData.status = isEdit ? badgeData.status : true;
          updatePromoBadgesList(badgeData);
          setSaving(true);
        } else {
          alert('Please enter Text for any one Store at least.');
          return;
        }
      }
    } else {
      alert('Something wrong!');
    }
  };

  useEffect(() => {
    if (isEdit == true) {
      setIsFilterable(editBadgeData.isFilterable);
      setIsSystemGeneratedTag(editBadgeData.isSystemGeneratedTag || false);
      if (editBadgeData.startDate) {
        setStartDate(new Date(editBadgeData.startDate));
      } else {
        setStartDate(undefined);
      }
      if (editBadgeData.endDate) {
        setEndDate(new Date(editBadgeData.endDate));
      } else {
        setEndDate();
      }

      setBadgeData(editBadgeData);
    } else {
      setBadgeData({});
      setSaving(false);
      setEndDate();
      setStartDate();
    }
  }, [isEdit, editBadgeData]);
  return (
    <>
      <div className="">
        <Card className="">
          <CardHeader tag="h4" style={{ marginTop: -10 }}>
            <CardTitle>
              {isEdit ? 'Edit' : 'Create'} {typeName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>Name</label>
                  <Input
                    name="ruleName"
                    value={badgeData.name || ''}
                    onChange={event => setBadgeData({ ...badgeData, name: event.target.value })}
                    defaultValue=""
                    placeholder={`${typeName} Name`}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-3 text-left" md="6">
                <FormGroup>
                  <label>BG Color</label>
                  <Input
                    name="ruleName"
                    value={badgeData.bgColor || '#b8e2ff'}
                    onChange={event =>
                      setBadgeData({
                        ...badgeData,
                        bgColor: event.target.value
                      })
                    }
                    defaultValue=""
                    placeholder={`${typeName} Name`}
                    type="color"
                    style={{
                      padding: '0',
                      height: '29px'
                    }}
                  />
                </FormGroup>
              </Col>
              <Col className="px-3 text-left" md="6">
                <FormGroup>
                  <label>Text Color</label>
                  <Input
                    name="ruleName"
                    value={badgeData.textColor || '#2d2d2d'}
                    onChange={event =>
                      setBadgeData({
                        ...badgeData,
                        textColor: event.target.value
                      })
                    }
                    defaultValue=""
                    placeholder={`${typeName} Name`}
                    type="color"
                    style={{
                      padding: '0',
                      height: '29px'
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {badgeData.basedOn === 'collections' ? (
              <Row>
                <Col className="px-3 text-left" md="12">
                  <FormGroup>
                    <label>
                      <Input
                        checked={isFilterable}
                        onChange={event => {
                          setIsFilterable(event.target.checked);
                          setBadgeData({ ...badgeData, isFilterable: event.target.checked });
                        }}
                        type="checkbox"
                        style={{
                          position: 'static',
                          margin: '0 5px 0 0'
                        }}
                      />
                      Filterable on the UI.
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>
                    <Input
                      checked={isSameTxt}
                      onChange={event => setIsSameTxt(event.target.checked)}
                      // defaultValue={true}
                      type="checkbox"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    Same for other country.
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {badgeData.basedOn === 'collections' ? (
              <Row>
                <Col className="px-3 text-left" md="12">
                  <FormGroup>
                    <label>
                      <Input
                        checked={isSystemGeneratedTag}
                        onChange={event => {
                          setIsSystemGeneratedTag(event.target.checked);
                          setBadgeData({ ...badgeData, isSystemGeneratedTag: event.target.checked });
                        }}
                        type="checkbox"
                        style={{
                          position: 'static',
                          margin: '0 5px 0 0'
                        }}
                      />
                      Bottom tag.
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <Col className="px-3 text-left" md="12">
                <FormGroup>
                  <label>Schedule</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', color: '#9a9a9a' }}>
                    <div style={{ width: '100%' }}>
                      <div>From </div>
                      <div style={{ display: 'flex' }}>
                        <DatePicker
                          dateFormat="dd-M-yyyy hh:mm:ss"
                          style={{ color: '#9a9a9a' }}
                          wrapperClassName="datePicker"
                          placeholderText={'PromoBadge Start Date'}
                          selected={startDate}
                          showTimeSelect
                          onChange={date => {
                            setStartDate(date);
                            setBadgeData({ ...badgeData, startDate: date.toISOString() });
                          }}
                        />
                        <Button
                          close
                          style={{ margin: '0 10px' }}
                          onClick={() => {
                            setStartDate();
                            setBadgeData({ ...badgeData, startDate: undefined });
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      <div>To</div>
                      <div style={{ display: 'flex' }}>
                        <Button
                          close
                          style={{ margin: '0 10px' }}
                          onClick={() => {
                            setEndDate();
                            setBadgeData({ ...badgeData, endDate: undefined });
                          }}
                        />

                        <DatePicker
                          dateFormat="dd-M-yyyy hh:mm:ss"
                          selected={endDate}
                          placeholderText={'PromoBadge End Date'}
                          showTimeSelect
                          onChange={date => {
                            setEndDate(date);
                            setBadgeData({ ...badgeData, endDate: date.toISOString() });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {isSystemGeneratedTag ? (
              <Row>
                <Col
                  className="md-12"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handlePLPToggle}
                >
                  PLP Text
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row className={classnames(isPLPActive ? 'show' : 'hidden')}>
              {STORES.map((store, index) => {
                return (
                  <Col
                    className={classnames('px-3', store.lang === 'ar' ? 'text-right' : 'text-left')}
                    md="6"
                    dir={store.lang === 'ar' ? 'rtl' : 'ltr'}
                  >
                    <FormGroup>
                      <label>{store.name}</label>
                      <Input
                        name="ruleName"
                        value={badgeData[store.storeId] || ''}
                        onChange={event =>
                          handleBadgeTextChange({
                            textKey: store.storeId,
                            textValue: event.target.value.replace(/[&<>"']/g, ''),
                            lang: store.lang
                          })
                        }
                        defaultValue=""
                        placeholder={`${typeName} Text`}
                        type="text"
                        disabled={store.storeId === 1 || store.storeId === 3 || !isSameTxt ? false : true}
                      />
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
            {badgeData.basedOn === 'collections' && isSystemGeneratedTag ? (
              <>
                <Row>
                  <Col
                    className="md-12"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handlePDPToggle}
                  >
                    PDP Text
                  </Col>
                </Row>
                <Row className={classnames(isPDPActive ? 'show' : 'hidden')}>
                  {STORES.map((store, _index) => {
                    return (
                      <Col
                        className={classnames('px-3', store.lang === 'ar' ? 'text-right' : 'text-left')}
                        md="6"
                        dir={store.lang === 'ar' ? 'rtl' : 'ltr'}
                      >
                        <FormGroup>
                          <label>{store.name}</label>
                          <Input
                            name="ruleName"
                            value={(badgeData['pdp'] && badgeData['pdp'][store.storeId]) || ''}
                            onChange={event =>
                              handleBadgePDPTextChange({
                                textPDPKey: store.storeId,
                                textPDPValue: event.target.value.replace(/[&<>"']/g, ''),
                                lang: store.lang
                              })
                            }
                            defaultValue=""
                            placeholder={`${typeName} PDP Text`}
                            type="text"
                            disabled={store.storeId === 1 || store.storeId === 3 || !isSameTxt ? false : true}
                          />
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              ''
            )}
            <Row>
              <Col className="px-3 text-left" md="12" style={{ margin: '10px 0 0 0' }}>
                <FormGroup>
                  <label style={{ margin: '0 15px 0 0' }}>
                    <Input
                      name="collections"
                      checked={badgeData.basedOn === 'collections'}
                      onChange={event =>
                        setBadgeData({
                          ...badgeData,
                          basedOn: event.target.value ? 'collections' : 'rules'
                        })
                      }
                      type="radio"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    For Collections
                  </label>
                  <label>
                    <Input
                      name="rules"
                      checked={badgeData.basedOn === 'rules'}
                      onChange={event =>
                        setBadgeData({
                          ...badgeData,
                          basedOn: event.target.value ? 'rules' : 'collections'
                        })
                      }
                      type="radio"
                      style={{
                        position: 'static',
                        margin: '0 5px 0 0'
                      }}
                    />
                    For Rules
                  </label>
                </FormGroup>
              </Col>
              {badgeData.basedOn === 'collections' && (
                <Col className="px-3 text-left" md="12">
                  <FormGroup>
                    <label>Collection Name</label>
                    <textarea
                      style={{
                        border: '1px solid #eee',
                        resize: 'none',
                        minHeight: 100,
                        padding: 15
                      }}
                      className="form-control"
                      name="sizeBrokenessField"
                      multiple={true}
                      value={badgeData.collections || ''}
                      onChange={event =>
                        setBadgeData({
                          ...badgeData,
                          collections: event.target.value
                        })
                      }
                      defaultValue=""
                    ></textarea>
                  </FormGroup>
                </Col>
              )}
              {badgeData.basedOn === 'rules' && (
                <>
                  <Col className="px-3 text-left" md="12">
                    <Rules setBadgeData={setBadgeData} badgeData={badgeData} />
                  </Col>
                </>
              )}
            </Row>
            <div className="px-3 text-right">
              <Button disabled={isSaving} color="info" className="btn-round" onClick={handleSavePromo}>
                {isSaving ? 'Saving. Please wait...' : 'Save & Publish'}
              </Button>
            </div>
            {/* </Form> */}
          </CardBody>
        </Card>
      </div>
    </>
  );
};
