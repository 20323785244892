
export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LIST_SEARCHABLEATTRIBUTES": {
      const { searchableattributes } = action.data;
      return {
        ...state,
        searchableAttributesList: searchableattributes,
      };
    }
    case "GET_SEARCHABLEATTRIBUTE": {
      const { searchableAttributesDetail } = action.data;
      return {
        ...state,
        searchableAttributesDetail,
      };
    }
    default: {
      return state;
    }
  }
};
