import axios from 'axios';
import { toast } from 'react-toastify';
import { getCurrentUser } from '../utils/index';

const excludeUrl = ['https://stylishop.store/getstrapi', 'https://stylishop.store/getstrapi-categories'];
const showToast = (message, options) => {
  toast(message, {
    autoClose: 3000, // Adjust the duration as needed
    position: 'top-right',
    ...options,
    onClose: () => {
      // Trigger the next toast after the current one is closed
      // You can add logic here to show the next toast based on some conditions
      console.log('Toast closed:', message);
    }
  });
};
// const email = getEmail();
const axiosOptions = {
  timeout: 36000000,
  headers: {}
};
const axiosMobileInstance = axios.create(axiosOptions);

axiosMobileInstance.interceptors.request.use(
  async config => {
    const url = config.url;
    const user = getCurrentUser() || null;
    let token = '';
    if (user) {
      token = user.token;
    }
    const iConfig = config;
    if (url && !excludeUrl.includes(url)) {
      iConfig.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosMobileInstance.interceptors.response.use(
  res => {
    return res;
  },
  error => {
    console.log('error::', error?.response);
    if (403 === error?.response?.status) {
      const msg = 'You do not have adequate permission to access this Page. Please contact your administrator.';
      showToast(`${msg}`, { type: 'error', toastId: '403' });
      return Promise.reject(error);
    } else {
      if (error?.response?.data?.statusCode === 401) {
        const msg = 'Authentication failed.Please logout and login Again';
        showToast(`${msg}`, { type: 'error', toastId: '401' });
        return Promise.reject(error);
      }
      showToast(`Something went wrong. [ ${error}]`, { type: 'error' });
      return Promise.reject(error);
    }
  }
);
export default axiosMobileInstance;
