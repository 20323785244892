import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  ModalFooter
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';

import { useStateValue } from 'context/RuleContext';
import { deleteRule } from 'variables/rules';
import SearchRules from 'views/Expression/SearchRules';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import { setFeatureRanking, listFeatureRanking } from './actions';
import CardFooter from 'reactstrap/lib/CardFooter';

export default ({
  match: { params: { id } = {} } = {},
  idFromProps,
  onSaveComplete,
  handleClose = () => { }
} = {}) => {
  const history = useHistory();
  const rankingId = idFromProps || id;
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  const [ruleGroups, setRuleGroups] = useState();
  const [rankingName, setRankingName] = useState('');
  const [mainCategory, setMainCategory] = useState('Women');
  const onRuleGroupChange = ruleGroups => {
    setRuleGroups(ruleGroups);
  };

  const handleRankingNameChange = event => {
    setRankingName(event.target.value);
  };
  const handleMainCategoryChange = event => {
    setMainCategory(event.target.value);
  };

  useEffect(() => {
    let featureRanking;
    if (rankingId) {
      const { featureRankingList = [] } = ruleState;
      featureRanking = featureRankingList.find(
        ranking => Number(ranking.id) === Number(rankingId)
      );
      if (featureRanking) {
        setRuleGroups([...(featureRanking.ruleGroups || [])]);
        setRankingName(featureRanking.rankingName);
        if (featureRanking.mainCategory) {
          setMainCategory(featureRanking.mainCategory);
        }
      }
    }
    if (!featureRanking) {
      setRuleGroups([
        [
          {
            name: 'categoryIds',
            logical: 'contains',
            id: new Date().getTime(),
            type: 'must'
          }
        ]
      ]);
    }
  }, [ruleState.featureRankingList]);

  useEffect(() => {
    listFeatureRanking({}, ruleDispatch);
  }, []);

  const handleSaveFeatureRanking = async () => {
    const { error } = await setFeatureRanking(
      { ruleGroups, rankingName, mainCategory, id: rankingId },
      ruleDispatch
    );
    if (!error) {
      onSaveComplete();
    }
  };

  return (
    <>
      {/* <PanelHeader size="sm"></PanelHeader> */}
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col md="9">
                    <CardTitle tag="h4">
                      {idFromProps ? 'Edit ' : 'Add'} Feature Ranking&nbsp;
                    </CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <label>Main Category</label>
                      <select
                        className="form-control"
                        name="mainCategory"
                        value={mainCategory}
                        onChange={handleMainCategoryChange}
                        defaultValue=""
                      >
                        <option value="">--Select--</option>
                        <option value="Women">Women</option>
                        <option value="Men">Men</option>
                        <option value="Beauty">Beauty</option>
                        <option value="Kids">Kids</option>
                        <option value="Home">Home</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md={10}>
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        name="rankingName"
                        value={rankingName}
                        onChange={handleRankingNameChange}
                        defaultValue=""
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <SearchRules
                  ruleGroups={ruleGroups}
                  onRuleGroupChange={onRuleGroupChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalFooter
        style={{
          position: 'fixed',
          bottom: 0,
          background: 'inherit',
          borderTop: '1px solid #eee',
          width: '60%',
          height: '60px'
        }}
      >
        <Button
          color="default"
          size="sm"
          outline
          className="btn-round"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          color="info"
          size="sm"
          className="btn-round"
          onClick={handleSaveFeatureRanking}
        >
          Save Ranking
        </Button>
      </ModalFooter>
    </>
  );
};
