/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUsage } from 'views/Monitoring/actions';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';
import Header from 'views/Monitoring/Header';

export default ({
  match: {
    params: { id }
  }
}) => {
  const [nodeUsage, setNodeUsage] = useState({});
  useEffect(() => {
    getUsage().then(data => {
      setNodeUsage(data);
    });
  }, []);

  const format = (num = '') => {
    return num.toLocaleString('en-US');
  };
  // console.log('nodeUsage', nodeUsage);
  let sums = {
    document_get_action: 0,
    bulk_action: 0,
    search_action: 0,
    document_update_action: 0,
    update_by_query_action: 0
  };
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <Header />
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Node ID</th>
                      <th>Since</th>
                      <th>Bulk Action</th>
                      <th>Search Action</th>
                      <th>Document Update Action</th>
                      <th>Document Get Action</th>
                      <th>Update By Get Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nodeUsage &&
                      Object.keys(nodeUsage).map((nodeKey, id) => {
                        const node = nodeUsage[nodeKey];
                        sums['bulk_action'] += node.rest_actions.bulk_action || 0;
                        sums['search_action'] += node.rest_actions.search_action || 0;
                        sums['document_update_action'] += node.rest_actions.document_update_action || 0;
                        sums['document_get_action'] += node.rest_actions.document_get_action || 0;
                        sums['update_by_query_action'] += node.rest_actions.update_by_query_action || 0;
                        return (
                          <tr>
                            <td>{nodeKey}</td>
                            <td>
                              {new Date(node.since).toDateString()}
                              <br />
                              {new Date(node.since).toTimeString()}
                            </td>
                            <td>{format(node.rest_actions.bulk_action || 0)}</td>
                            <td>{format(node.rest_actions.search_action || 0)}</td>
                            <td>{format(node.rest_actions.document_update_action || 0)}</td>
                            <td>{format(node.rest_actions.document_get_action || 0)}</td>
                            <td>{format(node.rest_actions.update_by_query_action || 0)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <th colspan="2">Total</th>
                    <th>{format(sums.bulk_action)}</th>
                    <th>{format(sums.search_action)}</th>
                    <th>{format(sums.document_update_action)}</th>
                    <th>{format(sums.document_get_action)}</th>
                    <th>{format(sums.update_by_query_action)}</th>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
