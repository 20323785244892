import React, { useState, useEffect } from 'react';

// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';

const ThresHoldRow = ({ rankModeData, editaCategorySlottingData, rankMode, setEditaCategorySlottingData, index }) => {
  const [operator, setOperator] = useState('');
  const [thresHold, setThresHold] = useState('');
  const criteriaDiables = ['li-hc', 'bestsellers', 'rebuys'];

  useEffect(() => {
    if (rankModeData) {
      setOperator(rankModeData.operator);
      setThresHold(rankModeData.threshold);
    }
  }, [rankModeData]);

  const handleOperator = operator => {
    setOperator(operator);
    const tempRankModeData = rankModeData;
    tempRankModeData.operator = operator;
    const tempEditaCategorySlottingData = editaCategorySlottingData;
    tempEditaCategorySlottingData[rankMode][index] = tempRankModeData;
    setEditaCategorySlottingData(tempEditaCategorySlottingData);
  };

  const handleThreshold = threshold => {
    setThresHold(threshold);
    const tempRankModeData = rankModeData;
    tempRankModeData.threshold = threshold;
    const tempEditaCategorySlottingData = editaCategorySlottingData;
    tempEditaCategorySlottingData[rankMode][index] = tempRankModeData;
    setEditaCategorySlottingData(tempEditaCategorySlottingData);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputGroup style={{ width: '40%', marginBottom: '0px' }}>
        <select
          value={operator}
          onChange={e => {
            handleOperator(e.target.value);
          }}
          className="form-control"
          style={{ height: 40, border: '1px solid rgb(227, 227, 227)' }}
          disabled={rankModeData.criteria_field && criteriaDiables.includes(rankModeData.criteria_field) ? true : false}
        >
          <option value="">Choose</option>
          <option value="is">is</option>
          <option value="contains">contains</option>
          <option value="between">between</option>
          <option value="lte">&le;</option>
          <option value="gte">&ge;</option>
          <option value="exists">exists</option>
        </select>
      </InputGroup>
      <InputGroup style={{ width: '100%', marginBottom: '0px' }}>
        <Input
          value={thresHold}
          style={{ height: 40, border: '1px solid rgb(227, 227, 227)' }}
          defaultValue=""
          placeholder="Value"
          type="text"
          disabled={rankModeData.criteria_field && criteriaDiables.includes(rankModeData.criteria_field) ? true : false}
          onChange={e => {
            handleThreshold(e.target.value);
          }}
        />
      </InputGroup>
    </div>
  );
};

export default ({ rankModeData, editaCategorySlottingData, rankMode, setEditaCategorySlottingData, index }) => {
  const [slots, setSlots] = useState('');

  useEffect(() => {
    if (rankModeData) setSlots(rankModeData.slots);
  }, [rankModeData]);

  const handleSlots = slot => {
    setSlots(slot);
    const tempRankModeData = rankModeData;
    tempRankModeData.slots = slot;
    const tempEditaCategorySlottingData = editaCategorySlottingData;
    tempEditaCategorySlottingData[rankMode][index] = tempRankModeData;
    setEditaCategorySlottingData(tempEditaCategorySlottingData);
  };
  //   useEffect(() => {
  //     const slots = rankModeData.slot;
  //     setSlots(slots);
  //   }, [rankModeData]);

  return (
    <>
      <div className="">
        <Card className="">
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'left', fontWeight: 'bold' }}>
                {rankModeData.criteria_field}
              </div>
            </div>
            <Form>
              <Row>
                <Col className="px-3" md="12">
                  <FormGroup>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline'
                      }}
                    >
                      <label>Threshold</label>
                    </div>

                    <ThresHoldRow
                      rankModeData={rankModeData}
                      editaCategorySlottingData={editaCategorySlottingData}
                      rankMode={rankMode}
                      setEditaCategorySlottingData={setEditaCategorySlottingData}
                      index={index}
                    />

                    <Label for="exampleSelect">Slots</Label>
                    <Input
                      type="number"
                      name="slots"
                      id="slots"
                      value={slots}
                      onChange={event => {
                        handleSlots(event.target.value);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
