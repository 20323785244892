import React from 'react';
import { CardTitle } from 'reactstrap';

export default () => {
  return (
    <CardTitle tag="h5">
      <a href="/admin/monitoring">Node Status</a> |{' '}
      <a href="/admin/monitoring-indicies">Index Stats</a>
    </CardTitle>
  );
};
