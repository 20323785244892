import axios from './axios';
import { SERVER_ENDPOINT } from 'constants/index';
import fileDownload from 'js-file-download';
const endpoint = `${SERVER_ENDPOINT}`;

export async function getProducts({
  id,
  ruleGroups,
  boostRules,
  deBoostRules,
  rankingRules,
  sizeBrokenness,
  rowSeqFilters,
  sortState,
  pinRules,
  ruleInfo,
  type,
  includeCollection,
  assignedFeatureRankingList,
  featureRankingProperties,
  page,
  pageSize = 120,
  env = window.localStorage.env || 'prod',
  storeId = window.localStorage.storeId || 1,
  showCollectionCount = false,
  storeFront,
  isDownload,
  newRankingRules
}) {
  const result = await axios.post(
    endpoint,
    {
      id,
      ruleGroups,
      boostRules,
      deBoostRules,
      sort: sortState,
      pinRules,
      rowSeqFilters,
      rankingRules,
      sizeBrokenness,
      page,
      pageSize,
      ruleInfo,
      type,
      includeCollection,
      assignedFeatureRankingList,
      featureRankingProperties,
      storeId,
      env,
      showCollectionCount,
      storeFront,
      isDownload,
      newRankingRules
    },
    {
      responseType: !isDownload ? undefined : 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: isDownload ? 'application/zip' : 'application/json'
      }
    }
  );
  if (isDownload) {
    fileDownload(result.data, `${ruleInfo.contextRuleId}.zip`);
    return;
  }

  return {
    hits: result.data.body.hits,
    took: result.data.body.took,
    aggregations: result.data.body.aggregations
  };
}
