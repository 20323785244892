import React, { useEffect } from 'react';
import Toast from 'components/Toast';

import { useStateValue } from 'context/RuleContext';
import Auth from './Auth';

export default ({ children }) => {
  const {
    rules: [ruleState, ruleDispatch]
  } = useStateValue();

  return (
    <>
      <Auth>{children}</Auth>
      <Toast
        className={ruleState ? 'show' : 'hide'}
        type={ruleState.toastType}
        timeOut={ruleState.toastTimeout}
        showBar={ruleState.showToast}
      >
        {ruleState.toastContent}
      </Toast>
    </>
  );
};
