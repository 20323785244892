import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import trim from 'lodash.trim';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as firebase from 'firebase/app';
import map from 'lodash.map';
import 'firebase/auth';

// reactstrap components
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Row, Modal } from 'reactstrap';
// reactstrap components
// core components
import Product from 'components/Product/index.jsx';
import PinnedItems from './PinnedItems.jsx';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import SearchRules from 'views/Expression/SearchRules.jsx';
import BoostRules from 'views/Expression/BoostRules.jsx';
import RuleInfo from 'views/Expression/RuleInfo.jsx';
import Sizebrokenness from 'views/Expression/Sizebrokenness.jsx';
import StoreSelection from 'views/ProductNavigation/StoreSelection';

import { getProducts } from 'variables/products';
import { getRules, saveRules, saveFlashSaleInfo } from 'variables/rules';
import { useStateValue } from '../context/RuleContext';
import slugify from 'utils/slugify';

import update from 'immutability-helper';
import { ACTION_SHOW_TOAST, showToast } from './Expression/actions.js';
import NewRanking from './Expression/RankingV2.jsx';
import StorefrontSettings from './Expression/StorefrontSettings.jsx';
import ImportCSV from './Collections/ImportCSV.jsx';
import CustomizeThisView from './Expression/CustomizeThisView.jsx';
import Activities from './Activities/index.jsx';
import Metadata from './Metadata.jsx';
import Banner from './Banner.jsx';
import DuplicateLinks from './Expression/DuplicateLinks.js';
import { getCurrentUser } from '../utils/index.js';

const pageSize = 120;
const Category = ({
  match: {
    params: { id, type = 'curated' }
  }
}) => {
  let subType;
  if (window.location.search.indexOf('flashsale') > -1) {
    subType = 'flashsale';
  } else if (window.location.search.indexOf('curated_search') > -1) {
    subType = 'curated_search';
  }
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [pinBySKU, showPinBySKU] = useState(false);
  const [rowSeqFilters, setRowSeqFilters] = useState({});
  const [brandNames, setBrandNames] = useState([]);
  const [categoryId, setCategoryId] = useState(343);
  const [ruleGroups, setRuleGroups] = useState();
  const [ruleGroupsModal, setRuleGroupsModal] = useState();
  const [totalInfo, setProductTotal] = useState({});
  const [sortState, setSortState] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [ruleInfo, setRuleInfo] = useState({});
  const [updatedBy, setRuleUpdatedBy] = useState({});
  const [timeTook, setProductTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [listingError, setListingError] = useState('');
  const [pinRules, setPinRules] = useState([]);
  const [collectionInfo, setCollectionFile] = useState({});
  const [pinRulesAvailable, setPinRulesAvailable] = useState([]);
  const [filter, setFilter] = useState('')
  const [spotlight,setSpotlightData] = useState([])

  const [isSaving, setSaving] = useState(false);
  const [boostRules, setBoostRules] = useState();
  const [deBoostRules, setDeboostRules] = useState();
  const [rankingRules, setRankingRules] = useState();
  const [assignedList, setAssignedList] = useState([]);
  const [featureRankingProperties, setFeatureRankingProperties] = useState({});

  const [contextRuleIdEdited, setContextRuleIdEdited] = useState(!id);
  const [dustbins, setDustbins] = useState([{ accepts: ['product'], lastDroppedItem: [] }]);
  const [pinnedProductsAddedFlag, setPinnedProductsAddedFlag] = useState(false);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  const [newRankingRules, setNewRankingRules] = useState();

  const [sizeBrokenness, setSizeBrokenness] = useState({
    sizeBrokenessValue: '',
    sizeBrokenessField: 'size',
    enabled: false
  });
  const [storeFront, setStoreFront] = useState({
    categoryLevel: 'level3'
  });

  const [daysRangeDropDownOpen, setDaysRangeDropDownOpen] = useState(false);
  const [daysRange, setDaysRange] = useState(Number(window.localStorage.metricsDaysDange) || 7);
  const [links, setLinks] = useState({});

  const toggle = () => setDaysRangeDropDownOpen(prevState => !prevState);

  const history = useHistory();
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const {
    rules: [ruleState, ruleDispatch],
    configData: [configState, configDispatch]
  } = useStateValue();

  const handleAddNewRule = (deboost = false) => {
    onPageChange({ selected: 0 });
    if (deboost) {
      setDeboostRules([...(deBoostRules || []), { id: new Date().getTime() }]);
    } else {
      setBoostRules([...(boostRules || []), { id: new Date().getTime() }]);
    }
  };
  const handleAddNewRanking = () => {
    onPageChange({ selected: 0 });
    setRankingRules([...rankingRules, { id: new Date().getTime() }]);
  };

  const handlePinRules = (pins = []) => {
    setPinRules([...pinRules, ...pins]);
  };

  const handleBoostRuleTextChange = ({ attribute = '', operator = '', value = '', type = '', id }) => {
    const rules = boostRules;
    onPageChange({ selected: 0 });
    const selectedRuleIndex = rules.findIndex(rule => rule.id === id);
    const selectedRule = {
      ...rules[selectedRuleIndex]
    };
    if (type === 'attribute') {
      selectedRule.attribute = attribute;
      selectedRule.value = '';
    }
    if (type === 'operator') {
      selectedRule.operator = operator;
      // if (selectedRule.attribute === 'created_at') {
      //   selectedRule.format = 'date';
      // }
    }
    if (type === 'value') {
      selectedRule.value = value;
    }
    const newRules = [...rules];
    newRules[selectedRuleIndex] = selectedRule;
    setBoostRules(newRules);
  };

  const handleDeBoostRuleTextChange = ({ attribute = '', operator = '', value = '', type = '', id }) => {
    const rules = deBoostRules;
    onPageChange({ selected: 0 });
    const selectedRuleIndex = rules.findIndex(rule => rule.id === id);
    const selectedRule = {
      ...rules[selectedRuleIndex]
    };
    if (type === 'attribute') {
      selectedRule.attribute = attribute;
      selectedRule.value = '';
    }
    if (type === 'operator') {
      selectedRule.operator = operator;
    }
    if (type === 'value') {
      selectedRule.value = value;
    }
    const newRules = [...rules];
    newRules[selectedRuleIndex] = selectedRule;
    setDeboostRules(newRules);
  };

  const handleRankingChange = ({ attribute = '', order = '', type = '', id }) => {
    onPageChange({ selected: 0 });
    const selectedRuleIndex = rankingRules.findIndex(rule => rule.id === id);
    const selectedRule = {
      ...rankingRules[selectedRuleIndex]
    };
    if (type == 'attribute') {
      selectedRule.attribute = attribute;
    }
    if (type == 'order') {
      selectedRule.order = order;
    }

    const newRules = [...rankingRules];
    newRules[selectedRuleIndex] = selectedRule;
    setRankingRules(newRules);
  };

  const handleNewRankChange = ({ name = '', value = '' }) => {
    onPageChange({ selected: 0 });
    setNewRankingRules({ ...newRankingRules, [name]: value, ['order']: 'asc' });
  };

  const handleBoostRuleDelete = (id, isDemote = false) => {
    onPageChange({ selected: 0 });
    const rules = isDemote ? deBoostRules : boostRules;
    const selectedRuleIndex = rules.findIndex(rule => rule.id === id);
    const newRules = [...rules];
    newRules.splice(selectedRuleIndex, 1);
    if (isDemote) {
      setDeboostRules(newRules);
    } else {
      setBoostRules(newRules);
    }
  };

  const handleRankingDelete = id => {
    onPageChange({ selected: 0 });
    const selectedRuleIndex = rankingRules.findIndex(rule => rule.id === id);
    const newRules = [...rankingRules];
    newRules.splice(selectedRuleIndex, 1);
    setRankingRules(newRules);
  };

  const handleSortChange = event => {
    setSortState(event.target.value);
  };
  const onRuleGroupChange = ruleGroups => {
    setPinnedProductsAddedFlag(false);
    onPageChange({ selected: 0 });
    setRuleGroups(ruleGroups);
  };

  const onRuleInfoChange = ({
    mainCategory = '',
    ruleName = '',
    name = '',
    contextRuleId = '',
    updateContextId = false,
    flashSaleInfo
  }) => {
    if (contextRuleId) {
      setRuleInfo({
        ...ruleInfo,
        contextRuleId: slugify(contextRuleId)
      });
      setContextRuleIdEdited(true);
    } else if (flashSaleInfo) {
      setRuleInfo({
        ...ruleInfo,

        flashSaleInfo: { ...(ruleInfo.flashSaleInfo || {}), ...flashSaleInfo, context: [] }
      });
    } else if (mainCategory) {
      setRuleInfo({
        ...ruleInfo,
        mainCategory
      });
    } else {
      setContextRuleIdEdited(false);
      setRuleInfo({
        ...ruleInfo,
        [name]: ruleName,
        contextRuleId:
          id && ruleInfo.contextRuleId && !contextRuleIdEdited
            ? ruleInfo.contextRuleId
            : (name === 'ruleName' && slugify(ruleName.toLowerCase())) || ruleInfo.contextRuleId
      });
    }
  };

  useEffect(() => {
    fetchProducts({
      ruleGroups,
      boostRules,
      deBoostRules,
      rowSeqFilters,
      sortState,
      pinRules,
      rankingRules,
      sizeBrokenness
    });
    setSpotlightData(ruleState?.ruleDetail?.spotlight_property?.map(product => product.product_id));
  }, [
    ruleGroups,
    boostRules,
    deBoostRules,
    sortState,
    rowSeqFilters,
    pinRules,
    rankingRules,
    page,
    sizeBrokenness,
    ruleState.storeId,
    ruleInfo.mainCategory,
    ruleState.env,
    assignedList,
    featureRankingProperties
  ]);

  const fetchProducts = async ({ ruleGroups, isDownload }) => {
    window.clearInterval(window.timer);
    window.timer = setTimeout(async () => {
      if (ruleGroups) {
        try {
          setListingError('');
          setLoading(true);
          let tmpRuleGroups = ruleGroups;
          if (type == 'collection') {
            tmpRuleGroups = {};
          }
          const productResult = await getProducts({
            id,
            ruleGroups: tmpRuleGroups,
            boostRules,
            deBoostRules,
            pinRules,
            sortState,
            rankingRules,
            rowSeqFilters,
            sizeBrokenness,
            assignedFeatureRankingList: assignedList,
            featureRankingProperties,
            page: !isDownload ? page : 0,
            type,
            ruleInfo,
            pageSize: !isDownload ? pageSize : 1000,
            env: ruleState.env,
            storeId: ruleState.storeId,
            storeFront,
            isDownload,
            newRankingRules
          });
          if (isDownload) {
            setLoading(false);
            setDownloadLoading(false);
            return false;
          }
          const pinProducts = [];
          const pinProductsAvailable = [];
          const otherProducts = [];
          const skus = pinRules.map(pin => pin.value.split(':')[1]);

          if (productResult && productResult.hits)
            productResult.hits.hits.forEach(hit => {
              if (skus.indexOf(hit._source.sku && hit._source.sku[0]) > -1) {
                pinProducts.push({ type: 'product', product: hit });
                pinProductsAvailable.push({
                  value: `sku:${hit._source.sku[0]}`
                });
              } else {
                otherProducts.push(hit);
              }
            });

          setProducts(otherProducts);
          if (!pinnedProductsAddedFlag) {
            setDustbins([{ accepts: ['product'], lastDroppedItem: pinProducts }]);
            setPinnedProductsAddedFlag(true);

            setPinRulesAvailable(pinProductsAvailable);
          }
          setProductTotal(productResult.hits.total);
          setProductTime(productResult.took);

          //Update brand_name
          setLoading(false);
          console.log(updatedBy);
          const { email, timestamp } = updatedBy;
          const msg =
            type === 'collection' && timestamp + 60000 > new Date().getTime()
              ? 'Updating Collection.. it may take 1 minute'
              : ``;
          window.clearInterval(window.collectionInterval);
          if (type === 'collection' && timestamp + 180000 > new Date().getTime()) {
            window.collectionInterval = setInterval(() => {
              fetchProducts({ ruleGroups });
            }, 30000);
          }
          setListingError(msg);
        } catch (e) {
          setLoading(false);
          const { email, timestamp } = updatedBy;
          const msg =
            type === 'collection' && timestamp + 60000 > new Date().getTime()
              ? 'Updating Collection.. it may take 1 minute'
              : `Error: Something is wrong, please check the form`;
          setListingError(msg);
          window.clearInterval(window.collectionInterval);
          window.collectionInterval = setInterval(() => {
            fetchProducts({ ruleGroups });
          }, 30000);

          console.error(e);
        }
      }
    }, 500);
  };

  const [butnDisable, setButnDisable] = useState(type !== 'collection');
  useEffect(() => {
    if (type !== 'collection' && products && products.length > 0 && listingError === '') {
      setButnDisable(false);
    } else if (type !== 'collection') {
      setButnDisable(true);
    }
  }, [products, listingError]);

  const handleSaveRules = async () => {
    // console.log('debug', ruleInfo?.flashSaleInfo);
    if (type === 'collection' && subType === 'flashsale' && !ruleInfo?.flashSaleInfo?.flashSaleId) {
      showToast(
        {
          type: ACTION_SHOW_TOAST,
          content: `Please enter flash sale id!`,
          timeOut: 5000
        },
        ruleDispatch
      );
      return;
    } else if (
      type === 'collection' &&
      subType === 'flashsale' &&
      !ruleInfo?.flashSaleInfo?.countriesApplicable?.length
    ) {
      showToast(
        {
          type: ACTION_SHOW_TOAST,
          content: `Please select at least one country applicable!`,
          timeOut: 5000
        },
        ruleDispatch
      );
      return;
    }

    // return;

    const userDetails = getCurrentUser();
    if (
      ruleInfo.ruleName &&
      String(ruleInfo.ruleName).replace(/\s+/g, ' ').trim() &&
      ruleInfo.ruleNameArabic &&
      String(ruleInfo.ruleNameArabic).replace(/\s+/g, ' ').trim()
    ) {
      const skus = pinRules.map(pin => pin.value.split(':')[1]);
      const availablePinRules = [];

      dustbins[0].lastDroppedItem.map(hit => {
        if (skus.indexOf(hit.product._source.sku && hit.product._source.sku[0]) > -1) {
          availablePinRules.push({
            value: `sku:${hit.product._source.sku[0]}`
          });
        }
      });

      setSaving(true);

      const region = localStorage.getItem('LOCATION');

      if (subType === 'curated_search') {
        type = 'curated_search';
        ruleInfo.ruleName = String(ruleInfo.ruleName).split(',') || '';
        ruleInfo.ruleNameArabic = String(ruleInfo.ruleNameArabic).split(',') || '';
      }
      if (ruleInfo && ruleInfo?.searchKeywordEn) {
        ruleInfo.searchKeywordEn =
          ruleInfo?.searchKeywordEn && Array.isArray(ruleInfo?.searchKeywordEn)
            ? ruleInfo?.searchKeywordEn
            : ruleInfo?.searchKeywordEn.split(',').map(item => item.trim()) || '';
      }
      if (ruleInfo && ruleInfo?.searchKeywordAr) {
        ruleInfo.searchKeywordAr =
          ruleInfo?.searchKeywordAr && Array.isArray(ruleInfo?.searchKeywordAr)
            ? ruleInfo?.searchKeywordAr
            : ruleInfo?.searchKeywordAr.split(/،\s*/).map(item => item.trim()) || '';
      }

      if (ruleInfo?.flashSaleInfo) {
        const type = 'collection';
        const contextId = ruleInfo?.contextRuleId || '';
        const flashSaleWidgetData = storeFront && storeFront.widgetData ? storeFront.widgetData : {};
        const flashSaleDataGcc = {
          ksa: {
            ...ruleInfo.flashSaleInfo,
            flashSaleWidgetData: { 1: flashSaleWidgetData[1], 3: flashSaleWidgetData[3] }
          },
          uae: {
            ...ruleInfo.flashSaleInfo,
            flashSaleWidgetData: { 7: flashSaleWidgetData[7], 11: flashSaleWidgetData[11] }
          },
          kwt: {
            ...ruleInfo.flashSaleInfo,
            flashSaleWidgetData: { 12: flashSaleWidgetData[12], 13: flashSaleWidgetData[13] }
          },
          bhr: {
            ...ruleInfo.flashSaleInfo,
            flashSaleWidgetData: { 15: flashSaleWidgetData[15], 17: flashSaleWidgetData[17] }
          },
          qat: {
            ...ruleInfo.flashSaleInfo,
            flashSaleWidgetData: { 19: flashSaleWidgetData[19], 21: flashSaleWidgetData[21] }
          }
        };
        const flashSaleDataIn = {
          ind: ruleInfo?.flashSaleInfo
        };
        const flashSaleData = region === 'IN' ? flashSaleDataIn : flashSaleDataGcc;

        const flashSaleInfo = {
          [`${contextId}`]: flashSaleData
        };
        const result = await saveFlashSaleInfo({ flashSaleInfo, type }, ruleDispatch);
      }
      const saveResult = await saveRules({
        boostRules,
        deBoostRules,
        ruleGroups,
        ruleGroupsModal,
        pinRules: availablePinRules,
        rankingRules,
        newRankingRules,
        rowSeqFilters,
        sizeBrokenness,
        storeFront,
        ruleInfo,
        type,
        collectionInfo,
        assignedFeatureRankingList: assignedList,
        featureRankingProperties,
        updatedBy: {
          email: userDetails?.email,
          timestamp: new Date().getTime()
        },
        id
      });

      const linkedToBeCloned = Object.keys(links)
        ?.filter(st => links[st].enabled === true)
        .map(st => links[st]);
      if (linkedToBeCloned && linkedToBeCloned.length > 0) {
        await linkedToBeCloned.forEach(async link => {
          const ruleInfoClone = { ...ruleInfo };
          ruleInfoClone.contextRuleId = link.url;
          await saveRules({
            boostRules,
            deBoostRules,
            ruleGroups,
            ruleGroupsModal,
            pinRules: availablePinRules,
            rankingRules,
            newRankingRules,
            rowSeqFilters,
            sizeBrokenness,
            storeFront,
            ruleInfo: ruleInfoClone,
            type,
            collectionInfo,
            assignedFeatureRankingList: assignedList,
            featureRankingProperties,
            updatedBy: {
              email: userDetails?.email,
              timestamp: new Date().getTime()
            }
          });
        });
      }

      setSaving(false);

      setCollectionFile({});

      let successMsg = 'Rule Saved Successfully';
      if (saveResult.data.statusCode === 205) {
        successMsg = saveResult.data.message;
      }
      showToast(
        {
          type: ACTION_SHOW_TOAST,
          content: `${successMsg}`,
          timeOut: 5000
        },
        ruleDispatch
      );

      setTimeout(() => {
        if (type === 'collection') {
          if (id) {
            fetchProducts({ ruleGroups });
            setTimeout(() => {
              window.clearInterval(window.collectionInterval);
            }, 60000);

            window.collectionInterval = setInterval(() => {
              fetchProducts({ ruleGroups });
            }, 30000);
            const msg = type === 'collection' ? 'Updating Collection.. it may take 1 minute' : ``;
            setListingError(msg);
            if (type === 'collection' && subType === 'flashsale' && saveResult.data.statusCode !== 205) {
              history.push(`/admin/collections/flashsale`);
            } else if (
              type === 'curated_search' &&
              subType === 'curated_search' &&
              saveResult.data.statusCode !== 205
            ) {
              history.push(`/admin/collections/curated_search`);
            } else {
              window.location.reload();
            }
          } else {
            if (saveResult.data.statusCode !== 205) {
              // history.push(`/admin/collections/flashsale`);
              history.push(`/admin/categories/collection/${saveResult.data.body._id}?subType=${subType}`);
            }
          }
          // history.push('/admin/listing');
        } else if (type === 'curated_search' && subType === 'curated_search' && saveResult.data.statusCode !== 205) {
          history.push(`/admin/collections/curated_search`);
        } else {
          history.push('/admin/listing');
        }
      }, 1000);
    } else {
      alert(`Please enter Both ${title} Names`);
    }
    // window.location.reload();
  };

  const handleDrop = (index, item) => {
    const { name } = item;
    setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
    const newDustbins = [...dustbins];
    newDustbins[index].lastDroppedItem.push({ ...item });
    setDustbins(newDustbins);
    handlePinRules([{ value: `sku:${item.product._source.sku[0]}` }]);

    // [droppedBoxNames, dustbins]
  };

  const handlePinProducts = () => {
    setPinnedProductsAddedFlag(false);
    onPageChange({ selected: 0 });
    let skus = searchQuery; // .replace(/\s/g, '');
    skus = skus.split('\n');
    const values = [];
    skus.map(sku => {
      values.push({ value: `sku:${trim(sku)}` });
    });
    handlePinRules(values);
    setTimeout(() => {
      setSearchQuery('');
    }, 1000);
  };

  const onDeletePinnedProducts = item => {
    const index = pinRules.findIndex(p => p.value === `sku:${item.product._source.sku[0]}`);
    const newPinRules = [...pinRules];
    newPinRules.splice(index, 1);
    setPinRules(newPinRules);
    const dropAreaProducts = [...dustbins[0].lastDroppedItem];
    dropAreaProducts.splice(index, 1);
    setDustbins([{ accepts: ['product'], lastDroppedItem: dropAreaProducts }]);
  };

  const onPageChange = page => {
    setPage(page.selected);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (ruleState.ruleDetail) {
      setRuleGroups(ruleState.ruleDetail.ruleGroups);
      setRuleGroupsModal(ruleState.ruleDetail.ruleGroupsModal || ruleState.ruleDetail.ruleGroups);
      setRowSeqFilters(ruleState.ruleDetail.rowSeqFilters || {});
      setBoostRules(ruleState.ruleDetail.boostRules);
      setDeboostRules(ruleState.ruleDetail.deBoostRules);
      setRankingRules(ruleState.ruleDetail.rankingRules);
      setNewRankingRules(ruleState.ruleDetail.newRankingRules);
      setRuleInfo(ruleState.ruleDetail.ruleInfo || {});
      setRuleUpdatedBy(ruleState.ruleDetail.updatedBy || {});
      setAssignedList(ruleState.ruleDetail.assignedFeatureRankingList || []);
      setFeatureRankingProperties(ruleState.ruleDetail.featureRankingProperties || {});
      setSizeBrokenness(
        ruleState.ruleDetail.sizeBrokenness || {
          sizeBrokenessValue: '',
          sizeBrokenessField: 'size',
          noOfOtherSizes: ''
          // sizeBrokenessField2: 'size_lingerie'
        }
      );
      setStoreFront(
        ruleState.ruleDetail.storeFront || {
          categoryLevel: 'level3'
        }
      );
      if (ruleState.ruleDetail.pinRules) {
        setPinRules(ruleState.ruleDetail.pinRules);
      }
    }
  }, [ruleState.ruleDetail]);

  useEffect(() => {
    if (id) {
      getRules({ id }, ruleDispatch);
    } else {
      setRuleGroups([
        [
          {
            name: 'categoryIds',
            logical: 'contains',
            id: new Date().getTime(),
            type: 'must'
          }
        ]
      ]);
      setRuleGroupsModal([[{ logical: 'contains', id: new Date().getTime(), type: 'must' }]]);
      setBoostRules([]);
      setDeboostRules([]);
      if (type !== 'collection') {
        setRankingRules([
          {
            id: new Date().getTime() + 1,
            attribute: 'enrich.last30rev',
            order: 'desc'
          }
        ]);
      }
      const _rank = ruleState.sortByVMMetric ? 'def_rank' : 'bau_rank';
      setNewRankingRules({ rank: _rank, order: 'asc', isContextMultiCategory: false });
    }
  }, [id]);

  // useEffect(() => {
  //   ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined } });
  // }, []);

  const handleCollectionFile = values => {
    setCollectionFile({ ...collectionInfo, ...values });
  };
  const handleSortPinnedItems = items => {
    const pRules = items.map(item => ({
      value: `sku:${item.product._source.sku[0]}`
    }));
    // setTimeout(() => {
    setPinRules([...pRules]);
    // }, 0);
    setDustbins([{ accepts: ['product'], lastDroppedItem: items }]);
  };
  const handleRuleAdd = () => {
    ruleDispatch({ type: 'RULE_DETAIL', data: { ruleDetail: undefined }, isAddNew: true });
    history.replace(`/admin/categories`);
  };

  const handleBoostRuleSorting = rules => {
    onPageChange({ selected: 0 });
    setBoostRules(rules);
  };
  const handleDeBoostRuleSorting = rules => {
    onPageChange({ selected: 0 });
    setDeboostRules(rules);
  };
  const handleRankingSorting = rules => {
    onPageChange({ selected: 0 });
    setRankingRules(rules);
  };

  const handleSizeBrokenness = ({ name, value }) => {
    onPageChange({ selected: 0 });
    setSizeBrokenness({ ...sizeBrokenness, [name]: value });
  };
  const handleStorefront = ({ name, value, aggIndex }) => {
    if (name === 'fixedmixAggregations') {
      setStoreFront({ ...storeFront, [name]: value.map((val, index) => ({ ...val, order: index + 1 })) });
    } else {
      setStoreFront({ ...storeFront, [name]: value });
    }
  };

  const handleDownload = () => {
    setDownloadLoading(true);
    fetchProducts({ ruleGroups, isDownload: true });
  };
  const handleAddNewRowSequencing = () => {
    const newSeqFilter = {};
    const newIndex = Object.keys(rowSeqFilters).length;
    setRowSeqFilters({ ...rowSeqFilters, [newIndex]: newSeqFilter });
  };
  const setRowSeqFiltersWrap = (filters, key) => {
    console.log(key, '@@key@@@setRowSeqFiltersWrap@@@@', filters);
    rowSeqFilters[key] = filters;
    console.log(rowSeqFilters, '@rowSeqFilters@setRowSeqFiltersWrap');
    setRowSeqFilters({ ...rowSeqFilters });
    //TODO:
    //Handle new seg. If empty key append filters into array.
  };
  const itemsPerRow = Number(window.localStorage.itemsPerRow || 2);
  const handleMetricDaysInRange = days => {
    window.localStorage.metricsDaysDange = days;
    setDaysRange(days);
  };
  const { ruleList = [], widgets = {}, config: { flashSale = {} } = {}, sortByVMMetric } = ruleState;
  let typeName = type === 'collection' ? (subType === 'flashsale' && 'Flash Sale') || 'Collection' : 'Curated Category';
  let title =
    type === 'collection' ? (subType === 'flashsale' && '⚡️Flash Sale ⚡️') || 'Collections' : 'Curated Categories';
  if (subType === 'curated_search') {
    type = 'curated_search';
    typeName = 'curated_search';
    title = 'Curated Search';
  }
  try {
    if (id && isEmpty(ruleState.ruleDetail)) {
      return (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col md={12} xs={12} lg={12}>
                <Card
                  style={{
                    padding: 40
                  }}
                >
                  No List.
                  <Button color="info" className="btn-round" onClick={handleRuleAdd}>
                    Create New {title}
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={5} xs={12} lg={4}>
              <Card
                style={
                  {
                    // position: 'sticky', top: 10
                  }
                }
              >
                <RuleInfo
                  ruleId={id}
                  contextRuleId={ruleInfo.contextRuleId}
                  ruleGroups={ruleGroups}
                  mainCategory={ruleInfo.mainCategory}
                  flashSaleInfo={ruleInfo.flashSaleInfo}
                  ruleName={ruleInfo.ruleName}
                  ruleNameArabic={ruleInfo.ruleNameArabic}
                  searchKeywordEn={ruleInfo.searchKeywordEn}
                  searchKeywordAr={ruleInfo.searchKeywordAr}
                  onRuleGroupChange={onRuleGroupChange}
                  categoryId={categoryId}
                  onRuleInfoChange={onRuleInfoChange}
                  type={type}
                  typeName={typeName}
                  subType={subType}
                />
                <Tabs>
                  <TabList>
                    {type === 'collection' ? (
                      <>
                        <Tab>CSV</Tab>
                      </>
                    ) : (
                      <>
                        <Tab>Filter</Tab>
                      </>
                    )}
                    <Tab>Boost</Tab>
                    <Tab>Bury</Tab>
                    <Tab>Rank</Tab>
                    {/*<Tab>Row Sequencing</Tab>*/}
                    <Tab>Fragmentation</Tab>
                    <Tab>Settings</Tab>
                    <Tab>SEO Metadata</Tab>
                    <Tab>Banner</Tab>
                    <Tab>Duplicate Links</Tab>
                  </TabList>
                  {type === 'collection' ? (
                    <>
                      <TabPanel>
                        <ImportCSV
                          ruleLoadingDone={!loading}
                          currentTotalProducts={totalInfo?.value}
                          ruleId={id}
                          onCollectionFile={handleCollectionFile}
                          setRuleGroupsParent={setRuleGroupsModal}
                          setRuleGroupsDefault={setRuleGroups}
                          ruleGroupsParent={ruleGroupsModal}
                          ruleInfo={ruleInfo}
                          subType={subType}
                          env={ruleState.env}
                          storeId={ruleState.storeId}
                        />
                      </TabPanel>
                    </>
                  ) : (
                    <>
                      {' '}
                      <TabPanel>
                        {ruleGroups ? (
                          <SearchRules
                            ruleId={id}
                            contextRuleId={ruleInfo.contextRuleId}
                            ruleGroups={ruleGroups}
                            ruleName={ruleInfo.ruleName}
                            ruleNameArabic={ruleInfo.ruleNameArabic}
                            searchKeywordEn={ruleInfo.searchKeywordEn}
                            searchKeywordAr={ruleInfo.searchKeywordAr}
                            onRuleGroupChange={onRuleGroupChange}
                            categoryId={categoryId}
                            onRuleInfoChange={onRuleInfoChange}
                          />
                        ) : null}
                      </TabPanel>
                    </>
                  )}
                  <TabPanel>
                    <BoostRules
                      boostRules={boostRules}
                      setBoostRules={handleBoostRuleSorting}
                      handleAddNewRule={handleAddNewRule}
                      handleBoostRuleTextChange={handleBoostRuleTextChange}
                      handleBoostRuleDelete={handleBoostRuleDelete}
                      handleSaveRules={handleSaveRules}
                      deboost={false}
                      filterableAttributesList={ruleState.ruleDetail.filterableAttributesList}
                      featureRankingProperties={featureRankingProperties}
                    />
                  </TabPanel>
                  <TabPanel>
                    <BoostRules
                      boostRules={deBoostRules}
                      setBoostRules={handleDeBoostRuleSorting}
                      handleAddNewRule={handleAddNewRule}
                      handleBoostRuleTextChange={handleDeBoostRuleTextChange}
                      handleBoostRuleDelete={handleBoostRuleDelete}
                      handleSaveRules={handleSaveRules}
                      deboost={true}
                      filterableAttributesList={ruleState.ruleDetail.filterableAttributesList}
                      featureRankingProperties={featureRankingProperties}
                    />
                  </TabPanel>
                  <TabPanel>
                    {configState.data && configState.data.isNewRowSequency ? (
                      <NewRanking
                        newRankingRules={newRankingRules}
                        handleNewRankChange={handleNewRankChange}
                        handleSaveRules={handleSaveRules}
                        isVMMetricEnable={ruleState.sortByVMMetric}
                      />
                    ) : (
                      <></>
                    )}
                  </TabPanel>

                  <TabPanel>
                    <Sizebrokenness
                      handleSizeBrokenness={handleSizeBrokenness}
                      sizeBrokenness={sizeBrokenness}
                      maxFragmentation={ruleState.ruleDetail.maxFragmentation}
                    />
                  </TabPanel>
                  <TabPanel>
                    {' '}
                    <StorefrontSettings
                      handleStorefront={handleStorefront}
                      storeFront={storeFront}
                      filterableAttributesList={ruleState.ruleDetail.mergedfilterAttr}
                      fixedFilterableAttributesList={ruleState.ruleDetail.mergedFixcoreAttr}
                    />
                  </TabPanel>
                  <TabPanel>
                    {' '}
                    <Metadata handleStorefront={handleStorefront} storeFront={storeFront} />
                  </TabPanel>
                  <TabPanel>
                    {' '}
                    <Banner handleStorefront={handleStorefront} storeFront={storeFront} />
                  </TabPanel>

                  <TabPanel>
                    <DuplicateLinks ruleInfo={ruleInfo} links={links} setLinks={setLinks} />
                  </TabPanel>
                </Tabs>

                {/* <BoostRules
                  boostRules={demoteRules}
                  setBoostRules={handleBoostRuleSorting}
                  handleAddNewRule={handleAddNewRule}
                  handleBoostRuleTextChange={handleBoostRuleTextChange}
                  handleBoostRuleDelete={handleBoostRuleDelete}
                  handleSaveRules={handleSaveRules}
                  promote={false}
                /> */}

                <div className="px-3 text-right">
                  <Button outline color="default" className="btn-round no-border" onClick={() => history.goBack()}>
                    Cancel
                  </Button>
                  <Button
                    disabled={butnDisable || isSaving}
                    color="primary"
                    className="btn-round"
                    onClick={handleSaveRules}
                  >
                    {isSaving ? 'Saving. Please wait...' : 'SAVE & PUBLISH'}
                  </Button>
                </div>
              </Card>
              <Activities id={id} onDownload={handleDownload} downloadLoading={downloadLoading} />
            </Col>
            <Col md={7} xs={12} lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div style={{ display: 'inline-block' }}>
                      <ul
                        style={{
                          listStyle: 'none',
                          padding: 0,
                          display: 'inline'
                        }}
                      >
                        <StoreSelection />
                      </ul>{' '}
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {' '}
                      <span>
                        Products ({totalInfo.value} Items) <span className="fs-11">in {timeTook}ms</span>
                      </span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <DndProvider backend={Backend}>
                    <>
                      <div
                        className=""
                        style={{
                          position: 'sticky',
                          top: 10,
                          left: 0,
                          zIndex: 100,
                          maxHeight: 250,
                          overflowY: 'scroll',
                          background: 'rgb(238, 238, 238)',
                          boxShadow: '2px 4px 2px -2px #c9c9c9'
                        }}
                      >
                        {dustbins.map(({ accepts, lastDroppedItem }, index) => (
                          <PinnedItems
                            onDelete={onDeletePinnedProducts}
                            sortPinnedItems={handleSortPinnedItems}
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={item => handleDrop(index, item)}
                            key={index}
                            sizeBrokenness={sizeBrokenness}
                          />
                        ))}
                      </div>
                      <Row className="border-bottom mb-3 mt-3 pb-3">
                        <div className="col-6"></div>
                        <div className="col-6">
                          <div
                            className=""
                            style={{ textAlign: 'right', paddingRight: 10 }}
                            onClick={() => showPinBySKU(!pinBySKU)}
                          >
                            <span className="fs-12">Pin items to the top by SKU</span>
                            <span className="caret" />
                          </div>
                          {pinBySKU ? (
                            <FormGroup>
                              <Input
                                value={searchQuery}
                                onChange={event => setSearchQuery(event.target.value)}
                                defaultValue=""
                                placeholder="ADD by SKU(newline seperated)"
                                type="textarea"
                              />

                              <Button color="secondary" size="sm" onClick={handlePinProducts}>
                                Add
                              </Button>
                            </FormGroup>
                          ) : null}
                        </div>
                      </Row>
                    </>

                    <Row>
                      <Col>
                        <span className="c-pastel-red">
                          {loading ? 'Loading please wait...' : ''}
                          {listingError}
                        </span>
                      </Col>
                      <Col>
                        <ReactPaginate
                          forcePage={page}
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={Math.ceil(totalInfo.value / pageSize)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={onPageChange}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                          style={{ justifyContent: 'flex-end' }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}></Col>
                      <Col style={{ textAlign: 'right' }}>
                        <CustomizeThisView
                          handleMetricDaysInRange={handleMetricDaysInRange}
                          daysRangeDropDownOpen={daysRangeDropDownOpen}
                          toggle={toggle}
                          daysRange={daysRange}
                          setFilter={setFilter}
                        />
                      </Col>
                    </Row>
                    <Row>
                    {products.map((product, key) => {
                        return (
                          <Col xs={4} lg={itemsPerRow} key={key} style={(filter === "Spotlight") && (!spotlight?.includes(product._id)) ? {display:'none'}:{}} >
                            <Product
                              hasDrag={true}
                              product={product}
                              isDropped={isDropped}
                              type={type}
                              collectionInfo={collectionInfo}
                              contextRuleId={ruleInfo.contextRuleId}
                              daysRange={daysRange}
                              filter={filter}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <ReactPaginate
                      forcePage={page}
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={Math.ceil(totalInfo.value / pageSize)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={onPageChange}
                      containerClassName={'pagination '}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                    {/* </tbody>
                  </Table> */}
                  </DndProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    window.location.reload();
  }
};

export default Category;
