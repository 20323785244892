import * as firebase from 'firebase/app';
import 'firebase/firestore';

import axios from 'variables/axios';
import get from 'lodash.get';
import { SERVER_ENDPOINT } from 'constants/index';

export async function getPromoBadgesList(dispatch) {
  const result = await axios.get(`${SERVER_ENDPOINT}/get-promo-badges-list`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  dispatch({
    type: 'GET_PROMO_BADGES',
    data: {
      promoBadgesList: result.data.Value
    }
  });
}
export async function setPromoBadgesList({ badges }, dispatch) {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/set-promo-badges-list`,
    { badges },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  getPromoBadgesList(dispatch);
}
