/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getFilterAttributes,
  deleteFilterAttribute
} from 'views/FilterListing/actions';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';

import { tbody } from 'variables/general';
import { useStateValue } from 'context/RuleContext';

export default ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  // const [copied, setCopy] = useState(false);
  const {
    filterattributes: [filterattributeState, filterAttributeDispatch]
  } = useStateValue();
  useEffect(() => {
    getFilterAttributes({}, filterAttributeDispatch);
  }, []);
  useEffect(() => {
    if (id) {
      deleteFilterAttribute({ id }, filterAttributeDispatch, history);
    }
  }, []);
  const handleAddFilterAttribute = () => {
    filterAttributeDispatch({
      type: 'ADD_FILTERATTRIBUTE',
      data: { ruleDetail: undefined }
    });
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    history.push(`/admin/addfilterattribute`);
  };
  const handleEditFilterAttribute = fieldId => {
    filterAttributeDispatch({
      type: 'EDIT_FILTERATTRIBUTE',
      data: { ruleDetail: undefined }
    });
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    history.push(`/admin/editfilterattribute/${fieldId}`);
  };
  const handleDeleteFilterAttribute = fieldId => {
    filterAttributeDispatch({
      type: 'DELETE_FILTERATTRIBUTE',
      data: { ruleDetail: undefined }
    });
    window.__isReactDndBackendSetUp = false; // HACK FOR BLANK PAGE BUG
    history.push(`/admin/deletefilterattribute/${fieldId}`);
  };
  const { filterAttributesList = [] } = filterattributeState;
  localStorage.setItem('order', filterAttributesList.length);
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Filter Attributes{' '}
                  <Button
                    color="info"
                    size="sm"
                    className="btn-round"
                    onClick={handleAddFilterAttribute}
                    onKeyPress={handleAddFilterAttribute}
                  >
                    Add New
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Label</th>
                      <th>Value</th>
                      <th>Hint</th>
                      <th className="text-right">Edit</th>
                      <th className="text-right">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterAttributesList.map(filterfield => {
                      const {
                        fieldLabel,
                        fieldValue,
                        fieldHint,
                        id: filterAttributeId
                      } = filterfield;
                      return (
                        <tr key={filterAttributeId}>
                          <td style={{ width: 250 }} className="text-left">
                            {fieldLabel}
                          </td>

                          <td className="text-left">{fieldValue}</td>
                          <td className="text-left">{fieldHint}</td>
                          <td
                            style={{ color: '#4570b9', width: 50 }}
                            className="text-right"
                          >
                            <a
                              role="button"
                              href="javascript:void(0)"
                              onClick={() =>
                                handleEditFilterAttribute(filterAttributeId)
                              }
                              onKeyPress={() =>
                                handleEditFilterAttribute(filterAttributeId)
                              }
                            >
                              Edit
                            </a>
                          </td>
                          <td
                            style={{ color: 'red', width: 50 }}
                            className="text-right"
                          >
                            <a
                              role="button"
                              href="javascript:void(0)"
                              onClick={() =>
                                handleDeleteFilterAttribute(filterAttributeId)
                              }
                              onKeyPress={() =>
                                handleDeleteFilterAttribute(filterAttributeId)
                              }
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
