export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CATEGORY_SLOTTING': {
      const { getCategorySlotting = [] } = action.data;
      return {
        ...state,
        getCategorySlotting
      };
    }
    case 'GET_CATEGORY_SET': {
      const { getCategorySet } = action.data;
      return {
        ...state,
        getCategorySet: JSON.parse(getCategorySet)
      };
    }
    default: {
      return state;
    }
  }
};
