import React, { useEffect, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { Spinner, CustomInput, FormGroup, Card, CardBody, Button, Row, Col, Jumbotron, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import SearchRules from 'views/Expression/SearchRules.jsx';
import { getProducts } from 'variables/products';
import Product from 'components/Product/index.jsx';
import ReactPaginate from 'react-paginate';
import Alert from 'reactstrap/es/Alert';

export default ({
  ruleLoadingDone,
  ruleId,
  onCollectionFile = () => {},
  setRuleGroupsParent,
  setRuleGroupsDefault,
  ruleInfo,
  ruleGroupsParent,
  subType,
  env,
  storeId,
  currentTotalProducts
}) => {
  const [modal, setModal] = useState(false);
  const [ruleGroups, setRuleGroups] = useState();
  const [importViewCount, setImportViewCount] = useState(false);
  const [pinnedProductsAddedFlag, setPinnedProductsAddedFlag] = useState(false);
  const [page, setPage] = useState(0);
  const [includeCollection, setIncludeCollection] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [replaceExist, setReplaceExist] = useState(false);
  const [products, setProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [includeList, setIncludeList] = useState([]);
  const [excludeList, setExcludeList] = useState([]);
  const [availableCollectionProducts, setAvailableCollectionProducts] = useState(0);
  const [deselectAllExistingProducts, setDeselectAllExistingProducts] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [collectionCount, setCollectionCount] = useState(0);

  console.log(includeList, 'includeList@ImportCSV.jsx@excludeList', excludeList);
  const modalClasses = classNames({
    'modal-content': {
      height: '100%'
    },
    modal: {
      WebkitTransform: 'translate3d(0%, 0, 0)',
      msTransform: 'translate3d(0%, 0, 0)',
      oTransform: 'translate3d(0%, 0, 0)',
      transform: 'translate3d(0%, 0, 0)'
    },
    'modal-dialog': {
      transition: '1s',
      left: '0'
    }
  });

  const onPageChange = page => {
    setPage(page.selected);
    window.scrollTo(0, 0);
  };

  const onRuleGroupChange = ruleGroups => {
    setPinnedProductsAddedFlag(false);
    onPageChange({ selected: 0 });
    setRuleGroups(ruleGroups);
    // setRuleGroupsParent(ruleGroups);
  };

  const toggle = () => setModal(!modal);
  const handleOnDrop = file => {
    const json = file.map(d => {
      return d.data;
    });
    let isNotDecimal = false;
    if (json && json.length > 0 && json[0]) {
      const column = json[0];
      if (column && column[6] && column[6].includes('discount')) {
        (json || []).forEach((item, index) => {
          if (index > 0 && item?.length > 0) {
            if (item[6] && item[6] > 0 && !item[6].includes('.')) {
              isNotDecimal = true;
            }
            if (item[8] && item[6] > 0 && !item[8].includes('.')) {
              isNotDecimal = true;
            }
            if (item[10] && item[6] > 0 && !item[10].includes('.')) {
              isNotDecimal = true;
            }
            if (item[12] && item[6] > 0 && !item[12].includes('.')) {
              isNotDecimal = true;
            }
            if (item[14] && item[6] > 0 && !item[14].includes('.')) {
              isNotDecimal = true;
            }
          }
        });
      }
    }

    if (isNotDecimal) {
      window.alert('Please enter discount column in decimals');
      return;
    } else {
      onCollectionFile({ jsonData: json });
      console.log(json);
    }
  };
  const handleOtherInfo = ({ name, event }) => {
    // console.log(name, event.target.checked);
    onCollectionFile({ [name]: event.target.checked });
    if (name == 'replaceExisting') {
      setReplaceExist(event.target.checked);
    }
  };
  const handleOnRemoveFile = file => {
    console.log(file);
  };

  const handleOnError = file => {
    console.log(file);
  };

  useEffect(() => {
    if (ruleLoadingDone) {
      console.log(ruleGroupsParent, '@ruleGroupsParentruleGroupsParentruleGroupsParent');
      if (ruleGroupsParent && Object.keys(ruleGroupsParent).length) {
        setRuleGroups(ruleGroupsParent);
        return;
      }
      setRuleGroups([[{ logical: 'contains', id: new Date().getTime(), type: 'must' }]]);
    }
  }, [ruleLoadingDone]);

  useEffect(() => {
    fetchProducts({
      ruleGroups,
      ruleInfo
    });
  }, [ruleGroups, page, includeCollection]);

  // eslint-disable-next-line no-undef
  const fetchProducts = async ({ ruleGroups, ruleInfo }) => {
    window.clearInterval(window.timer);
    window.timer = setTimeout(async () => {
      if (ruleGroups) {
        try {
          setLoading(true);
          const productResult = await getProducts({
            ruleGroups,
            ruleInfo,
            page,
            includeCollection,
            env,
            storeId,
            showCollectionCount: true
          });
          const pinProducts = [];
          const pinProductsAvailable = [];
          const otherProducts = [];
          const skus = [].map(pin => pin.value.split(':')[1]);

          const tmpIncludedProducts = [];
          if (productResult && productResult.hits)
            productResult.hits.hits.forEach(hit => {
              if (skus.indexOf(hit._source.sku && hit._source.sku[0]) > -1) {
                pinProducts.push({ type: 'product', product: hit });
                pinProductsAvailable.push({
                  value: `sku:${hit._source.sku[0]}`
                });
              } else {
                otherProducts.push(hit);
              }

              if (
                hit._source?.enrich?.[`c_${ruleInfo.contextRuleId}`] ||
                hit._source?.[`c_${ruleInfo.contextRuleId}`]
              ) {
                tmpIncludedProducts.push(hit._source.sku[0]);
              }
            });

          setCollectionCount(productResult?.aggregations?.collectionCountWrap?.collectionCount?.value || 0);
          setAvailableCollectionProducts(productResult?.aggregations?.collectionCountWrap?.collectionCount?.value || 0);
          setProducts(otherProducts);

          //Added to clear any selections.
          //TODO: To be confirmed once with Product.

          //Update included list.
          // setIncludeList(tmpIncludedProducts);

          setProductTotal(productResult.hits.total.value || 0);

          //Update brand_name
          setLoading(false);

          window.clearInterval(window.collectionInterval);

          window.collectionInterval = setInterval(() => {
            fetchProducts({ ruleGroups, ruleInfo });
          }, 30000);
        } catch (e) {
          setLoading(false);
          // const { email, timestamp } = updatedBy;

          window.clearInterval(window.collectionInterval);
          window.collectionInterval = setInterval(() => {
            fetchProducts({ ruleGroups, ruleInfo });
          }, 30000);

          console.error(e);
        }
      }
    }, 500);
  };

  const handleIncludeList = list => {
    // if (selectAllRef)
    //   selectAllRef.indeterminate = list.length && list.length < productTotal;
    setIncludeList(list);
  };

  const handleExcludeList = list => {
    setExcludeList(list);
  };
  //Check all
  const unCheckAll = () => {
    setSelectAll(false);
    setIncludeList([]);
    setProducts([...products]);
  };

  const getImportCount = () => {
    console.log(
      `selectAll-${selectAll}, includeList.length( ${includeList.length}, excludeList.length(${excludeList.length}, deselectAllExistingProducts${deselectAllExistingProducts}`
    );
    return selectAll
      ? productTotal - excludeList.length
      : (!deselectAllExistingProducts ? availableCollectionProducts : 0) + includeList.length - excludeList.length;
  };

  //Uncheck all
  const checkAll = () => {
    if (selectAll && availableCollectionProducts > 0) {
      if (
        !window.confirm(
          `${availableCollectionProducts} product(s) already saved in the collection will be de-selected. Do you want to continue DESELECT ALL?`
        )
      ) {
        return false;
      }
      setDeselectAllExistingProducts(true);
    }
    setSelectAll(!selectAll);
    handleIncludeList([]);
    handleExcludeList([]);

    // setProducts([...products]);
  };

  //Import products from filter modal to collection list.
  const importProducts = async () => {
    setImportViewCount(true);
    let importCount = getImportCount();
    if (!importCount) {
      window.alert('Please select al-least one product to continue');
      return;
    }
    if (
      !window.confirm(
        `Are you sure you want to import ${importCount} products. You'll also need to click 'Save & Publish' to apply the changes.`
      )
    ) {
      console.log('No action performed.');
      return;
    }

    onCollectionFile({
      selectAll,
      includeList,
      excludeList,
      includeProductsFromSavedCollection: includeCollection,
      replaceExisting: replaceExist,
      deselectAllExistingProducts,
      isFilterAndImport: true,
      filterImportProductCount: importCount
    });
    setRuleGroupsParent(ruleGroups);
    setRuleGroupsDefault([[{ logical: 'contains', id: new Date().getTime(), type: 'must' }]]);
    toggle();
  };
  const importCount = getImportCount();

  return (
    <Card>
      <CardBody>
        <FormGroup>
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            noDrag
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
            style={{ width: '100%' }}
          >
            <span>Click to upload.</span>
          </CSVReader>
          {subType === 'flashsale' ? (
            <a href="/sample-flashsale.csv">Download Sample CSV</a>
          ) : (
            <a href="/sample-collection.csv">Download Sample CSV</a>
          )}
          <br />
          <br />
          <CustomInput
            type="checkbox"
            id="replaceExisting"
            name="replaceExisting"
            label={'Replace existing'}
            onChange={event => handleOtherInfo({ name: 'replaceExisting', event })}
            // invalid={invalidFile}
          />
          {subType === 'flashsale' ? (
            <CustomInput
              type="checkbox"
              id="resetSoldQty"
              name="resetSoldQty"
              label={'Reset Sold Quantity'}
              onChange={event => handleOtherInfo({ name: 'resetSoldQty', event })}
              // invalid={invalidFile}
            />
          ) : null}
        </FormGroup>
      </CardBody>
      <hr />
      <CardBody>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px'
          }}
        >
          <div>
            {subType != 'flashsale' ? (
              <Button
                size="sm"
                outline
                color="info"
                className="btn-round"
                onClick={toggle}
                style={{
                  borderRadius: '4px'
                }}
              >
                Filter &amp; Import
              </Button>
            ) : null}
          </div>
          {importCount && importViewCount ? (
            <Alert color="primary" size={'sm'}>
              <>{getImportCount()} product(s) selected for saving</>
            </Alert>
          ) : null}
          {modal ? (
            <Modal
              isOpen={modal}
              toggle={toggle}
              style={{
                maxWidth: '90%',
                height: '100vh',
                left: '0',
                margin: '0'
              }}
              className={`${modalClasses} no-border`}
              contentClassName={'no-border'}
            >
              <ModalHeader toggle={toggle}>Filter & Import</ModalHeader>
              <ModalBody style={{ marginBottom: 30 }}>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <SearchRules
                      availableCollectionProducts={availableCollectionProducts}
                      onRuleGroupChange={onRuleGroupChange}
                      ruleGroups={ruleGroups}
                      selectAll={selectAll}
                      setIncludeCollection={setIncludeCollection}
                      includeCollection={includeCollection}
                      ruleInfo={ruleInfo}
                      importCount={currentTotalProducts}
                      onRuleInfoChange={v => {
                        console.log(v, '@onRuleInfoChange');
                      }}
                    />
                  </Col>
                  <Col lg={8} md={6} sm={12}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {/*<Button color="secondary" onClick={checkAll}>Deselect All</Button>*/}
                        {/*<Button color="secondary" onClick={checkAll}>Select All</Button>*/}
                        <CustomInput
                          bsSize="lg"
                          type="switch"
                          id="exampleCustomSwitch"
                          className={'form-switch-lg'}
                          name="customSwitch"
                          label="Select all Products"
                          onClick={checkAll}
                        />

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {/*<CustomInput*/}
                          {/*    type="checkbox"*/}
                          {/*    id="replaceExisting2"*/}
                          {/*    name="replaceExisting2"*/}
                          {/*    label={'Replace existing'}*/}
                          {/*    onChange={event =>*/}
                          {/*        handleOtherInfo({ name: 'replaceExisting', event })*/}
                          {/*    }*/}
                          {/*/>*/}
                          {loading ? <Spinner color="dark" /> : ''}
                          {/* <Button
                            color="primary"
                            style={{ marginLeft: '10px' }}
                            onClick={importProducts}
                          >
                            Select & Proceed
                          </Button>{' '} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {!productTotal ? (
                        <div>
                          <Container fluid>
                            <h1 className="display-3"></h1>
                            <p className="lead">No Product(s) found. Set the filters to show products</p>
                          </Container>
                        </div>
                      ) : null}
                      {products.map((product, key) => {
                        return (
                          <div key={product._source.sku[0]} className="col col-2">
                            <Product
                              setIncludeList={handleIncludeList}
                              setExcludeList={handleExcludeList}
                              includeList={includeList}
                              excludeList={excludeList}
                              selectAll={selectAll}
                              showCheck={true}
                              contextRuleId={ruleInfo.contextRuleId}
                              key={product._source.sku[0]}
                              product={product}
                              deselectAllExistingProducts={deselectAllExistingProducts}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter
                style={{
                  position: 'fixed',
                  bottom: 0,
                  background: 'inherit',
                  borderTop: '1px solid #eee',
                  width: '90%',
                  height: '60px'
                }}
              >
                {productTotal ? (
                  <ReactPaginate
                    forcePage={page}
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(productTotal / 60)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={onPageChange}
                    containerClassName={'pagination mr-10 align-start'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                ) : null}
                <div style={{ fontSize: '14px', fontWeight: '400' }}>
                  Total <b>{productTotal}</b> products{' '}
                  <b style={{ fontSize: '1.1em' }} className="c-sap-green">
                    (Selected - {getImportCount()})
                  </b>
                </div>
                <Button color="default" outline onClick={toggle}>
                  Close
                </Button>
                <Button color="primary" onClick={importProducts}>
                  Select &amp; Proceed
                </Button>{' '}
              </ModalFooter>
            </Modal>
          ) : null}
        </FormGroup>
      </CardBody>
    </Card>
  );
};
