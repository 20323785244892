import firebase from 'firebase';

const DEV = {
  apiKey: 'AIzaSyBXprkSsdWnA8Ks9HJ7XAjAvt9bcO_iAkQ',
  authDomain: 'stylishopdev.firebaseapp.com',
  projectId: 'stylishopdev',
  storageBucket: 'stylishopdev.appspot.com',
  messagingSenderId: '640935596576',
  appId: '1:640935596576:web:393a09830cb8b64e672245',
  measurementId: 'G-WDEYHW4RET'
};
const UAT = {
  apiKey: 'AIzaSyBYAfWIyGi3aR8BI4RLGSURQSiu_1O7FqY',
  authDomain: 'supplyqa01.firebaseapp.com',
  databaseURL: 'https://supplyqa01.firebaseio.com',
  projectId: 'supplyqa01',
  storageBucket: 'supplyqa01.appspot.com',
  messagingSenderId: '108359217635',
  appId: '1:108359217635:web:88d0c1917456b57f4070d6'
};
const PROD = {
  apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
  authDomain: 'stylishopprod.firebaseapp.com',
  projectId: 'stylishopprod',
  storageBucket: 'stylishopprod.appspot.com',
  messagingSenderId: '476895285940',
  appId: '1:476895285940:web:bbd4cd2f90a5d2ceba3de1',
  measurementId: 'G-RV808LM9M2'
};

const firebaseApp = process.env.CUROFY_APP_NODE_ENV === 'production' ? PROD : DEV;
const firebaseConfig = firebase.initializeApp(firebaseApp);
export default firebaseConfig;
