/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getIndiciesUsage, rescue } from 'views/Monitoring/actions';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';
import Header from 'views/Monitoring/Header';

import { useStateValue } from 'context/RuleContext';

export default ({
  match: {
    params: { id }
  }
}) => {
  const [nodeUsage, setNodeUsage] = useState({});
  const [backups, setBackupIndex] = useState({});
  const [explain, setExplain] = useState({});
  const {
    rules: [ruleState]
  } = useStateValue();

  useEffect(() => {
    getIndiciesUsage({ env: ruleState.env }).then(data => {
      setNodeUsage(data);
    });
    getIndiciesUsage({ env: ruleState.env, backups: true }).then(data => {
      console.log('backup data', data);
      const indiceMap = {};
      data.map(item => {
        indiceMap[item.index.replace('_backup', '')] = item;
      });
      setBackupIndex(indiceMap);
    });
  }, [ruleState.env]);

  const handleExplain = ({ index }) => {
    getIndiciesUsage({ env: ruleState.env, index, explain: true }).then(
      data => {
        setExplain(data);
        console.log(data);
        // setNodeUsage(data);
      }
    );
  };

  const handleRescue = ({ index, operation }) => {
    if (
      window.confirm(`Are you sure you want to do ${operation.toUpperCase()}`)
    ) {
      let method = '';
      if (operation === 'restore') {
        method = window.prompt(`How do you want to restore? delete/append?`);
      }
      rescue({
        env: ruleState.env,
        index,
        explain: true,
        operation,
        method
      }).then(data => {
        setExplain(data);
        console.log(data);
        // setNodeUsage(data);
      });
    }
  };

  // console.log('nodeUsage', nodeUsage);

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={8}>
            <Card>
              <CardHeader>
                <Header />
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: 10 }}></th>
                      <th>Index</th>
                      <th>Doc Count</th>
                      <th>Primary Shards</th>
                      <th>Total Size</th>
                      <th>Primary Shard Size</th>
                      <th>Replicas</th>
                      <th>Status</th>
                      <th>Backup</th>
                      <th>Restore</th>
                      <th>More Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nodeUsage &&
                      Object.keys(nodeUsage).map((nodeKey, id) => {
                        const node = nodeUsage[nodeKey];

                        return (
                          <tr>
                            <td>
                              <span
                                class="dot"
                                style={{ backgroundColor: node.health }}
                              ></span>{' '}
                            </td>
                            <td>&nbsp;{node.index}</td>
                            <td>{node['docs.count']}</td>
                            <td>{node.pri}</td>
                            <td>{node['store.size']}</td>
                            <td>{node['pri.store.size']}</td>
                            <td>{node['rep']}</td>
                            <td>{node.status}</td>
                            <td>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  handleRescue({
                                    index: node.index,
                                    operation: 'backup'
                                  })
                                }
                              >
                                Backup
                              </a>
                            </td>
                            <td>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  handleRescue({
                                    index: node.index,
                                    operation: 'restore'
                                  })
                                }
                              >
                                Restore
                                <br />
                                {backups[node.index] &&
                                  backups[node.index]['docs.count']}
                              </a>
                            </td>
                            <td>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  handleExplain({ index: node.index })
                                }
                              >
                                Explain
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col xs={4}>
            <Card style={{ backgroundColor: '#2c2c2c' }}>
              <CardHeader>
                <CardTitle style={{ color: '#ffffff' }}>
                  Console Output
                </CardTitle>
              </CardHeader>
              <CardBody>
                <pre style={{ color: '#ffffff' }}>
                  >{JSON.stringify(explain, null, 4)}
                </pre>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
