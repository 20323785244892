import { ACTION_TYPE_LIST_FEATURE_RANKING } from 'views/FeatureRanking/actions';
import { ACTION_SHOW_TOAST, ACTION_HIDE_TOAST } from './actions';

export const initialState = {
  env: window.localStorage.env,
  storeId: window.localStorage.storeId,
  visibleAttributes: JSON.parse(window.localStorage.selectedAttributes || '[]')
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RULES': {
      const { rules, config, sortByVMMetric } = action.data;
      return {
        ...state,
        ruleList: rules.hits,
        ruleListTotal: rules.total,
        ruleListLoading: false,
        config,
        sortByVMMetric
      };
    }
    case 'FETCH_RULES_LIST_LOADING': {
      return {
        ...state,
        ruleListLoading: true
      };
    }
    case 'RULE_DETAIL': {
      const { ruleDetail = {}, sortByVMMetric } = action.data;
      return {
        ...state,
        ruleDetail: { rankingRules: [], ...ruleDetail },
        sortByVMMetric: action.isAddNew ? state.sortByVMMetric : sortByVMMetric
      };
    }
    case 'SET_STORE': {
      return {
        ...state,
        storeId: action.data.storeId
      };
    }
    case 'SET_ENV': {
      return {
        ...state,
        env: action.data.env
      };
    }
    case 'STRAPI_IMAGES': {
      const { strapiImages = {}, widgets = {} } = action.data;
      return {
        ...state,
        strapiImages,
        widgets
      };
    }
    case ACTION_SHOW_TOAST: {
      const { content, type, timeOut } = action.data;
      return {
        ...state,
        showToast: true,
        toastContent: content,
        toastType: type,
        toastTimeout: timeOut
      };
    }
    case ACTION_HIDE_TOAST: {
      return {
        ...state,
        showToast: false,
        toastContent: undefined,
        toastType: undefined,
        toastTimeout: undefined
      };
    }
    case ACTION_TYPE_LIST_FEATURE_RANKING: {
      const featureRankingList = action.data;
      return {
        ...state,
        featureRankingList
      };
    }
    case 'SET_VISIBLE_ATTRIBUTES': {
      return {
        ...state,
        visibleAttributes: JSON.parse(window.localStorage.selectedAttributes || '[]')
      };
    }
    default: {
      return state;
    }
  }
};
