export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_BADGES': {
      const { en, ar, disabledBadges = [] } = action.data;
      return {
        ...state,
        badgesListEn: en,
        badgesListAr: ar,
        disabledBadges
      };
    }
    case 'GET_BADGE': {
      const { badgesDetail } = action.data;
      return {
        ...state,
        badgesDetail
      };
    }
    case 'GET_PROMO_BADGES': {
      const { promoBadgesList = [] } = action.data;
      return {
        ...state,
        promoBadgesList
      };
    }
    default: {
      return state;
    }
  }
};
