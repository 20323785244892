// import firebaseConfig from '../../firebaseConfig';
// const db = firebaseConfig.firestore();
// firebaseConfig.firestore().settings({ experimentalForceLongPolling: true });

import axios from './../../variables/axios';
// import get from 'lodash.get';
import { SERVER_ENDPOINT } from 'constants/index';

export const getUsage = async (opts, dispatch) => {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/get-usage`,
    {},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return result.data.body.nodes;
};
export const getIndiciesUsage = async (opts, dispatch) => {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/get-indicies-stats`,
    {
      env: opts.env,
      index: opts.index,
      explain: opts.explain,
      backups: opts.backups
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return result.data.body;
};

export const rescue = async (opts, dispatch) => {
  const result = await axios.post(
    `${SERVER_ENDPOINT}/rescue-management`,
    {
      env: opts.env,
      index: opts.index,
      operation: opts.operation,
      method: opts.method,
      action: 'force'
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return result.data.body;
};
